@import '/src/styles-imports/shared';

.login {
  @include basicGrid;

  .container {
    margin-top: 176px;
    grid-column: 5/9;
    animation: loginSlideIn .300s ease-out;

    .poweredBy {
      margin-top: -36px;
      padding-bottom: space('Xs');
    }

    .ssoExclusive {
      & > button {
        display: block;
        margin-top: $spaceM;
        margin-left: auto;
      }

      @include mqSmaller($bpM) {
        & > button {
          width: 100%;
        }
      }

      @include mqSmaller($bpS) {
        text-align: center;
      }
    }
  }

  @include mqSmaller($bpM) {
    .container {
      grid-column: 3/7;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
      margin-top: 126px;

      .poweredBy {
        display: flex;
        justify-content: center;
      }
    }
  }
}

@keyframes loginSlideIn {
  0% {
    margin-top: 150px;;
    opacity: 0;
  }
  100% {
    margin-top: 176px;
    opacity: 1;
  }
}
