@import '/src/styles-imports/shared';

/* InfoCard */
.infoCard {
  /* INTERACTION */

  /* MODIFIERS */
  &.expanded {
    .header {
      .arrow svg {
        transform: scale(-1);
        path {
          fill: $colorPrimary1;
        }
      }
    }

    .content {
      &:after {
        background: none;
      }
    }
  }

  &.notExpandable {
    .header {
      pointer-events: none;
      .arrow {
        display: none;
      }
    }

    .content:after {
      display: none;
    }
  }

  &.evo {
    .infoSubCard {
      background-color: $colorAssistant1;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.10);
    }

    .header {
      & > span {
        color: $colorAssistantDark;
      }
    }

    .content:after {
      background: linear-gradient(to top, $colorAssistant1, $colorAssistant1 50%, rgba($colorAssistant1, 0));
    }

    &.expanded, &.notExpandable {
      .content:after {
        background: none;
      }
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
  p {
    margin-top: 0;
  }

  .header {
    display: flex;
    align-items: center;

    @include transition(color, fill);

    & > span {
      flex: 1;
      padding-left: 12px;
      padding-right: 8px;

      color: $colorPrimary1;
    }

    .arrow {
      height: 14px;

      & > svg {
        @include transition(transform);
      }
    }

    &:hover {
      cursor: pointer;

      & > span {
        color: $colorPrimary2;
      }

      & > .arrow svg path {
        fill: $colorPrimary2;
      }
    }
  }

  .content {
    max-height: 60px;
    overflow: hidden;

    @include type('Copy');
    margin-top: 12px;

    position: relative;
    width: 100%;

    @include transitionLong(max-height);

    &:after {
      display: block;
      position: absolute;
      width: 100%;
      height: 20px;
      content: '';
      bottom: -7px;
      background: linear-gradient(to top, #fbfbfb, #fbfbfb 50%, rgba(#fbfbfb, 0));
    }
  }

  .hiddenContent {
    visibility: hidden;
  }
}
