@import '/src/styles-imports/shared';


/* CandidateExternalProfile */
.candidateExternalProfile {
  /* INTERACTION */
  height: 100%;
  background-color: $colorGrey6;

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .navigation {
    position: sticky;
    top: 0;

    display: flex;
    box-sizing: border-box;

    padding: space('Xs') $gridMarginLarge 0;
    background-color: $colorWhite;
    border-bottom: 1px solid $colorGrey4;

    z-index: zIndex('Buffer1');

    &::-webkit-scrollbar {
      display: none;
    }

    @include mqSmaller($bpS) {
      padding: space('Xs') $gridMarginSmall 0;
    }

    .item {
      @include type('Copy');
      padding-bottom: space('Xs');
      cursor: pointer;
      white-space: nowrap;

      @include transition(color);

      &:not(:last-child) {
        margin-right: space('S');
      }

      &:hover {
        color: $colorGrey2;
      }

      &.active {
        color: $colorPrimary1;
        border-bottom: 2px solid $colorPrimary1;
        &:hover {
          color: $colorPrimary2;
        }
      }
    }
  }
}
