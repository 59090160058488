@import '/src/styles-imports/shared';

/* UPGRADE TRIAL */
.upgradeTrial {
  margin: 0 20px 20px;
  border-top: 1px solid $colorGrey4;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .text {
    margin: $spaceXs 0;
    @include type('LabelM');
    .highlight {
      color: $colorPrimary1;
    }
  }
}
