@import '/src/styles-imports/shared';

/* WRAPPERCOACHHUB */
.wrapperCoachHub {

  position: fixed; // to break out of mainLayout
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  background-color: white;
  z-index: zIndex('Modal');

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .coachHubHeader {
    height: $headerHeightMedium;

    @include mqSmaller($bpS) {
      height: 96px;
    }
  }
  .coachHubMain {
    height: calc(100% - #{$headerHeightMedium});

    overflow-y: auto;
    overflow-x: hidden;

    @include animation(slideUp);

    @include mqSmaller($bpS) {
      height: calc(100% - #{$spaceXxl});
    }
  }
  .coachHubContent {

    box-sizing: border-box;
    padding: $spaceL 0 $spaceL;
    margin: 0 $gridMarginLarge;

    min-height: calc(100% - #{$footerHeight});

    @include mqSmaller($bpS) {
      padding: $spaceS 0 0 0;
      margin: 0 $gridMarginSmall;

      min-height: calc(100% - #{$footerHeightLarge});
    }
  }
  .coachHubFooter {
    @include gridMarginsAsPaddings;

    height: $footerHeight;

    @include mqSmaller($bpS) {
      height: $footerHeightLarge;
    }
  }
}
