@import '/src/styles-imports/shared';

/* CandidateUserGroup */
.candidateUserGroup {
  @include basicGridNoMargins;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    grid-column: start/mid;

    @include mqSmaller($bpM) {
      grid-column: start/7;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }

  .profileSettings {
    margin-top: space('L');

    .checkBoxes {
      & > label {
        margin-top: space('Xs');
      }
    }
  }
}
