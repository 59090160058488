/**
  - Named after bootstrap model
    https://getbootstrap.com/docs/4.3/getting-started/theming/#theme-colors
  - Definitions in Wiki
    https://blueexcellence.atlassian.net/wiki/spaces/BQ/pages/884772/UI+Basics+Colors+Typography+UI+Elements+Layout+basics#Colors
  - Initial palettes specs
    https://www.figma.com/file/NM7wfwqsnvPyKnjcJP52p8/Styles%2C-type%2C-butttons%2C-form-elements%2C-icons%2C-cards?type=design&node-id=2511-8841&mode=design
  - Mostly sorted from dark to light
**/


/**
  INITIAL PALETTES
**/

$colorBlue01: #183F8C;
$colorBlue02: #0149DA; // hover
$colorBlue03: #2D72FE; // main
$colorBlue04: #92B4F9;
$colorBlue05: #D5E3FF; // interactive
$colorBlue06: #F4F7FE; // active
$colorBlue07: #F9FBFF; // active

$colorGrey01: #333333;
$colorGrey02: #5B5B5B;
$colorGrey03: #999999;
$colorGrey04: #C1C1C1;
$colorGrey05: #D3D3D3;
$colorGrey06: #E7E7E7;
$colorGrey07: #F2F2F2;
$colorGrey08: #F5F5F5;
$colorGrey09: #FBFBFB;

$colorPurple01: #723ADA;
$colorPurple02: #8B68ED; // hover
$colorPurple03: #B69CFF; // main
$colorPurple04: #D8CCFB; // active
$colorPurple05: #EBE4FF;
$colorPurple06: #F6F3FF;
$colorPurple07: #FCFBFF;

$colorYellow01: #D5A90A;
$colorYellow02: #F0BC00; // hover
$colorYellow03: #FECA0C; // main
$colorYellow04: #FFE894; // active
$colorYellow05: #FEF5D1;
$colorYellow06: #FFFAE5;
$colorYellow07: #FFFDF5;

$colorRed01: #902323;
$colorRed02: #B42122; // hover
$colorRed03: #E1292B; // main
$colorRed04: #F87879;
$colorRed05: #F9D4D5;
$colorRed06: #FEEDEE;
$colorRed07: #FFFBFB;

$colorGreen01: #569B50;
$colorGreen02: #5FB658; // hover
$colorGreen03: #6FD365; // main
$colorGreen04: #AAE5A4;
$colorGreen05: #DEF6DB;
$colorGreen06: #E9FEE6;
$colorGreen07: #F7FEF6;

$colorBlack: #212121;
$colorWhite: #FFFFFF;


/**
  DERIVED PALETTES
**/

/* PURPOSES */
$colorBlueMain: $colorBlue03;
$colorBlueDark: $colorBlue02;
$colorBlueLight: $colorBlue05;
$colorBlueShade: $colorBlue06;

$colorPurpleMain: $colorPurple03;
$colorPurpleDark: $colorPurple02;
$colorPurpleLight: $colorPurple05;
$colorPurpleShade: $colorPurple06;

$colorYellowMain: $colorYellow03;
$colorYellowDark: $colorYellow02;
$colorYellowLight: $colorYellow05;
$colorYellowShade: $colorYellow06;

$colorRedMain: $colorRed03;
$colorRedDark: $colorRed02;
$colorRedLight: $colorRed05;
$colorRedShade: $colorRed06;

$colorGreenMain: $colorGreen03;
$colorGreenDark: $colorGreen02;
$colorGreenLight: $colorGreen05;
$colorGreenShade: $colorGreen06;

/* PRIMARY */
$colorPrimary1: $colorBlueMain;
$colorPrimary1h: #6395FF;
$colorPrimary2: $colorBlueDark;
$colorPrimary3: $colorBlueLight;
$colorPrimary4: $colorBlueShade;

/* SECONDARY */
$colorSecondary1: $colorYellowMain;
$colorSecondary2: $colorYellowDark;
$colorSecondary3: $colorYellowLight;

/* DANGER */
$colorDanger1: $colorRedMain;
$colorDanger2: $colorRedDark;
$colorDanger3: $colorRedLight;

/* GREYS */
$colorGrey1: $colorGrey01;
$colorGrey2: $colorGrey03;
$colorGrey3: $colorGrey05;
$colorGrey4: $colorGrey07;
$colorGrey5: $colorGrey08;
$colorGrey6: $colorGrey09;


/**
  ASSESSMENT COLORS
**/

/* 9LEVELS & DISC COLORS */
$colorTurquoise1: #40B8B0;
$colorTurquoise2: #9FDBD7;

$colorYellow1: #FFDF3F;
$colorYellow2: #FFEF9F;
$colorYellow3: $colorYellow05;

$colorGreen1: #71BC5F;
$colorGreen2: #B8DDAF;

$colorOrange1: #F4A740;
$colorOrange2: #F9D39F;

$colorBlue1: #4098CE;
$colorBlue2: #9FCBE6;

$colorRed1: #E65A50;
$colorRed2: #F2ACA7;

$colorPurple1: #A05985;
$colorPurple2: #DBC1D1;

/* CLIFTON COLORS */
$colorCliftonPurple: #6B297E;
$colorCliftonOrange: #CF711B;
$colorCliftonBlue: #3F6FC9;
$colorCliftonGreen: #539360;

$colorCliftonExecuting: $colorCliftonPurple;
$colorCliftonInfluencing: $colorCliftonOrange;
$colorCliftonRelationship: $colorCliftonBlue;
$colorCliftonStrategic: $colorCliftonGreen;

/* AI ASSISTANT COLORS */
$colorAssistant1: $colorPurple06;
$colorAssistantDark: $colorPurple01;

/**
  SHADOWS
**/
$shadowNone: 0px 0px rgba($colorWhite, 0);
$shadowDark: 0px 2px 10px rgba($colorBlack, 0.1);
$shadowBlue: 0px 2px 20px rgba($colorPrimary1, 0.3);

$dropShadow: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));


/**
  MIXINS
**/
@mixin fades ($width, $color: $colorGrey6) {
  &::before, &::after {
    position: absolute;
    content: " ";
    top: 0;
    width: $width;
    height: 100%;
    pointer-events: none;
    z-index: 1;
  }
  // let fades break out
  &::before {
    left:0;
    background: linear-gradient(to right, $colorGrey6, $colorGrey6 50%, rgba($colorGrey6, 0));
  }
  &::after {
    right: 0;
    background: linear-gradient(to left, $colorGrey6, $colorGrey6 50%, rgba($colorGrey6, 0));
  }
}

@mixin diagramGapColor($color) {
  [class*=SegmentedLineDiagram_fillColor] {
    fill: $color;
  }
}

@mixin diagramFills () {
  &.block-blue {
    fill: $colorBlueMain;
  }
  &.block-purple {
    fill: $colorPurpleMain;
  }
  &.block-yellow {
    fill: $colorYellowMain;
  }
  &.block-green {
    fill: $colorGreenMain;
  }
  &.block-red {
    fill: $colorRedMain;
  }
}


:export {
  colorPrimary1: $colorPrimary1;
  colorPrimary2: $colorPrimary2;
  colorPrimary3: $colorPrimary3;
  colorPrimary4: $colorPrimary4;

  colorDanger1: $colorDanger1;
  colorDanger2: $colorDanger2;
  colorDanger3: $colorDanger3;

  colorSecondary1: $colorSecondary1;
  colorSecondary2: $colorSecondary2;
  colorSecondary3: $colorSecondary3;

  colorBlack: $colorBlack;
  colorGrey1: $colorGrey1;
  colorGrey2: $colorGrey2;
  colorGrey3: $colorGrey3;
  colorGrey4: $colorGrey4;
  colorGrey5: $colorGrey5;
  colorGrey6: $colorGrey6;
  colorWhite: $colorWhite;

  colorTurquoise1: $colorTurquoise1;
  colorTurquoise2: $colorTurquoise2;

  colorYellow1: $colorYellow1;
  colorYellow2: $colorYellow2;
  colorYellow3: $colorYellow3;

  colorGreen1: $colorGreen1;
  colorGreen2: $colorGreen2;

  colorOrange1: $colorOrange1;
  colorOrange2: $colorOrange2;

  colorBlue1: $colorBlue1;
  colorBlue2: $colorBlue2;

  colorRed1: $colorRed1;
  colorRed2: $colorRed2;

  colorPurple1: $colorPurple1;
  colorPurple2: $colorPurple2;

  shadowDark: $shadowDark;

  colorAssistant1: $colorAssistant1;
  colorAssistantDark: $colorAssistantDark;
}
