@import '/src/styles-imports/shared';

/* PRICINGTABLEITEM */
.pricingTable {
  display: grid;
  grid-template-columns: auto 112px 112px;
  padding-top: space('Xxs');
  padding-bottom: space('Xs');

  @include mqSmaller($bpS) {
    grid-template-columns: 2fr 1fr 1fr;
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  & > div {
    text-align: right;
    padding: space('Xs') 0;
    border-bottom: 1px solid $colorGrey3;

    &:not(.rowDescription) {
      padding-left: space('Xs');
    }
  }

  .rowDescription {
    text-align: left;

    word-break: break-word;
  }

  .rowCount {
    @include ellipsis();

    @include mqSmaller($bpS) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }

  .rowPrice {
    white-space: nowrap;
    overflow: hidden;
  }

  .rowBold {
    font-weight: $typeWeightXxl;

    word-break: break-word;
  }
}

.pricingTableLoading {
  padding-top: space('Xs');
  padding-bottom: space('Xs');
}
