@import '/src/styles-imports/shared';

/* SCROLLABLE */
.scrollable {
  position:relative;

  width: 100%;

  // allow onKeyUp with tabIndex but don't show outline
  outline: none;

  @include transition(opacity);

  /* FLAGS */
  &.noScrollbar {
    .scrollContainer {
      padding-bottom: 0px; // remove padding to create space between content and scrollbar
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .scrollContainer::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .scrollContainer {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  }

  &.scroll {
    .scrollContainer { // disable scrolling on pagination
      overflow-x: scroll;
    }
  }

  &.fade {
    @include fades(space('Xs'));
    // let fades break out
    &::before {
      left: - space('Xs');
    }
    &::after {
      right: - space('Xs');
    }

    &.fadeWhite {
      &::before {
        left: - space('Xs');
        background: linear-gradient(to right, #fff, #fff 50%, rgba(#fff, 0));
      }
      &::after {
        right: - space('Xs');
        background: linear-gradient(to left, #fff, #fff 50%, rgba(#fff, 0));
      }
    }
  }
  &.fadeWhite {
    @include fades(space('Xs'), white);
    // let fades break out
    &::before {
      left: - space('Xs');
    }
    &::after {
      right: - space('Xs');
    }
  }

  &.noMargins { // for MainHeader.subNavItem border
    .scrollContainer {
      margin: 0;
      height: 100%;
    }
  }

  &.chartMargins {
    .scrollContainer {
      padding-top:space('S');
      margin: 0px;
    }
    .controls {
      margin-top: 20px;
    }
  }

  &.hidden {
    opacity: 0;
  }

  /* SCROLL CONTAINER */
  .scrollContainer {
    user-select: none;

    cursor: grab;
    display: flex;
    flex-wrap: nowrap;



    margin: space('Xs') 0;
    padding: 0;
    // bottom padding for a bit of air for scrollbar
    padding-bottom: space('Xs');
    width: 100%;

    white-space: nowrap; // force inline block into one line
    overflow: hidden;

    &.extraPaddings {
      margin-left: -#{space('Xs')};
      // give left and right padding for fades breakout compensation
      padding-left: space('Xs');
      padding-right: space('Xs');
    }
  }

  /* BREAKOUT BUFFER */
  .breakoutBuffer {
    height: 100%;
  }
  /* BREAKING CONTAINER */
  .breakingContainer {
    display: flex;
    // width set dynamically in JS based on breakout prop
  }

  /* CONTROLS */
  .controls {
    margin-top: - space('Xxs');
  }

}
