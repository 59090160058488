@import '/src/styles-imports/shared';


$introFooterHeight: 76px;

:export {
  introFooterHeight: $introFooterHeight;
}

.assessmentGuidance {

  .title {
    @include type('M');
    color: $colorPrimary1;
    text-align: center;
  }
  .description {
    @include type('CopyStrong');
    color: $colorGrey1;
    text-align: left;
    margin-top: space('Xs');
    margin-bottom: space('S');

    p {
      margin-top: $spaceM;
    }
  }

  .calloutContent {
    @include type('S');
    padding: 6px 0;
  }

}
