@import '/src/styles-imports/shared';

/* Success */
.success {
  text-align: center;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  button {
    margin-top: space('S');
  }
}
