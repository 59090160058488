@import '/src/styles-imports/shared';

/* Peer360SETUP */
.peer360Setup {

  &.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: zIndex('Modal');
    background-color: $colorWhite;

    //HEADER
    .header {
      @include type('LabelL');
      color: $colorPrimary1;
      padding: 20px 60px;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid $colorGrey4;
      display: flex;
      justify-content: space-between;
      .close {
        cursor: pointer;

        display: flex;
        align-items: center;

        height: 100%;
        padding-left: $spaceXs;

        @include type('Copy');
        color: $colorPrimary1;

        &:hover {
          color: $colorPrimary2;
        }
      }
    }

    .scrollableContent {
      height: calc(100vh - #{$headerHeight});
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    .gridContent {
      @include basicGrid;
      margin-top: space('Xl') !important;
      margin-bottom: space('L');
    }
  }

  &:not(.modal) {
    .header {
      display: none;
    }

    .gridContent {
      @include basicGridNoMargins;
    }
  }

  .gridContent {
    .main {
      grid-column: 4/10;
      color: $colorGrey1;

      .preTitle {
        @include type('LabelL');
        margin-bottom: space('Xxs');
        color: $colorPrimary1;
      }

      .skeleton {
        margin-top: space('L');
      }

      .title {
        @include type('S');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xs');
      }
      .peerGroup {
        margin-top: space('M');
        .title {
          @include type('Xs');
        }
        .description {
          margin-top: 8px;
        }
        .inviteTitle {
          margin-top: space('S');
          font-weight: 600;
        }
        .inviteQuantityError {
          @include type('Hint');
          margin-top: 16px;
          color: $colorDanger1;
        }
      }
      .bottom {
        display: flex;
        justify-content: flex-end;
        margin-top: space('M');
        > button:last-child {
          margin-left: space('Xs');
        }
      }
    }
  }

  @include mqSmaller($bpM) {
    .gridContent {
      .main {
        grid-column: 2/8;
      }
    }
  }

  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
    .gridContent {
      margin-top: space('M') !important;
      margin-bottom: space('Xxl') !important;
      .main {
        grid-column: start/end;
      }
    }
  }

  /* FLOATERS */
  .toastTitle {
    @include type('LabelL');
    text-align: left;

    margin-right: $spaceM;
    overflow: hidden;
  }

  .toastDescription {
    @include type('Copy');
    text-align: left;

    margin-top: $spaceXxs;
  }
}
