@import '/src/styles-imports/shared';

/* QuestionCompanyDomain */
.questionCompanyDomain {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  & > div {
    margin-top: space('Xs');

    &:first-child {
      text-align: center;
    }
  }
}
