.marginTopXl {
  margin-top: space('Xl');
}
.marginTopL {
  margin-top: space('L');
}
.marginTopM {
  margin-top: space('M');
}
.marginTopS {
  margin-top: space('S');
}
.marginTopXs {
  margin-top: space('Xs');
}
.marginTopXxs {
  margin-top: space('Xxs');
}
.marginTopXxxs {
  margin-top: space('Xxxs');
}

.marginBottomXl {
  margin-bottom: space('Xl');
}
.marginBottomL {
  margin-bottom: space('L');
}
.marginBottomM {
  margin-bottom: space('M');
}
.marginBottomS {
  margin-bottom: space('S');
}
.marginBottomXs {
  margin-bottom: space('Xs');
}
.marginBottomXxs {
  margin-bottom: space('Xxs');
}
.marginBottomXxxs {
  margin-bottom: space('Xxxs');
}
