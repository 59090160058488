@import '/src/styles-imports/shared';

.skeletonContainer {
  height: 746px;
  border: 1px solid $colorGrey4;
  border-radius: 10px;
  padding: 32px 32px 47px 32px;
  box-sizing: border-box;
  grid-column: 5/13;
  .skeletonBlock {
    display: grid;
    grid-template-columns: repeat(8, auto);
    grid-column-gap: 17px;
    animation: loadAssessmentResult 1.5s infinite ease;
    .header {
      grid-column: 1/9;
      height: 24px;
      width: 90%;
      box-sizing: border-box;
      background-color: $colorGrey4;
      border-radius: 12px;
    }
    .diagram {
      grid-column: 3/7;
      height: 280px;
      box-sizing: border-box;
      background-color: $colorGrey4;
      border-radius: 10px;
      margin-top: 25px;
    }
    .subItems {
      grid-column: 1/9;
      .subItemsGroup {
        margin-top: 32px;
        .subItem1, .subItem2 {
          height: 14px;
          width: 70%;
          box-sizing: border-box;
          background-color: $colorGrey4;
          border-radius: 12px;
        }
        .subItem2 {
          width: 100%;
          margin-top: 8px;
        }
      }
    }
  }

  @include mqSmaller($bpM) {
    height: 736px;
    padding: 22px 16px;
    grid-column: 5/9;
    .skeletonBlock {
      grid-template-columns: repeat(4, auto);
      .header, .diagram, .subItems {
        grid-column: 1/5;
      }
      .diagram {
        height: 249px;
      }
    }
  }

  @include mqSmaller($bpS) {
    height: 736px;
    padding: 22px 16px;
    grid-column: start/end;
    .skeletonBlock {
      grid-template-columns: repeat(4, auto);
      .header, .diagram, .subItems {
        grid-column: start/end;
      }
      .diagram {
        height: 249px;
      }
    }
  }
}
