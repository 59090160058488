@import '/src/styles-imports/shared';

/* Review */
.review {
  @include dialogIntermission;

  margin-bottom: space('L');

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .items {
    margin-top: space('S');

    //HEADER
    .header {
      display: flex;
      margin-top: space('Xs');

      & > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include type('LabelL');
      }
      & > div:first-child {
        width: calc(100% - (72px + 96px + #{$spaceXs} + #{$spaceXs}));
      }
      & > div:nth-child(2) {
        width: 72px;
        margin-left: space('Xs');
        text-align: right;
      }
      & > div:last-child {
        width: 96px;
        margin-left: space('Xs');
        text-align: right;
      }

      @include mqSmaller($bpM) {
        & > div:first-child {
          width: calc(100% - (64px + 72px + #{$spaceXs} + #{$spaceXs}));
        }
        & > div:nth-child(2) {
          width: 64px;
        }
        & > div:last-child {
          width: 72px;
        }
      }

      @include mqSmaller($bpS) {
        & > div:first-child {
          width: calc(100% - (48px + 72px + #{$spaceXs} + #{$spaceXs}));
        }
        & > div:nth-child(2) {
          width: 48px;
        }
        & > div:last-child {
          width: 72px;
        }
      }
    }

    //LIST
    .list {
      margin-top: space('Xs');
    }

    //ITEM
    .item, .itemWith2Cols, .itemWith3Cols {
      display: flex;
      padding: space('Xs') space('Xxs');
      border-bottom: 1px solid $colorGrey3;
      @include type('Copy');

      &:first-child {
        border-top: 1px solid $colorGrey3;
      }

      & > div {
        width: calc(50% - #{$spaceXxs});
        white-space: pre-wrap;
        word-break: break-word;
      }

      & > div:first-child {
        margin-right: space('Xs');
      }
    }

    .itemWith2Cols {
      padding: space('Xs') 0;
      & > div {
        width: 50%;

        white-space: pre-wrap;
        @include type('LabelL');
      }
      & > div:last-child {
        margin-left: space('Xs');
        text-align: right;
      }
    }

    .itemWith3Cols {
      padding: space('Xs') 0;
      & > div {
        white-space: pre-wrap;
        @include type('Copy');
      }
      & > div:first-child {
        width: calc(100% - (72px + 96px + #{$spaceXs} + #{$spaceXs}));
      }
      & > div:nth-child(2) {
        width: 72px;
        margin-left: space('Xs');
        text-align: right;
      }
      & > div:last-child {
        width: 96px;
        margin-left: space('Xs');
        text-align: right;
      }

      @include mqSmaller($bpM) {
        & > div:first-child {
          width: calc(100% - (64px + 72px + #{$spaceXs} + #{$spaceXs}));
        }
        & > div:nth-child(2) {
          width: 64px;
        }
        & > div:last-child {
          width: 72px;
        }
      }

      @include mqSmaller($bpS) {
        & > div:first-child {
          width: calc(100% - (48px + 72px + #{$spaceXs} + #{$spaceXs}));
        }
        & > div:nth-child(2) {
          width: 48px;
        }
        & > div:last-child {
          width: 72px;
        }
      }
    }
  }

  .controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    & > button {
      margin-top: space('S');
    }
  }
}
