@import '/src/styles-imports/shared';

$shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0;
}
.enterActive, .enterDone {
  opacity: 1;
  @include transition(opacity);
}
.exit {
  opacity: 1;
}
.exitActive, .exitDone {
  opacity: 0;
  transform: translateY(-30px);
  @include transition(transform, opacity);
}

/* BOT ASSISTANT */
.botAssistant {
  position: fixed;
  right: $spaceM;
  bottom: $spaceM;
  z-index: zIndex('Buffer3');
  pointer-events: none;
  
  &.smallIcon {
    right: 12px;
    bottom: 12px;
  }
  &.smallChat {
    right: 0;
    bottom: 0;
  }

  /* ICON */
  .chatIcon {
    .notificationDot {
      position: absolute;
      top: 0;
      left: 0;
      width: $spaceXs;
      height: $spaceXs;
      border-radius: 50%;
      pointer-events: auto;
      cursor: pointer;
      background-color: $colorSecondary2;
      @include animation(fadeIn);
    }
  
    svg {
      pointer-events: inherit;
      filter: drop-shadow($shadow);
      g {
        pointer-events: auto;
        cursor: pointer;
        &:hover {
          path:first-child {
            @include transition(fill);
            fill: $colorPrimary4;
          }
        }
      }
    }
  }

  /* CHAT */
  .chatContainer {
    width: 500px;
    height: calc(min(88vh, 600px));
    border-radius: 10px;
    pointer-events: auto;
    overflow: hidden;
    background-color: white;
    box-shadow: $shadow;
    display: flex;
    flex-direction: column;
    @include animation(slideUp);

    @include mqSmaller($bpM) {
      width: 100vw;
      height: 100vh;
      border-radius: initial;
    }

    .chatHeader {
      height: 60px;
      display: flex;
      align-items: center;
      gap: $spaceXs;
      padding: 0 $spaceXs;
      line-height: 0;
      background-color: $colorPrimary1;
      box-shadow: $shadow;
      z-index: zIndex('Buffer1');

      .title {
        flex: 1;
        @include type('LabelL');
        color: white;
      }

      .close svg {
        width: $spaceS;
        height: $spaceS;
        path {
          fill: white;
        }
      }
    }

    .callToAction {
      padding: $spaceM 22px;
      @include type('Copy');
    }

    .chatContent {
      flex: 1;
      display: flex;
      flex-direction: column-reverse;
      overflow: auto;
      padding: 10px;

      .chatBubble {
        margin: 12px;
        padding: $spaceXxs $spaceXs;
        border-radius: 12px;
        white-space: pre-line;
        word-break: break-word;
        animation-delay: -4ms; // skip 0% keyframe, which is only reserved for typingDots animation
        @include animation(bubbleSlideUp);

        &.bot {
          align-self: flex-start;
          margin-right: 60px;
          color: $colorGrey4;
          background-color: $colorPrimary1;

          // blu-markdown adjustments
          > div {
            margin-top: -8px;
            margin-bottom: -20px;
          }
        }
        &.user {
          align-self: flex-end;
          margin-left: 60px;
          background-color: $colorPrimary4;
        }

        // Typing animation
        &.typingDots {
          align-items: center;
          display: flex;
          min-height: 20px;
          animation-delay: 500ms;
          animation-fill-mode: backwards;
          .dot {
            display: inline-block;
            background-color: $colorPrimary3;
            width: 6px;
            height: 6px;
            margin-right: 4px;
            vertical-align: middle;
            border-radius: 50%;
            animation: typingAnimation 1.6s infinite ease-in-out;
          }
          .dot:nth-child(1) {
            animation-delay: 200ms;
          }
          .dot:nth-child(2) {
            animation-delay: 400ms;
          }
          .dot:nth-child(3) {
            animation-delay: 600ms;
            margin-right: 0;
          }
        }
      }

      // Reduce margins on adjacent bubbles from the same sender
      .user + .user, .bot + .bot {
        margin-bottom: 0;
      }
    }

    .chatInput {
      background-color: $colorGrey4;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 10px;
      padding: $spaceXs;
      line-height: 0;

      // ResizableTextArea modifiers
      > div:first-child {
        flex: 1;
        overflow: hidden;
        padding-right: 12px;
        border-radius: 22px;
        background-color: white;
        textarea {
          padding: 12px 8px 12px 20px;
          border: 0;
          box-shadow: none;
          background-color: inherit;
        }
      }

      // Send button modifiers
      svg {
        width: $spaceS;
        height: $spaceS;
      }
    }
  }
}

// ANIMATIONS

@keyframes bubbleSlideUp {
  0% {
    opacity: 0;
    margin-top: -$spaceL;
  }
  1% {
    opacity: 0.5;
    margin-top: 12px;
    transform: translateY($spaceM);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes typingAnimation {
  0% {
    transform: translateY(0px);
    background-color: $colorPrimary3;
  }
  30% {
    transform: translateY(-5px);
    background-color: $colorPrimary1h;
  }
  55% {
    transform: translateY(0px);
    background-color: $colorGrey09;
  }
}
