@import '/src/styles-imports/shared';

.myProfileOverview {
  background: $colorGrey6;

  .gridContainer {
    @include basicGridNoMargins;
  }

  .personalInfo {
    grid-column: 1/5;
    z-index: zIndex('Buffer1');

    .subBlock {
      &:not(.sticky) {
        @include mqSmaller($bpS) {
          margin-top: 0;
        }
      }

      &.sticky {
        position: sticky;
        top: space('L');

        @include mqSmaller($bpS) {
          position: unset;
          top: unset;
        }
      }
    }

    .buttons {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-top: space('S');

      z-index: zIndex('Buffer1');

      @include animationLonger(slideUp);
      animation-fill-mode: forwards;

      button {
        align-self: baseline;
        margin-bottom: space('Xs');
        margin-right: space('Xs');
      }
      button:last-child {
        margin-bottom: 0;
      }
    }
  }

  .assessmentsInfo {
    grid-column: 5/13;
    overflow: hidden;

    @include animationLong(slideUp);
    animation-fill-mode: forwards;

    & > div:not(:first-child) {
      margin-top: $spaceXs;
    }

    .noData {
      text-align: center;
      color: $colorGrey2;

      & > svg {
        width: 50%;
        height: auto;
        margin-top: $spaceS;
      }
    }

    .assessmentTeasers {
      margin-bottom: $spaceXs;
    }

    .titleBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .scrollingButtons {
      display: flex;

      & > div:first-child {
        margin-right: space('Xxs');
      }

      @include mqSmaller($bpS) {
        display: none;
      }
    }

    .flex {
      display: flex;
      margin-top: $spaceXs;

      //copy
      & > div:first-child {
        flex: 1;
      }
      & > button {
        height: 100%;
        margin-left: $spaceXs;
      }

      @include mqSmaller($bpM) {
        flex-direction: column;

        & > button {
          align-self: baseline;
          margin-left: 0;
          margin-top: $spaceXs;
        }
      }
    }

    @include mqSmaller($bpM) {
      grid-column: 5/9;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: space('M');
    }
  }

  .exportView {
    width: 600px;
    max-width: 600px;
    margin: space('M') auto 0;

    // loading indicator
    .loading {
      margin-bottom: space('M');
      margin-top: 0;
      text-align: center;
      & > div {
        justify-content: center;
      }
    }

    & > [id^=export], & > .assessment {
      div, text, circle {
        pointer-events: none;
      }
    }

    & > .assessment {
      margin-top: space('Xs');
    }
  }

  .exportViewFooter {
    width: 600px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    & > button {
      display: block;
      margin-left: auto;
    }
  }

}
