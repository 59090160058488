@import '/src/styles-imports/shared';

$copyIconSize: 14px;

/* InvitationLinks */
.invitationLinks {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  & > button {
    display: block;
    margin-top: space('S');
    margin-left: auto;
  }

  .item {
    padding: space('Xs') space('Xxxs');
    border-bottom: 1px solid $colorGrey3;

    &:first-child {
      border-top: 1px solid $colorGrey3;
    }

    .row {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: space('Xxs');
      }

      // email, link values
      & > div:first-child {
        flex-basis: calc(100% - #{$copyIconSize + $spaceXxs});
        word-break: break-word;
      }

      & > svg {
        margin-left: space('Xxs');
        @include transition(fill);

        &:hover {
          cursor: pointer;

          & > g > path {
            fill: $colorPrimary2;
          }
        }
      }
    }
  }
}
