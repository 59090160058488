@import '/src/styles-imports/shared';

$navigationHeight: 55px;

/* CandidateExternalSummary */
.candidateExternalSummary {
  width: 100%;
  height: 100%;
  background-color: $colorGrey6;
  color: $colorGrey1;

  /* CHILDREN */
  .gridContainer {
    @include basicGrid;
    padding-bottom: space('M');
    padding-top: space('M');
  }

  .header {
    padding: space('S') space('Xs');
  }

  .listItem {
    padding: space('Xs') space('Xxs');

    //margins with negative values to decrease the margins of CollapsibleNext content
    margin-right: -(space('Xs'));
    margin-left: -(space('Xxs'));

    border-bottom: 1px solid $colorGrey4;

    &:first-child {
      border-top: 1px solid $colorGrey4;
    }

    .label {
      @include type('Hint');
      color: $colorGrey2;
    }
    .value {
      @include type('Copy');
      margin-top: space('Xxs');

      ul {
        margin-left: space('S');
      }
    }
  }

  //LEFT
  .leftContent {
    grid-column: start/5;

    //CANDIDATE INFO
    .candidateInfo {
      margin-top: space('M');
    }

    //ATTACHMENTS
    .attachments {
      margin-top: space('M');
      & > div > div:not(:first-child), button {
        margin-top: space('S');
      }
    }
  }

  //RIGHT
  .rightContent {
    grid-column: 5/end;

    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: space('M');
    }

    & > div:not(:first-child) {
      margin-top: space('Xs');
    }

    .profileCompletionPending, .deactivated {
      padding-bottom: space('Xs');
    }

    .vacancyTableSubHeader {
      width: 100%;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .labels {
        flex: 1;
        margin-right: space('Xs');
        white-space: nowrap;
        padding-bottom: space('S');
        .subLabels {
          margin-top: space('Xxs');
          @include type('Hint');

          a span {
            @include type('Hint');
          }
        }
      }

      .controls {
        display: flex;
        align-items: center;
        padding-bottom: space('S');

        button {
          margin-right: space('Xs');
        }
      }
    }

    .comments {
      .commentsList {
        margin-top: space('Xs');
      }
      .commentInput {
        margin-top: space('S');
      }
    }

    .roleLoadingSkeleton {
      width: 100%;
      animation: loadAssessmentResult 1.5s infinite ease;
      .top {
        width: 100%;
        height: 24px;
        border-radius: 12px;
        background-color: $colorGrey4;
      }
      .contentBlock {
        margin-top: space('S');
        .row {
          width: 94%;
          height: 14px;
          background-color: $colorGrey4;
          border-radius: 12px;
          margin-bottom: space('Xxs');

          &:nth-child(odd) {
            width: 72%;
          }
        }
      }
    }

    .roleMatchingContent {
      button {
        display: block;
        margin-left: auto;
      }
    }
  }

}
