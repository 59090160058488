@import '/src/styles-imports/shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
}
.exit {
  opacity: 1 !important;
}
.exitActive, .exitDone {
  opacity: 0 !important;
}

/* AssessmentHistoryDiagram */
.assessmentHistoryDiagram {
  /* CHILDREN */
  .year {
    @include type('Copy');
    margin-top: $spaceXs;
    text-align: right;
  }

  .flexContainer {
    position: relative;
    display: flex;
    //overflow: hidden;
  }

  .scrollable {
    width: calc(100% - 20px);
    position: relative;
  }

  .numberValues {
    display: flex;
    flex-direction: column-reverse;
    width: 24px;
    height: 176px;
    overflow: hidden;
    justify-content: space-between;
    margin-top: $spaceXs;
    padding-right: 12px;

    @include type('Hint');

    & > div {
      text-align: right;
    }
  }

  .scrollableContainer {
    position: relative;
    box-sizing: border-box;
    padding-left: 12px;
  }

  svg.chart {
    height: 100%;
    overflow: visible;

    .currentMarker {
      fill: $colorPrimary1;
      opacity: 0.5;
      transition: all .3s ease-in-out;
    }

    & > text {
      @include type('Hint');
      text-anchor: middle;
      alignment-baseline: hanging;
    }

    .indicatorCircle {
      @include transition(opacity);
    }
    .hoverCircle:hover,
    .indicatorCircle:hover {
      cursor: pointer;
    }
  }

  .labels {
    position: relative;
    top: -24px;

    display: flex;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;

    @include type('Hint');
    color: $colorGrey1;
    text-align: center;
  }

  .tooltip {
    position: absolute;
    min-width: 95px;

    z-index: zIndex('Buffer1');
    padding: $spaceXxs $spaceXxs;
    border: 1px solid $colorGrey4;
    background-color: $colorWhite;
    border-radius: 4px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.10);

    @include type('Hint');
    @include transition(opacity);

    &:hover {
      cursor: pointer;
    }

    .row1 {
      & > span:last-child {
        padding-left: $spaceXxxs;
        color: $colorPrimary1;
      }
    }
    & > span:last-child {
      padding-top: $spaceXxxs;
    }
  }

  // Prevent chart scroll-y
  > div:first-child > div {
    overflow-y: hidden;
  }
}
