@import '/src/styles-imports/shared';

/* MODALCREDITCARD */
.modalCreditCard {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-top: space('S');

  & > button:last-child {
    margin-left: space('Xs');
  }
}
