@import '/src/styles-imports/shared';

.modalContent {
  .resultOverall {
    margin-top: $spaceS;

    .value {
      margin-top: $spaceXs;
      text-align: right;
      color: $colorPrimary1;
    }
  }

  .resultsPeer {
    margin-top: 40px;

    .peer {
      margin-top: $spaceM;

      &:first-child {
        margin-top: 40px;
      }

      & > svg {
        margin-top: $spaceXs;
      }

      .textContent {
        display: flex;
        justify-content: space-between;
        @include type('Copy');

        & > span:last-child {
          color: $colorPrimary1;
        }
      }
    }
  }

  .lowestHighestValues {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    margin-top: $spaceXxs;
    @include type('Hint');

    & > div {
      &:last-child {
        margin-left: 39px;
      }

      & > span:last-child {
        padding-left: 6px;
        color: $colorPrimary1;
      }
    }
  }

  .classification {
    margin-top: 40px;
    word-break: break-word;
  }

  & > button {
    display: block;
    margin-left: auto;
    margin-top: $spaceS;
  }
}
