@import '/src/styles-imports/shared';

/* Peer360Result */
.peer360Result {
  width: 100%;
  box-sizing: border-box;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      flex-shrink: 0;
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  .buttons {
    margin-top: $spaceS;
    display: flex;
    gap: $spaceS;
    justify-content: flex-end;
    
    @include mqSmaller($bpS) {
      flex-direction: column;
    }
  }

  .noDataText {
    @include type('Copy');
    margin-top: space('Xs');
  }

  button {
    display: block;
    margin-left: auto;
  }
}
