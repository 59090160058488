@import '/src/styles-imports/shared';

.callout {
  .triangle {
    width: 0;
    height: 0;
    margin: 0 auto;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;

    &.triangleOnTop {
      border-bottom: 11px solid $colorPrimary4;
    }
    &.triangleOnBottom {
      border-top: 11px solid $colorPrimary4;
    }

    &.left {
      margin-left: 20px;
      &.short {
        margin-left: 10px;
      } 
    }
    &.right {
      margin-left: auto;
      margin-right: 20px;
      &.short {
        margin-right: 10px;
      } 
    }
  }

  .text {
    @include type('Copy');
    color: $colorGrey1;
    background-color: $colorPrimary4;
    padding: 10px 16px;
    border-radius: 10px;
  }
}
