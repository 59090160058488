@import '/src/styles-imports/shared';

/* SHOW RESULTS BUTTON */
.showResultsButton {
  margin: space('Xs') 0;  
  display: flex;
  gap: space('Xxs');
  justify-content: flex-end;
  @include type('Copy');
  color: $colorPrimary1;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
