@import '/src/styles-imports/shared';

/* BUTTONCIRCULAR */
.buttonCircular {
  cursor: pointer;
  user-select: none;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid;
  border-color: $colorGrey3;
  box-sizing: border-box;

  text-align: center;
  @include transition(border-color, background-color);

  &.sizeS {
    width: 36px;
    height: 36px;
  }

  &.primary {
    border-color: $colorPrimary1;
    svg path {
      fill: $colorPrimary1;
    }

    &:hover {
      border-color: $colorPrimary2;
      svg path {
        fill: $colorPrimary2;
      }
    }
  }

  /* CHILDREN */
  svg {
    position: relative;
    margin-top: 50%;
    transform: translateY(-50%);

    path {
      @include transition(fill);
      fill: $colorGrey1;
    }
  }

  /* INTERACTION */
  &:hover {
    border-color: $colorPrimary1;
    svg path {
      fill: $colorPrimary1;
    }
  }

  &.active {
    border-color: $colorPrimary1;
    background-color: $colorPrimary1;
    svg path {
      fill: $colorWhite;
    }

    &:hover {
      border-color: $colorPrimary2;
      background-color: $colorPrimary2;
    }
  }

  /* MODIFIERS */
  &.disabled {
    pointer-events: none !important;
    border-color: $colorGrey4 !important;

    svg path {
      fill: $colorGrey3 !important;
    }
  }
}
