@import '/src/styles-imports/shared';

$checkmarkWidth: 14px;
$learnMoreWidth: 90px;
$clickableWidth: 200px;

/* TaskList */
.taskList {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .list {
    margin-top: $spaceXxs;

    .listItem {
      display: flex;
      align-items: center;
      padding: $spaceXs 0;
      border-bottom: 1px solid $colorGrey3;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      & > *:not(:last-child) {
        padding-right: $spaceXs;
      }

      .main {
        flex-basis: calc(100% - #{$checkmarkWidth} - #{$learnMoreWidth} - #{$clickableWidth});

        .progress {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .progressItem {
            padding-top: $spaceXxs;
            &:not(:last-child) {
              padding-right: $spaceS;
            }
          }
        }
      }

      & > svg {
        width: $checkmarkWidth;
        height: $checkmarkWidth;
        flex-shrink: 0;
      }

      .learnMore {
        flex-basis: $learnMoreWidth;
        @include type('Copy');
        color: $colorGrey2;

        @include transition(color);
        &:hover {
          cursor: pointer;
          color: $colorGrey3;
        }

        @include mqSmaller($bpS) {
          display: none;
        }
      }

      .clickable {
        flex-basis: $clickableWidth;

        display: flex;
        align-items: center;
        justify-content: flex-end;
        word-break: break-word;
        text-align: right;
        @include transition(color, fill);

        & > span {
          padding-right: $spaceXxs;
          color: $colorPrimary1;
        }
        & > svg path {
          fill: $colorPrimary1;
        }

        &:hover {
          cursor: pointer;
          & > span {
            color: $colorPrimary2;
          }
          & > svg path {
            fill: $colorPrimary2;
          }
        }
      }

      @include mqSmaller($bpS) {
        flex-wrap: wrap;

        & > *:not(:last-child) {
          padding-right: 0;

          &:first-child {
            padding-right: $spaceXs;
          }
        }

        .main {
          flex-basis: calc(100% - #{$checkmarkWidth} - #{$spaceXs});
        }
        .clickable {
          flex-basis: 100%;
          margin-top: $spaceXxs;
        }
      }

    }
  }
}
