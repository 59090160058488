@import '/src/styles-imports/shared';

/* SPIDER CHART */
.spiderChart {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .collapsibleHeader {
    display: flex;
    line-height: 2;
    > div {
      margin: 0 $spaceS;
      text-align: left;
    }
    .title {
      @include type('Hint');
      color: $colorGrey2;
    }
  }
}
