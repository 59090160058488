@import '/src/styles-imports/shared';

.selectedIndicator {
  position: absolute;
  top: 0;
  width: 3px;
  height: 48px;
  background-color: $colorPrimary1;
  transition: top 0.3s;
  z-index: zIndex('Buffer1');
}
