@import '/src/styles-imports/shared';

/* LabelCheckmark */
.labelCheckmark {
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  padding: space('Xs');
  border-bottom: 1px solid $colorGrey4;

  @include type('Copy');
  cursor: pointer;

  @include transition(color);

  /* INTERACTION */

  /* MODIFIERS */
  &:hover {
    color: $colorPrimary1;
  }

  &.active {
    color: $colorPrimary1;
  }

  &.topBorder {
    border-top: 1px solid $colorGrey4;
  }

  /* RESPONSIVE */

  /* CHILDREN */
}
