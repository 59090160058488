@import '/src/styles-imports/shared';

/* CustomReport */
.section {
  .mainTitle {
    @include type('S');
    margin-bottom: $spaceXs;
  }
}

.block {
  p {
    margin-top: 0;
  }

  &.withBorder {
    border: 1px solid #92B4F9;
    border-radius: 10px;
    padding: $spaceS;

    @include mqSmaller($bpS) {
      padding: $spaceXs;
    }

    &.blue {
      border-color: #92B4F9;
    }
    &.grey {
      border-color: $colorGrey3;
    }
    &.purple {
      border-color: #B69CFF;
    }
    &.yellow {
      border-color: #FFE894;
    }
    &.green {
      border-color: #AAE5A4;
    }
    &.red {
      border-color: #F87879;
    }
  }

  &.withBackground {
    padding: $spaceS;
    background-color: #F9FBFF;
    @include diagramGapColor(#F9FBFF);
    border-radius: 10px;

    @include mqSmaller($bpS) {
      padding: $spaceXs;
    }

    &.blue {
      background-color: #F9FBFF;
      @include diagramGapColor(#F9FBFF);
    }
    &.grey {
      background-color: #FBFBFB;
      @include diagramGapColor(#FBFBFB);
    }
    &.purple {
      background-color: #FCFBFF;
      @include diagramGapColor(#FCFBFF);
    }
    &.yellow {
      background-color: #FFFDF5;
      @include diagramGapColor(#FFFDF5);
    }
    &.green {
      background-color: #F7FEF6;
      @include diagramGapColor(#F7FEF6);
    }
    &.red {
      background-color: #FFFBFB;
      @include diagramGapColor(#FFFBFB);
    }
  }
}

.blueTitle {
  color: $colorPrimary2;
}

// Adjust hX presentation
[class^=AssessmentReportNext_body] {
  @mixin importantType() {
    font-family: $typeFamilyS !important;
    font-weight: $typeWeightS !important;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeS !important;
      line-height: $typeLineHeightS !important;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeSSmaller !important;
      line-height: $typeLineHeightSSmaller !important;
    }
  }

  main {
    h1 {
      @include importantType();
      margin-bottom: $spaceM !important;
    }
    h2 {
      @include importantType();
      color: $colorBlack !important;
    }
    h3 {
      color: $colorBlack !important;
    }
  }
}

.downloadFileLink {
  @include type('Copy');
  margin-top: $spaceM;
  margin-bottom: $spaceXxs;
  color: $colorPrimary1;
  cursor: pointer;
  @include transition(color, fill);
  display: flex;
  align-items: center;
  height: 20px;

  > :first-child {
    width: $spaceS;
  }

  &:hover {
    span {
      color: $colorPrimary2;
    }
    svg path {
      fill: $colorPrimary2;
    }
  }
  svg {
    height: 14px;
    margin-top: -6px;
    transform: translate(-3px, 3px);
    path {
      fill: $colorPrimary1;
    }
  }

  .downloadLoader {
    max-height: 14px;
  }

  &.disabled {
    pointer-events: none;
    cursor: initial;
    opacity: 0.5;
  }

  &.side {
    margin: 0;
  }
}
