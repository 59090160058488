@import '/src/styles-imports/shared';

/* QuestionPassword */
.questionPassword {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .description {
    @include type('CopyStrong');
    margin-top: space('Xs');
  }
  .inputs {
    margin-top: space('M');

    & > div:last-child {
      margin-top: space('Xs');
    }
  }
}
