@import '/src/styles-imports/shared';

/* QuestionAiFeedback */
.questionAiFeedback {
  @include basicGrid;
  word-break: break-word;
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .gridContent {
    grid-column: 2 / 12;

    @include mqSmaller($bpS) {
      grid-column: start / end;
    }

    & > div {
      margin-top: $spaceM;
    }
  }

  .infoCardWrapper {
    text-align: left;
  }

  .textareas {
    display: flex;
    gap: $spaceXs;
  }

  .textarea {
    flex: 1;
    text-align: left;

    .hint {
      flex: 1 0;

      @include type('Hint');
      padding-left: 8px;
      text-align: right;

      & > span.error {
        color: $colorDanger1;
      }
    }

    & > button {
      display: block;
      margin: $spaceXs 0 0 auto;
    }

    .loading {
      max-height: 278px;
      padding: 0 $spaceXs;
    }

    @include mqSmaller($bpS) {
      margin-top: $spaceXs !important;
    }
  }
}
