@import '/src/styles-imports/shared';

/* BalancedYou */
.balancedYou {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .gridContainer {
    @include basicGridNoMargins;

    .left {
      grid-column: start / 9;

      & > div:not(:first-child) {
        margin-top: $spaceXs;
      }
    }

    .right {
      grid-column: 9 / end;

      & > div:not(:first-child) {
        margin-top: $spaceXs;
      }
    }

    @include mqSmaller($bpM) {
      .left, .right {
        grid-column: start / end;
      }
      .right {
        & > div {
          margin-top: $spaceXs;
        }
      }
    }
  }

  .exportView {
    width: 600px;
    max-width: 600px;
    margin: space('M') auto 0;

    // loading indicator
    .loading {
      margin-bottom: space('M');
      margin-top: 0;
      text-align: center;
      & > div {
        justify-content: center;
      }
    }

    & > [id^=export], & > .assessment {
      div, text, circle {
        pointer-events: none;
      }
    }

    & > .assessment {
      margin-top: space('Xs');
    }
  }

  .exportViewFooter {
    width: 600px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    & > button {
      display: block;
      margin-left: auto;
    }
  }

}
