@import '/src/styles-imports/shared';

.attachmentsModalAdd {
  h5 {
    @include type('Xxs');
    margin-bottom: 8px;
  }

  h6 {
    @include type('LabelL');
    margin-bottom: 8px;
  }

  p {
    @include type('Copy');
    margin-bottom: 24px;
  }

  form {
    > div {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: $spaceS;
      }
    }
  }
}

.linkAddButtonWrapper {
  text-align: right;

  &.left {
    text-align: left;
  }
}

.success {
  margin-bottom: $spaceS;
}
.errorMessage {
  margin-top: $spaceXs;
  margin-bottom: $spaceXs;
  @include type('Copy');
  color: $colorDanger1;
}

.documentAddButtonWrapper {
  display: flex;
  gap: $spaceXs;
}
