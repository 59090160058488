@import '/src/styles-imports/shared';

/* BodyBatteryStatistics */
.bodyBatteryStatistics {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .table {
    margin-top: space('Xs');

    .tHeader {
      display: flex;

      .tHeaderItem {
        @include type('LabelL');

        &:first-child {
          flex: 1;
        }

        &:not(:first-child) {
          width: 64px;
          max-width: 64px;
          margin-left: space('Xxs');

          white-space: pre-wrap;
        }
      }
    }

    .tRows {
      min-width: 100%;
      box-sizing: border-box;
      margin-right: space('Xs');

      &:last-child {
        margin-right: 0;
      }

      .tRow {
        display: flex;
        padding: space('Xs') 0;
        border-bottom: 1px solid $colorGrey3;

        &:first-child {
          border-top: 1px solid $colorGrey3;
        }

        & > span {
          flex: 1;
        }

        & > svg {
          margin-right: space('L');
          flex-shrink: 0;

          &:last-child {
            margin-right: 40px;
          }
        }

        @include mqSmaller($bpS) {
          & > span {
            width: 72px;
            @include ellipsis();
          }
        }
      }
    }

    // Scrollable controls
    & > div:last-child {
      & > div:last-child {
        margin-top: space('Xs');
      }
    }
  }
}
