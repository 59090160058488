@import '/src/styles-imports/shared';

/* ASSESSMENTHEADER */
.assessmentHeader {

  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 60px;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpM) {
    padding: 0 $spaceXs;
  }

  /* CHILDREN */

  .left {
    @include ellipsis();
    padding-right: space('S');

    @include type('LabelL');
    color: $colorPrimary1;
  }

  .right {
    height: 100%;
  }

  .cancel {
    cursor: pointer;

    display: flex;
    align-items: center;

    height: 100%;
    padding-left: $spaceXs;

    @include type('Copy');
    color: $colorPrimary1;

    &:hover {
      color: $colorPrimary2;
    }
  }
}
