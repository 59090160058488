@import '/src/styles-imports/shared';

/* PILL GROUP */
.pillGroup {
  position:relative;
  width: 100%;

  // wrap mode
  &.wrap {
    margin: $spaceXs 0;
    display: flex;
    gap: $spaceXxs 0;
    flex-direction: row;
    flex-wrap: wrap;
    white-space: nowrap;
  }

  .pillContainer {
    display: flex;
  }
}
