@import '/src/styles-imports/shared';

/* VacancyUsers */
.vacancyUsers {
  margin-top: $spaceM;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .vuHeader {
    @include type('LabelL');
    padding: $spaceM $spaceXs !important;
  }

  .vuContent {
    padding-bottom: $spaceXs;

    & > button {
      margin: $spaceXs $spaceXs 0;
    }

    .userGroup {
      padding-bottom: $spaceXs;
      border-bottom: 1px solid $colorGrey4;

      &:not(:first-child) {
        padding-top: $spaceXs;
      }

      // role label
      & > div:first-child {
        @include type('LabelM');
        padding: 0 $spaceXs $spaceXs;
      }
    }

    .noUsers {
      @include type('Copy');
      margin: 0 $spaceXs;
    }

    .vUser {
      display: flex;
      align-items: center;
      padding: $spaceXs;
      border-top: 1px solid $colorGrey4;

      &:first-child {
        border-top: 1px solid $colorGrey4;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .imgCircle {
        flex-shrink: 0;
      }

      .namePosition {
        flex: 1 1;
        margin-left: space('Xxs');
        @include ellipsis();
        .name {
          @include type('LabelL');
          @include ellipsis();
        }
        .position {
          @include type('Hint');
          @include ellipsis();
          margin-top: 6px;
        }
      }

      .optionsMenu {
        margin-left: 4px;
        margin-top: 8px;
      }
    }

    .generalError {
      @include type('Copy');
      color: $colorDanger1;
      margin-left: $spaceXs;
    }
  }
}

.modalRole {
  margin-top: $spaceS;
  & > div:nth-child(2) {
    margin-top: $spaceXxs;
  }
  & > div:nth-child(3) {
    margin-top: $spaceXs;
  }
}
.modalError {
  @include type('Copy');
  color: $colorDanger1;
  margin-left: 0;
  padding-bottom: $spaceXs;
}
