@import '/src/styles-imports/shared';

/* PROGRESSBAR */
.progressBar {
  position: relative;
  height: $progressBarHeight;
  width:100%;
  background-color: $colorGrey4;

  @include transition(height);

  &.sizeS {
    height: $progressBarHeightSmall;

    .progressIndicator {
      height: $progressBarHeightSmall;
    }
  }

  &.strong {
    background-color: white;
    .progressIndicator {
      background-color: $colorPrimary1 !important;
    }
  }

  &.hide {
    height: 0px;
    overflow: hidden;
  }

}
.progressIndicator {
  position:absolute;
  height: $progressBarHeight;
  background-color: $colorPrimary1;

  @include transition(width, background-color, transform);

  &:not(.loading) {
    transform: translateX(0%);
  }

  &.loading:not(.alternateAnimation) {
    animation: pulse 1.2s infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
    background-color: $colorPrimary3;
  }
  &.loading.alternateAnimation {
    animation: colorPulse 600ms infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
  }

}

@keyframes pulse {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(400%);
  }
}
@keyframes colorPulse {
  0% {
    background-color: $colorPrimary1;
  }
  100% {
    background-color: $colorPrimary3;
  }
}
