@import '/src/styles-imports/shared';

/* PEERICONSLINE */
.peerIconsLine {
  min-height: 21px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: space('Xxxs');
  margin: 8px 0;

  > svg {
    transition: fill 300ms ease;
  }

  .invited {
    fill: $colorPrimary1;
  }
  .missing {
    fill: $colorGrey2;
  }
  .optional {
    fill: $colorGrey3;
  }
  .error {
    fill: $colorDanger1;
  }

  .plus {
    width: 10px;
    height: 10px;
  }
}
