@import '/src/styles-imports/shared';

.diagram {
  margin-top: space('S');
  box-sizing: border-box;
  width: 100%;
}

.copyright {
  margin-top: space('S');
  span {
    @include type('Hint');
    color: $colorPrimary1;
    cursor: pointer;
    @include transition(color);
    &:hover {
      color: $colorPrimary2;
    }
  }
}

//REPORT
.infoModalResult {
  color: $colorGrey1;
  > div:first-child {
    margin: 0 auto;
  }
  .name {
    @include type('Xxs');
    margin-top: space('Xxs');
    text-align: center;
  }
  .labelDescription {
    @include type('LabelL');
    margin-top: space('Xs');
    text-align: center;
  }
  .callout {
    @include type('Copy');
    margin-top: space('Xs');
  }
  .reportDiagram {
    margin-top: space('Xs');
    .header {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xs');
      span {
        @include type('Copy');
      }
      span:last-child {
        color: $colorPrimary1;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xxs');
      span {
        @include type('Hint');
        color: $colorGrey2;
      }
      span.blue {
        color: $colorPrimary1;
      }
    }
    svg {
      margin-top: space('Xs');
    }
  }
}
