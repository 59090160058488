@import '/src/styles-imports/shared';

.enter {
  pointer-events:none;
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  @include transition(opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
}
.exitActive, .exitDone {
  display: none;
}

.mindfulnessJourneyResult {
  // CHILDREN
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  .controlsPanel {
    margin: space('Xxs') 0 space('Xs');

    .historySection {
      .legend {
        margin-top: space('Xxs');
        display: flex;
        flex-wrap: wrap;

        @include type('Hint');
        .blue, .gray {
          .rect {
            width: 16px;
            height: 3px;
            background-color: $colorPrimary1;

            display: inline-block;
            vertical-align: middle;
          }
          span {
            padding-left: space('Xxs');
          }
        }
        .blue {
          margin-right: 22px;
        }
        .gray {
          .rect {
            background-color: $colorGrey3;
          }
        }
      }

      @include mqSmaller($bpS) {
        text-align: center;
        display: block;
        margin: 0 auto;
      }
    }
  }

  .viewReportButton {
    button {
      display: block;
      margin-left: auto;
      margin-bottom: 30px;
      margin-top: space('M');
    }
  }

  .infoTitle {
    @include mqSmaller($bpM) {
      justify-content: flex-start;
    }
  }

  .blockWithPaddings {
    padding: space('M');

    @include mqSmaller($bpM) {
      padding: space('S') space('Xs') space('M') !important;
    }

    & > svg {
      margin-top: space('Xs');
    }
  }

  .score {
    @include type('LabelL');
    text-align: center;
    margin-bottom: space('S');

    .scoreRatio {
      color: $colorPrimary1;
    }
  }

  .collapsibles {
    .collapsibleDetail {
      margin: 0 space('S');
      display: flex;
      justify-content: space-between;
      .item {
        margin-top: space('Xs');
        .itemTitle {
          @include type('Hint');
          color: $colorGrey2;
          margin-bottom: 8px;
        }
        .itemValue {
          color: $colorPrimary1;
        }
      }
    }
  }

  .basedOn {
    margin: space('Xs');
    @include type('Hint');
    color: $colorPrimary1;
  }

  .historyButton {
    margin-top: space('S');
    display: flex;
    justify-content: flex-end;

    @include mqSmaller($bpM) {
      margin: space('Xs');
    }
  }

  // RESPONSIVE
  // componensate for Card[hasBreakoutSmallerS]
  @include mqSmaller($bpM) {
    .title {
      padding: space('Xs');
      padding-top: space('S');
    }
    .controlsPanel {
      padding: 0 space('Xs');
    }
    .collapsibles {
      margin-bottom: space('M');
    }
  }
}
