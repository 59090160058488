@import '/src/styles-imports/shared';

/* BOOK COACHING */
.bookCoaching {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}

/* MODAL */
.primaryButton {
  display: flex;
  align-items: center;
  gap: space('Xxs');
}
