@import '/src/styles-imports/shared';

/* IST RANGE DIAGRAM */
.istRangeDiagram {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  svg {
    width: 100%;
    height: space('Xs');
    margin-bottom: 4px;

    rect {
      height: inherit;
    }

    // Individual rectangles
    .container {
      fill: $colorGrey4;
    }
    .range {
      fill: $colorPrimary3;
    }
    .score {
      animation: animateBar .3s ease-in-out;
      fill: $colorPrimary1;
      &.danger {
        fill: $colorDanger1;
      }
    }
    .prevScore {
      animation: animateBar .3s ease-in-out;
      fill: #92B4F9;
      &.danger {
        fill: $colorRedLight;
      }
    }
  }

  .label {
    display: flex;
    justify-content: space-between;
    word-break: break-word;

    .score {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 80px;
      font-weight: $typeWeightL;
      text-align: right;
    }

    .trend {
      display: flex;
      align-items: center;
      @include type('Copy');
      margin-left: $spaceXxs;

      & > span {
        flex-shrink: 0;
      }

      & > svg {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
        margin-left: $spaceXxxs;
        margin-bottom: 0;
      }

      &.blue {
        & > svg {
          transform: rotate(45deg);
          path {
            fill: $colorPrimary1;
          }
        }
      }
      &.red {
        & > svg {
          transform: rotate(135deg);
          path {
            fill: $colorDanger1;
          }
        }
      }
      &.grey {
        & > svg {
          transform: rotate(90deg);
          path {
            fill: $colorGrey2;
          }
        }
      }
    }
  }

  @keyframes animateBar {
    from {
      x: 0;
    }
    to {
      x: auto;
    }
  }
}
