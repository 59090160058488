@import '/src/styles-imports/shared';

/* DimensionResult */
.dimensionResult {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .diagramBlock {
    margin-top: $spaceXs;

    // result value
    & > div:first-child {
      @include type('Copy');
      color: $colorPrimary1;
      text-align: right;
    }
    .diagram {
      margin-top: $spaceXs;
    }
  }

  .peerResult {
    margin-top: $spaceM;

    &:first-child {
      margin-top: 40px;
    }

    & > svg {
      margin-top: $spaceXs;
    }

    .textContent {
      display: flex;
      justify-content: space-between;
      @include type('Copy');

      & > span:last-child {
        color: $colorPrimary1;
      }
    }
  }

  .lowestHighestValues {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;

    margin-top: $spaceXxs;
    @include type('Hint');

    & > div {
      &:last-child {
        margin-left: 39px;
      }

      & > span:last-child {
        padding-left: 6px;
        color: $colorPrimary1;
      }
    }
  }

}
