@import '/src/styles-imports/shared';

/* CandidateQuickView */
.candidateQuickView {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .header {
    width: 100%;
    position: sticky;
    box-sizing: border-box;
    background-color: $colorWhite;
    z-index: zIndex('Buffer1');

    display: flex;
    padding: space('S') space('Xs');
    border-bottom: 1px solid $colorGrey4;
    .rightBlock {
      margin-left: space('Xxs');
      .name {
        @include type('LabelL');
      }
      .position {
        @include type('Hint');
        margin-top: space('Xxxs');
      }
    }
  }

  .content {
    height: calc(100vh - 89px - #{$spaceM} - #{$spaceM});
    padding: space('M') space('Xs');
    overflow-y: auto;

    .candidatesData {
      padding-bottom: space('M');
    }

    .listItem {
      padding: space('Xs') space('Xxs');

      //margins with negative values to decrease the margins of CollapsibleNext content
      margin-right: -(space('Xs'));
      margin-left: -(space('Xxs'));

      border-bottom: 1px solid $colorGrey4;

      &:first-child {
        border-top: 1px solid $colorGrey4;
      }

      .label {
        @include type('Hint');
        color: $colorGrey2;
      }
      .value {
        @include type('Copy');
        margin-top: space('Xxs');

        ul {
          margin-left: space('S');
        }
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      padding: space('Xs') 0 space('M');
      & > a {
        padding-right: space('Xs');
        padding-top: space('Xs');
      }
    }

    .assessments {
      & > div:not(:first-child){
        margin-top: space('Xs');
      }
    }

    .roleFit {
      margin-top: space('S');

      & > div:not(:first-child) {
        margin-top: space('Xs');
      }
    }
  }
}
