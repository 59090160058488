@import '/src/styles-imports/shared';

/* Ist */
.ist {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .gridContainer {
    @include basicGridNoMargins;

    .left {
      grid-column: start / 9;

      & > div:not(:first-child) {
        margin-top: $spaceXs;
      }

      & > div:first-child {
        margin-top: 0;
      }
    }

    .recommendations {
      margin-top: $spaceL !important;
    }

    .right {
      grid-column: 9 / end;

      & > div:not(:first-child) {
        margin-top: $spaceXs;
      }
    }

    @include mqSmaller($bpM) {
      .left, .right {
        grid-column: start / end;
      }
      .right {
        & > div {
          margin-top: $spaceXs;
        }
      }
    }
  }
}
