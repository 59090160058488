@import '/src/styles-imports/shared';

/* PILL */
.pill {
  border-radius: 12px;
  padding: 2px $spaceXxs;
  margin-right: $spaceXxs;
  @include type('Copy');
  color: $colorGrey1;
  background-color: $colorGrey5;

  @include transition(color, background-color);

  &:last-child {
    margin-right: 0;
  }

  &.primary, &.primaryAndWhite {
    padding: $spaceXxxs $spaceXxs;
    border-radius: 14px;
    &.active {
      color: $colorWhite;
      background-color: $colorPrimary1;
    }
  }

  &.primaryAndWhite {
    background-color: $colorWhite;
  }

  &.loading {
    display: flex;
    align-items: center;

    .loader {
      width: 14px;
      height: 14px;
      margin-left: $spaceXxs;

      @include animationLonger(rotate);
      animation-iteration-count: infinite;
      animation-timing-function: ease;

      & > svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor:pointer;
      color: $colorPrimary1;
      background-color: $colorPrimary4;
    }
  }
  &.active {
    color: $colorPrimary1;
    background-color: $colorPrimary4;
  }
  &.pressed {
    color: $colorPrimary1;
    background-color: $colorPrimary3;
  }
  &.hasColorIndicator {
    display: flex;
    align-items: center;

    .rectangle {
      width: 10px;
      height: 2px;
      margin-left: $spaceXxxs;
    }
  }
}
