@import '/src/styles-imports/shared';

.myOrganization {
  background: $colorGrey6;

  .detailContainer {
    max-width: 100% !important;
    padding: space('M') 0;

    .title {
      @include type('Xxs');
      color: $colorGrey1;
      animation: slideUp $animationDuration $animationFunction;
      margin-bottom: 24px;
    }

    .detailGrid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      > * {
        min-width: 100px;
      }

      .secondCol {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      @include mqSmaller($bpS) {
        grid-template-columns: 1fr;
      }
    }
  }
}
