@import '/src/styles-imports/shared';

/* InstanceCreationSuccess */
.instanceCreationSuccess {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .gridContent {
    grid-column: 4 / 10;
    text-align: center;

    @include mqSmaller($bpM) {
      grid-column: 2 / 8;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
    }

    & > div:not(:first-child), & > button {
      margin-top: space('M');
    }
  }
}
