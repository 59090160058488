@import '/src/styles-imports/shared';

/* ParticipantsModal */
.participantsModal {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  & > div:not(:first-child) {
    margin-top: $spaceM;
  }

  .list {
    .listItem {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: $spaceXs;
      border-top: 1px solid $colorGrey4;
      border-bottom: 1px solid $colorGrey4;

      .left {
        display: flex;
        align-items: center;

        .textContent {
          margin-left: $spaceXxs;
          & > div:first-child {
            @include type('LabelL');
          }
          & > div:last-child {
            @include type('Hint');
            margin-top: $spaceXxxs;
          }
        }
      }

      .right {
        .notCompleted {
          @include type('Hint');
          text-align: right;
        }
        .reminder {
          display: flex;
          align-items: center;
          margin-top: $spaceXxxs;
          @include type('Copy');
          color: $colorPrimary1;

          & > span {
            padding-left: $spaceXxs;
          }

          @include transition(color);
          &:hover {
            cursor: pointer;
            color: $colorPrimary2;
          }

          &.disabled {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }

      @include mqSmaller($bpS) {
        padding: $spaceXs 0;
        flex-wrap: wrap;

        .right {
          margin-top: 8px;
          &.internalUser {
            margin-left: 44px;
          }

          .notCompleted {
            text-align: left;
          }
        }
      }
    }
  }
}
