@import '/src/styles-imports/shared';

.big5Result {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  .button button {
    display: block;
    margin: space('S') 0 space('Xxs') auto;
  }

  .reportButton button {
    display: block;
    margin: space('S') 0 0 auto;
  }

  .noDataText {
    @include type('Copy');
    margin-top: space('Xs');

    @include mqSmaller($bpM) {
      margin-right: space('Xs');
      margin-left: space('Xs');
    }
  }

  .infoModalResult {
    margin-top: space('Xs');
    color: $colorGrey1;

    > div:first-child {
      margin: 0 auto;
    }
    .copyDescription {
      @include type('Copy');
      margin-top: space('Xs');
    }
    .reportDiagram {
      margin-top: space('Xs');
      .header {
        display: flex;
        justify-content: space-between;
        margin-top: space('Xs');
        span {
          @include type('Copy');
        }
        span:last-child {
          color: $colorPrimary1;
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        margin-top: space('Xxs');
        span {
          @include type('Hint');
          color: $colorGrey2;
        }
        span.blue {
          color: $colorPrimary1;
        }
      }
    }
    .facets {
      margin-top: space('S');
      .facetsTitle {
        @include type('LabelL');
      }
      .facet {
        margin-top: space('S');
        .facetHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include type('Copy');
          color: $colorGrey1;

          span:last-child {
            color: $colorPrimary1
          }
        }
        .facetFooter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: space('Xxs');

          @include type('Hint');
          color: $colorGrey2;
        }
      }
    }
  }

  .footerText {
    @include type('Hint');
    color: $colorPrimary1;
    margin-top: space('M');

    @include mqSmaller($bpM) {
      margin-right: space('Xs');
      margin-left: space('Xs');
    }
  }

  // RESPONSIVE
  // componensate for Card[hasBreakoutSmallerS]
  @include mqSmaller($bpM) {
    .title {
      padding: space('Xs');
      padding-top: space('S');
    }
    [class*=Card] {
      padding-bottom: space('M');
    }
    .button button {
      margin-bottom: space('Xxs');
    }
    .reportButton button {
      margin-right: space('Xs');
      margin-bottom: 0;
    }
  }
}

.infoModalResult {
  margin-top: space('Xs');

  > div:first-child {
    margin: 0 auto;
  }

  .reportDiagram {
    margin-top: space('Xs');
    .header {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xs');
      span {
        @include type('Copy');
      }
      span:last-child {
        color: $colorPrimary1;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      margin-top: space('Xxs');
      span {
        @include type('Hint');
        color: $colorGrey2;
      }
      span.blue {
        color: $colorPrimary1;
      }
    }
  }
  .facets {
    margin-top: space('S');
    .facetsTitle {
      @include type('LabelL');
    }
    .facet {
      margin-top: space('S');
      .facetHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include type('Copy');
        color: $colorGrey1;

        span:last-child {
          color: $colorPrimary1
        }
      }
      .facetFooter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: space('Xxs');

        @include type('Hint');
        color: $colorGrey2;
      }
    }
  }
}

