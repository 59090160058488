@import '/src/styles-imports/shared';

/* RECOMMENDATION CAROUSEL */
.recommendationCarousel {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .scrollingButtons {    
    display: flex;
    justify-content: flex-end;
    gap: $spaceXs;

    @include transitionLong(opacity, transform);
    &.hidden {
      opacity: 0;
    }
  }

  .scrollableCard {
    min-width: calc((100% - #{$spaceXs}) / 2);
    margin-right: $spaceXs;
    &:last-child {
      margin-right: 0;
    }

    > * {
      height: 100%; // Make all cards of the same height
    }

    @include mqSmaller($bpS) {
      min-width: 100%;
    }
  }
}
