@import '/src/styles-imports/shared';

/* ResultSummary */
.resultSummary {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .blue {
    color: $colorPrimary2;
  }
}
