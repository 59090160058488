@import '/src/styles-imports/shared';

@mixin close {
  min-width: 0;
  margin-left: 0;
  padding: 0;
  opacity: 0;
  @include transition(padding, margin-left, opacity);
}

@mixin open {
  min-width: 10px;
  margin-left: 6px;
  padding: 0 4px;
  opacity: 1;
  @include transition(padding, margin-left, opacity);
}

// Animation for .counter <BluCSSTransition>
.enter {
  @include close;  
}
.enterActive, .enterDone {
  @include open;  
}
.exit {
  @include open;
}
.exitActive, .exitDone {
  @include close;  
}
