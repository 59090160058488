@import '/src/styles-imports/shared';

.errorScreen {
  @include basicGrid;
  .error {
    grid-column: 4/10;
    margin-top: 100px;
    text-align: center;

    @include mqSmaller($bpM) {
      grid-column: 2/8;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
    }

    .gearEmoji {
      @include type('L');
    }
    .title {
      @include type('S');
      margin-top: 16px;
    }
    .description {
      @include type('Copy');
      margin-top: space('Xs');
    }
    button {
      margin-top: space('M');
    }
  }
}
