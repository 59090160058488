@import '/src/styles-imports/shared';

$markerWidth: 4px;
$markerHeight: 20px;

$legendMarkerXY: 8px;

/* CLIFTONRESULT */
.cliftonResult {


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpM) {
    .title {
      padding: $spaceS $spaceXs 0 $spaceXs;
    }
    .legend {
      margin-bottom: $spaceM;
      .item {
        flex-basis: 100%;
        &:nth-child(n + 2) {
          margin-top: $spaceXxs;
        }
      }
    }
  }

  /* CHILDREN */
  .title {
    @include type('LabelL')
  }
  .strengths {
    margin-top: $spaceXs;
  }
  .strength {
    position: relative;
    padding: $spaceXs;
    border-bottom: 1px solid $colorGrey3;

    &:first-child {
      border-top: 1px solid $colorGrey3;
    }

    &:before {
      position: absolute;
      content: " ";
      top: $spaceXs;
      left: $spaceXs;
      width: $markerWidth;
      height: $markerHeight;
      background-color: $colorGrey3;
    }

    // dimension modifiers
    &.executing:before {
      background-color: $colorCliftonExecuting;
    }
    &.influencing:before {
      background-color: $colorCliftonInfluencing;
    }
    &.relationship:before {
      background-color: $colorCliftonRelationship;
    }
    &.strategic:before {
      background-color: $colorCliftonStrategic;
    }

    .index {
      margin-left: $spaceXxs + $markerWidth;
    }
    .label {
      margin-left: $spaceXs;
    }
  }
  .legend {
    display: flex;
    flex-wrap: wrap;
    margin-top: $spaceS;

    .item {
      position: relative;
      margin-left: $spaceXs;
      padding-left: $spaceXxs + $legendMarkerXY;

      @include type('Hint');

      &:before {
        position: absolute;
        content: " ";
        top: 4px;
        left: 0px;
        width: $legendMarkerXY;
        height: $legendMarkerXY;
        background-color: $colorGrey3;
      }

      &.executing:before {
        background-color: $colorCliftonExecuting;
      }
      &.influencing:before {
        background-color: $colorCliftonInfluencing;
      }
      &.relationship:before {
        background-color: $colorCliftonRelationship;
      }
      &.strategic:before {
        background-color: $colorCliftonStrategic;
      }
    }
  }
}
