@import 'responsive';


// header & subnav
$headerHeight: 60px;
$subNavHeight: 40px;
$headerHeightStandalone: 65px;

$headerHeightXs: 60px;
$subNavHeightXs: 82px;
$headerHeightStandaloneXs: 55px;

$headerHeightMedium: 80px;
$headerHeightLarge: 100px;

// footer
$footerHeight: 60px;
$footerHeightXs: 100px;
$footerHeightLarge: 80px;

// mainNav
$mainNavWidth: 244px;

// logo
$logoWidth: 150px;
// $logoHeight: 30px;
$logoHeight: 24px;
$logoWidthLarge: 250px;
// $logoHeightLarge: 50px;
$logoHeightLarge: 34px;

$progressBarHeight: 4px;
$progressBarHeightSmall: 2px;
$progressBarHeightLarge: 10px;


$inputHeight: 50px;
$inputHeightL: 54px;
$inputBorderRadius: 2px;
$iconBackgroundXY: 44px;

$interactionElementHeight: 40px;

:export {
  headerHeight: $headerHeight;
  progressBarHeight: $progressBarHeight;
  headerHeightLarge: $headerHeightLarge;
  footerHeight: $footerHeight;

  iconBackgroundXY: $iconBackgroundXY;
  interactionElementHeight: $interactionElementHeight;
}


// basic page setup with (best used with basicGridNoMargins from _responsive.scss)
//  - min-height according to header and footer
//  - uniform page paddings
//  - responsive paddings
@mixin basicPage {
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$spaceM} - #{$spaceL}); // spaceM - top margin, spaceL - bottom padding
  padding-left: $gridMarginLarge;
  padding-right: $gridMarginLarge;
  padding-top: space('M');
  padding-bottom: space('L');

  @include mqSmaller($bpS) {
    padding-left: $gridMarginSmall;
    padding-right: $gridMarginSmall;
  }
}

// deprecated: pages should not manage their own margins
@mixin basicPageNoMargins {
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
}

@mixin pageBackgroundGrey {
  background-color: $colorGrey6;
}

@mixin basicPageGrey {
  @include basicPage;
  @include pageBackgroundGrey;
}

@mixin basicPageWithGrid {
  @include basicPage;
  @include basicGridNoMargins;
}
@mixin basicPageWithGridGrey {
  @include basicPageGrey;
  @include basicGridNoMargins;
}


@mixin pageMargins {
  margin: 0px $gridMarginLarge;

  @include mqSmaller($bpS) {
    margin: 0px $gridMarginSmall;
  }
}


@mixin boxShadowAnimatable {
  pointer-events:none;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: $inputBorderRadius;
  box-shadow: $shadowDark;
  opacity: 0;
  @include transition(opacity);
}

@mixin formElement {
  cursor: text;

  position: relative;

  box-sizing: border-box;
  height: $inputHeight;
  width: 100%;

  padding: space('Xs');
  line-height: $inputHeight - $spaceXs * 2;

  border-radius: $inputBorderRadius;
  background-color: $colorGrey5;
  color: $colorBlack;

  @include transition(background-color);

  // // box shadow (animate smoothly)
  &:after {
    @include boxShadowAnimatable();
  }

  &:hover {
    background-color: $colorWhite;

    &:after {
      opacity: 1;
    }
  }
}


/* ICON WITH BACKGROUND */
@mixin iconWithBackground (
  $backgroundColorHover: $colorPrimary3,
  $pathColor: $colorPrimary1,
  $pathColorHover: $colorPrimary2
) {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;
  width: $iconBackgroundXY;
  height: $iconBackgroundXY;
  padding: $spaceXxs;

  border-radius: 50%;


  @include transition(background-color);

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  &:hover {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
    background-color: $backgroundColorHover;
    svg path {
      fill: $pathColorHover;
    }
  }

  svg path {
    fill: $pathColor;
    @include transition(fill);
  }
};

@mixin iconWithBackgroundGrey (
  $pathColor: $colorPrimary1,
  $pathColorHover: $colorPrimary2
) {
  @include iconWithBackground($colorGrey4, $pathColor, $pathColorHover)

}



@mixin dialogQuestion {

  // LAYOUT
  .grid {
    @include basicGridNoMargins;
    .gridContent {
      grid-column: 4/10;
      text-align: center;

      /* RESPONSIVE */
      @include mqSmaller($bpM) {
        grid-column: 2/8 !important;
      }

      @include mqSmaller($bpS) {
        grid-column: start/end !important;
        padding-top: 4px;
      }
    }
  }


  // CHILDREN
  .formRow {
    margin-top: $spaceXs;

    &.center {
      display: flex;
      justify-content: center;
    }

    &.notFullWidth {
      width: calc(100% - 44px - #{$spaceXs});
    }
  }

  .customInput {
    display: flex;
    //justify-content: space-between;
    align-items: center;

    & > div:first-child {
      width: calc(100% - 44px - #{$spaceXs});
    }

    .deleteInput {
      width: 44px;
      height: 44px;
      box-sizing: border-box;
      border: 1px solid $colorGrey3;
      border-radius: 22px;

      margin-left: space('Xs');
      text-align: center;
      cursor: pointer;
      @include transition(border-color, fill);

      svg {
        margin-top: 11px;
        g path {
          fill: $colorGrey1;
        }
      }

      &:hover {
        border-color: $colorGrey2;
      }
    }
  }

  .bigMargin {
    margin-top: $spaceS;
  }
  .biggerMargin {
    margin-top: $spaceM;
  }

  .radioButtonGroupContainer {
    display: flex;
    justify-content: center;
  }

  .assessmentRadioButtonGroup {
    display: inline-block;
    width: auto !important;

    label[class*=assessmentRadioButton] {
      width: auto !important;
      margin-bottom: $spaceS;

      @include type('CopyStrong');

      [class*=name] {
        width: auto;
      }
    }
  }

  .radioButtonGroup {
    display: inline-block;
    width: auto !important;

    label {
      width: auto !important;
      margin-bottom: $spaceS;

      @include type('CopyStrong');

      [class*=name] {
        width: auto;
        margin-left: $spaceXs;
      }
      [class*=checkmark] {
        height: 40px;
        width: 40px;
        &:after {
          top: 16px;
          left: 16px;
        }
      }
    }
  }
}

@mixin dialogIntermission {

  @include basicGridNoMargins;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  @include mqSmaller($bpL) {
  //@include mediaM() {
    .content, .content464, .content466, .content664 {
      grid-column: 4 / 10 !important;
    }
    .content686  {
      grid-column: 3 / 11 !important;
    }

    .contentBreakout {
      grid-column: start / end !important;
    }
  }
  @include mqSmaller($bpM) {
    .content, .content464, .content664 {
      grid-column: 3 / 7 !important;
    }
    .content466, .content686 {
      grid-column: 2 / 8 !important;
    }
    .contentBreakout {
      grid-column: start / end !important;
    }
  }

  @include mqSmaller($bpS) {
    .content, .content464, .content466, .content664, .content686 {
      grid-column: start / end !important;
    }
  }

  /* CHILDREN */
  .content, .content464 {
    margin-top: - $spaceL;
    grid-column: 5 / 9;
  }

  .content466 {
    grid-column: 5 / 9;
  }

  .content664 {
    grid-column: 4 / 10;
  }

  .content686 {
    grid-column: 4 / 10;
  }

  .contentBreakout {
    grid-column: 2 / 12;
  }

  .formRow {
    margin-top: $spaceXs;
  }

  .teaser {
    svg {
      width: 100%
    }
  }
  .title {
    @include type('S');

    &.center {
      text-align: center;
    }
  }
  .text {
    margin-top: $spaceXs;
    @include type('CopyStrong');
  }
  .controls {
    display: flex;
    justify-content: space-between;

    margin-top: $spaceM;
  }
}

