@import '/src/styles-imports/shared';

.flexRow {
  display: flex;
  justify-content: space-between;
  margin-top: space('Xs');

  border-top: 1px solid $colorGrey3;
  border-bottom: 1px solid $colorGrey3;
  padding: space('Xs') space('Xxs');

  & > span:last-child {
    padding-left: space('Xs');
  }
}

.list {
  margin-top: space('Xs');
  .listItem {
    padding: space('Xs');
    border-bottom: 1px solid $colorGrey3;

    @include type('Copy');

    &:first-child {
      border-top: 1px solid $colorGrey3;
    }
  }
}

.row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.row2 {
  width: 90%;
  margin-top: space('Xxs');

  color: $colorGrey2;

  // Remove blu-markdown bottom spacing
  > div {
    line-height: 0;
    p {
      line-height: 20px;
    }
  }
}

.runNote {
  color: $colorGrey2;
  margin-top: space('Xxs');
  font-style: italic;
}

.row3 {
  margin-top: space('Xs');
}
