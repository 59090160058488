@import '/src/styles-imports/shared';

.shareVacancy {
  .label {
    @include type('LabelL');
    margin-top: space('S');
  }
  .inputs {
    & > div, & > label {
      margin-top: space('S');
    }
  }

  .rows {
    margin-top: space('S');
  }
  .row {
    padding: space('Xs') space('Xxs');
    border-bottom: 1px solid $colorGrey3;

    &:first-child {
      border-top: 1px solid $colorGrey3;
    }

    .line1 {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        max-width: 70%;
        flex: 1;
        padding-right: space('Xxs');
        word-break: break-all;
      }
      .trashIcon {
        width: 36px;
        height: 36px;
        box-sizing: border-box;

        flex-shrink: 0;

        margin-right: space('Xxs');
        padding-top: 6px;
        text-align: center;
        border: 1px solid $colorPrimary1;
        border-radius: 22px;
        cursor: pointer;

        @include transition(border-color, fill);

        &:hover {
          border-color: $colorPrimary2;
          svg path {
            fill: $colorPrimary2;
          }
        }
      }
    }
    .line2 {
      display: flex;
      flex-wrap: wrap;
      margin-top: space('Xs');
      @include type('Hint');

      & > span {
        margin-right: space('Xxs');
        margin-bottom: space('Xxs');

        &.expired {
          color: $colorDanger1;
        }
      }
    }
  }

  // RESPONSIVE
  @include mqSmaller($bpS) {
    .line1 {
      flex-wrap: wrap;
      justify-content: flex-end !important;

      a {
        flex-basis: 100% !important;
        max-width: 100% !important;

        margin-bottom: $spaceXxs;
      }
    }
  }
}
