@import '/src/styles-imports/shared';


.enter {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-5px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-5px) !important;
  @include transition(transform, opacity);
}


/* DROPDOWN SEARCHABLE */
.dropDownSearchable {
  position: relative;
  width: 100%;
  height: 100%;

  .dropDown {
    @include formElement();
    padding: 0;
    user-select: none;
    cursor: pointer;
  
    /* INTERACTION */
    &:hover {
      .header .icon svg path {
        fill: $colorPrimary1 !important;
      }
    }
  
    /* MODIFIERS */
    &.sizeL {
      height: $inputHeightL;
  
      .header, .flyout .filterInput input, .flyout .option {
        @include type('CopyStrong');
      }
      .flyout {
        top: $inputHeightL;
        min-height: $inputHeightL;
  
        .filterInput {
          height: $inputHeightL;
        }
  
        .options {
          max-height: 5 * $inputHeightL;
        }
        .option {
          height: $inputHeightL;
        }
      }
    }
    &.sizeResponsive {
      @include mqLargerEqual($bpM) {
        height: $inputHeightL;
  
        .header, .flyout .filterInput input, .flyout .option {
          @include type('CopyStrong');
        }
        .flyout {
          top: $inputHeightL;
          min-height: $inputHeightL;
  
          .filterInput {
            height: $inputHeightL;
          }
  
          .options {
            max-height: 5 * $inputHeightL;
          }
          .option {
            height: $inputHeightL;
          }
        }
      }
    }
    &.showFlyout {
      background-color: $colorWhite; // always white background in focussed state
  
      &:after { // always show box shadow in focussed state;
        opacity: 1;
      }
  
      .header:hover { // give header a hover in focussed state
        color: $colorPrimary1;
      }
  
      .header .icon {
        transform: scale(-1, -1);
      }
      .header .icon svg path {
        fill: $colorPrimary1 !important;
      }
    }
  
    /* RESPONSIVE */
  
    /* CHILDREN */
    .header {
      position: relative;
      padding: $spaceXs;
      padding-right: 35px;
  
      @include type('Copy');
      @include ellipsis();
  
      @include transition (color);
  
      .icon {
        position: absolute;
        top: 20px;
        right: $spaceXs;
        width: 14px;
        height: 14px;
  
        svg {
          display: block;
        }
  
        @include transition(transform);
      }
    }
  
    .flyout {
      position: absolute;
      left: 0;
      top: $inputHeight;
  
      width: 100%;
      min-height: $inputHeight;
  
      background-color: $colorWhite;
      z-index: zIndex('Buffer2');
  
      &:after {
        @include boxShadowAnimatable();
        opacity: 1;
      }
  
      // FILTER INPUT
      .filterInput {
        position: relative;
        height: $inputHeight;
  
        .icon {
          position: absolute;
          top: $spaceXs;
          left: $spaceXs;
  
          width: 20px;
          height: 20px;
  
          svg {
            width: 100%;
            height: 100%;
          }
        }
  
        input {
          box-sizing: border-box;
          height: 100%;
          width: 100%;
          border: none;
          border-radius: $borderRadius;
  
          padding: $spaceXs;
          padding-left: 45px;
  
          @include type('Copy');
          color: $colorGrey1;
          caret-color: $colorPrimary1;
  
          &:focus {
            outline: none;
          }
  
          &::placeholder {
            color: $colorGrey1;
          }
        }
      }
  
      .divider {
        height: 1px;
        background-color: $colorGrey4;
      }
  
      // OPTIONS
      .options {
        max-height: 5 * $inputHeight;
        overflow-y: auto;
      }
  
      .option {
        position: relative;
        box-sizing: border-box;
        height: $inputHeight;
        padding: $spaceXs;
  
        @include type('Copy');
        @include ellipsis();
  
        @include transition(background-color);
  
        &:hover {
          background-color: $colorPrimary4;
        }
  
        &.active {
          background-color: $colorPrimary4;
          &:after {
            opacity: 1;
          }
        }
  
        &.highlight {
          background-color: $colorPrimary4;
        }
  
        &:after { // marker
          position: absolute;
          content: ' ';
          top: 0;
          left: 0;
          width: 2px;
          height: 100%;
          background-color: $colorPrimary1;
          opacity: 0;
  
          @include transition(opacity);
        }
      }
    }
  }

  .hint {
    @include type('Hint');
    margin-top: space('Xxs');
    margin-left: space('Xs');
    color: $colorGrey2;

    &.errorMessage{
      color: $colorDanger1;
    }
  }
}
