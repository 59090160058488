@import '/src/styles-imports/shared';

/* LISTITEM */
.listItem {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  margin-top: space('M');

  &.compactView {
    margin-top: space('Xxs');
  }

  .menuItem {
    cursor: pointer;

    box-sizing: border-box;
    padding: space('S');
    border-radius: 4px;
    border-left: 3px solid $colorGrey4;
    background-color: $colorGrey4;

    @include transition(background-color, border-color, box-shadow);

    @include mqSmaller($bpS) {
      padding: space('Xs');
    }
    .title {
      @include type('LabelL');
    }
    .description {
      @include type('Copy');
      margin-top: space('Xxs');
    }
    &.active, &:hover {
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
      border-color: $colorPrimary1;
      background-color: $colorWhite;
    }
    &.disabled {
      pointer-events: none;
      color: $colorGrey2;
    }
  }
  .hint {
    @include type('Hint');
    margin: space('Xs') 0 0 space('S');

    @include mqSmaller($bpS) {
      margin-left: space('Xs');
    }
  }
};
