@import '/src/styles-imports/shared';

/* CHIP */
.chip {
  user-select: none;

  position: relative;

  display: inline-block;

  padding: 10px;
  padding-left: 20px;
  padding-right: 42px;

  @include type('LabelButton');
  color: $colorPrimary1;

  border: 1px solid $colorPrimary1;
  border-radius: 18px;

  @include transition(border-color, color, opacity, transform); // added opacity, transform for BluCSSTransition in HotChips

  &.hasCircle {
    // display: flex;
    padding-left: 40px;
    box-sizing: border-box;
    // height: 36px;
    // padding-top:10px;
  }

  // CHILDREN
  .circleContainer {
    position: absolute;
    display: inline-block;
    left: 2px;
    top: 2px;
  }
  svg {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 14px;
    height: 14px;

    path {
      fill: $colorPrimary1;
      @include transition(fill);
    }
  }

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      border-color: $colorDanger1;
      color: $colorDanger1;

      svg path {
        fill: $colorDanger1;
      }

      [class*=Circle] {
        color: $colorDanger1;
        background-color: $colorDanger3;
      }
    }
  }

}



