@import '/src/styles-imports/shared';

/* Section2 */
.section2 {
  color: $colorGrey1;

  .callout {
    margin-top: 10px;
  }
  .sTitle {
    @include type('S');
  }
  .description {
    @include type('Copy');
    margin-top: space('Xs');
  }
}
