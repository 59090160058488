@import '/src/styles-imports/shared';

/* PERSONAL DATA */
.personalData {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .section {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: space('S');
    span:first-child {
      font-weight: 600;
    }
  }
}
