@import '/src/styles-imports/shared';

/* QuestionSegments */
.questionSegments {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .segmentedChooserParent {
    display: flex;
    align-items: center;
    margin-top: $spaceXs;

    .segmentedChooser {
      flex: 1;
    }

    & > span {
      flex: 1;
      flex-shrink: 0;
      padding-left: $spaceXs;
    }

    @include mqSmaller($bpS) {
      flex-direction: column;
      align-items: flex-start;

      & > span {
        order: 1;
        padding-left: 0;
      }

      .segmentedChooser {
        width: 100%;
        order: 2;
        margin-top: $spaceXxs;
      }
    }
  }

  .dynamicHint {
    height: 20px;

    opacity: 0;
    margin-top: 10px;
    color: $colorPrimary1;
    @include type('LabelM');
    @include transition(opacity);

    &.visible {
      opacity: 1;
    }
  }
}
