@import '/src/styles-imports/shared';

/* LOGIN MFA */
.loginMfa {
  width: 100%;
  margin: 0 auto;

  a {
    color: $colorPrimary1;
    @include transition(color);
    &:hover {
      color: $colorPrimary2;
    }
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    .title, .subTitle {
      text-align: center;
    }
  }

  /* CHILDREN */
  .title {
    @include type('M');
    color: $colorGrey1;
  }
  .subTitle {
    @include type('CopyStrong');
    color: $colorGrey1;
    padding: space('Xs') 0 space('S') 0;
  }
  .actions {
    margin: space('S') 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
