@import '/src/styles-imports/shared';

/* QuestionCsat */
.questionCsat {
  @include basicGrid;
  word-break: break-word;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .gridContent {
    grid-column: 4 / 10;

    margin-bottom: $spaceM;

    @include mqSmaller($bpM) {
      grid-column: 2 / 8;
    }

    @include mqSmaller($bpS) {
      grid-column: start / end;
    }

    & > button {
      display: block;
      margin: $spaceS auto 0;
    }

    .stars {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: $spaceM;

      .star {
        width: 48px;
        height: 48px;
        background-image: url('icons/star.svg');
        @include transition(background-image);

        &.selected {
          background-image: url('icons/star_selected.svg');
        }
        &.hovered:not(.selected) {
          background-image: url('icons/star_hovered.svg');
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .greyValue {
      @include type('Copy');
      color: $colorGrey2;
      text-align: center;
      margin-top: $spaceM;

      @include transition(color);
      &:hover {
        cursor: pointer;
        color: $colorGrey3
      }
    }

    .hint {
      @include type('Hint');
      margin-top: space('M');
      .line {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          margin: 0 $spaceXxxs;
        }
        svg:last-child {
          margin: 0 $spaceXxxs 0 0;
        }
      }
    }

  }
}
