@import '/src/styles-imports/shared';

.signUpForm {
  width: 100%;
  margin: 0 auto;
  .title {
    @include type('M');
    color: $colorGrey1;
  }
  .subTitle {
    @include type('CopyStrong');
    color: $colorGrey1;
    padding: 16px 0 24px 0;
  }
  .domains {
    @include type('Hint');
    padding: space('Xs') 0 12px space('Xs');
    span {
      color: $colorPrimary1;
      cursor: pointer;
      @include transition(color);
      &:hover {
        color: $colorPrimary2;
      }
    }
    &.noTopPadding {
      padding-top: 0;
    }
  }
  .passwordInput {
    margin-top: 16px;
  }
  .button {
    position: relative;
    margin-left: auto;
    margin-right: 0px;
  }
  .criteria {
    padding-bottom: 16px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    button {
      margin-left: auto;
    }
  }
}

// MODAL
.infoDescription {
  margin-top: space('Xxs');
  div:last-child {
    margin-top: space('Xs');
    span {
      display: block;
    }
  }
}

@include mqSmaller($bpM) {
  .signUpForm {
    .bottom {
      flex-direction: column-reverse;
      button {
        align-self: stretch;
        margin-left: unset;
      }
      a {
        padding-top: 24px;
      }
    }
  }
}

@include mqSmaller($bpS) {
  .signUpForm {
    .title, .subTitle {
      text-align: center;
    }
  }
}
