@import '/src/styles-imports/shared';

/* QUESTIONSTARTDATE */
.questionStartDate {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include dialogQuestion;

  .assessmentRadioButtonGroup {
    width: 100% !important;
  }

  .inputContainer {
    margin-left: 61px;
    margin-bottom: $spaceS;
  }

  &.sizeS {
    margin-top: 0 !important;

    .formRow {
      & > label:not(:first-child) {
        margin-top: space('Xs');
      }
    }

    .inputContainer {
      margin-left: 0;
      margin-bottom: 0;
      margin-top: space('Xs');
    }
  }
}
