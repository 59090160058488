@import '/src/styles-imports/shared';

$largeWidth: 328px;
$mediumWidth: 280px;
$smallWidth: 256px;

.dialogModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: zIndex('Modal');
  background-color: rgba(0, 0, 0, 0.1);
  animation: fadeIn .3s ease-out;
  &.isClosing {
    animation: fadeOut .3s ease-out;
  }
  .modal {
    max-width: 560px;
    box-sizing: border-box;
    background-color: white;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 16px 32px;
    border-radius: 10px;
    text-align: left;
    animation: loadModal .3s ease-out;

    &:focus {
      border: none;
      outline: none;
    }

    &.isClosing {
      animation: closeModal .3s ease-out;
    }
    .header {
      @include type('LabelL');
      padding-bottom: 8px;
      user-select: none;
      width: $largeWidth;
    }
    .body {
      @include type('Copy');
      color: $colorGrey1;
      width: $largeWidth;
    }
    .footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .confirm {
        margin-right: 0px;
        margin-left: 16px;
        margin-top: 16px;
        height: 36px;
      }
      .cancel {
        margin-right: 0px;
        margin-top: 16px;
        margin-left: 16px;
        height: 36px;
      }
    }

  }

  @include mqSmaller($bpM) {
    .modal {
      .header {
        width: $mediumWidth;
      }
      .body {
        width: $mediumWidth;
      }
    }
  }

  @include mqSmaller($bpS) {
    .modal {
      .header {
        width: $smallWidth;
      }
      .body {
        width: $smallWidth;
      }
    }
  }
}
