@import '/src/styles-imports/shared';

/* IMGPIN */
.imgPin {
  position: relative;
  cursor: pointer;
  width: 30px;

  .pin {
    & > svg, & > svg > path {
      @include transition (fill);
    }
  }

  .circle {
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
  }

  // &:hover {
  //   .pin > svg, .pin > svg > path {
  //     // hover goes to same color at the moment and thus has no effect
  //     // but leaving in case UX comes up with a hover state
  //     fill: $colorPrimary3 !important;
  //   }
  // }
}
