@import '/src/styles-imports/shared';

/* DATA */
.data {

  @include dialogIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
