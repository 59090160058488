@import '/src/styles-imports/shared';

/* IST INDICATOR */
.istIndicator {
  /* RESPONSIVE */

  /* CHILDREN */
  .indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: $colorPrimary1;

    /* INTERACTION */

    /* MODIFIERS */
    &.small {
      width: space('S');
      height: space('S');
      svg {
        transform: scale(calc(2 / 3));
      }
    }
    &.light {
      opacity: .4;
    }
    &.danger {
      background-color: $colorDanger1;
    }
  }

  .scoreWrapper {
    margin-top: 8px;
    white-space: nowrap;
    > span:first-child {
      font-weight: 600;
    }
  }
  .smallRatio {
    font-size: 10px;
  }

  .trend {
    display: flex;
    align-items: center;
    @include type('Hint');

    & > span {
      flex-shrink: 0;
    }

    & > svg {
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      margin-left: $spaceXxxs;
    }

    &.blue {
      color: $colorPrimary1;

      & > svg {
        transform: rotate(45deg);
        path {
          fill: $colorPrimary1;
        }
      }
    }
    &.red {
      color: $colorDanger1;

      & > svg {
        transform: rotate(135deg);
        path {
          fill: $colorDanger1;
        }
      }
    }
    &.grey {
      color: $colorGrey2;
      & > svg {
        transform: rotate(90deg);
        path {
          fill: $colorGrey2;
        }
      }
    }
  }
}
