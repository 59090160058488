@import '/src/styles-imports/shared';

/* ExportAssessmentResult */
.exportAssessmentResult {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .exportView {
    width: 600px;
    max-width: 600px;
    margin: space('M') auto 0;

    // loading indicator
    .loading {
      margin-bottom: space('M');
      margin-top: 0;
      text-align: center;
      & > div {
        justify-content: center;
      }
    }

    & > [id^=export], & > .assessment {
      div, text, circle {
        pointer-events: none;
      }
    }

    & > .assessment {
      margin-top: space('Xs');
    }
  }

  .exportViewFooter {
    width: 600px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;

    & > button {
      display: block;
      margin-left: auto;
    }
  }
}
