@import '/src/styles-imports/shared';

/* MAINHEADER */
.mainHeader {
  user-select: none;
  position: relative;
  width: 100%;
  height: 100%;

  /* INTERACTION */

  /* MODIFIERS */
  &.subNavIsOpen {
    .firstLevel {
      border-bottom-color: white;
    }
    @include mqSmaller($bpS) {
      .firstLevel {
        border-bottom-color: $colorGrey4;
      }
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
  .left {
    $headerControlsWidth: 210px;

    display: flex;
    align-items: center;

    width: calc(100% - #{$gridMarginLarge} - #{$headerControlsWidth});
    @include mqSmaller($bpS) {
      width: 100%;
    }
  }

  .right {
    position: relative; // for menuClose
    display:flex;
    align-items: center;
  }

  .firstLevel, .secondLevel {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    justify-content: space-between;
    width: 100%;
    height: $headerHeight;
    padding-right: 60px;

    background-color: white;
    border-bottom: 1px solid $colorGrey4;

    @include mqSmaller($bpS) {
      padding-right: $spaceXxs;
    }
  }
  .firstLevel {
    @include mqSmaller($bpS) {
      height: $headerHeightXs;

      .left {
        padding-left: space('Xxs');
      }
    }
  }
  .secondLevel {
    position: relative;
    flex-wrap: wrap;
    height: $subNavHeight;
    padding-left: $spaceXxs;
    padding-right: $spaceXs;

    z-index: 2;

    &.subNavIsOpen {
      .subNavItem:first-child {
        @include animationDurationOverride(300ms, slideUpSmallest);
      }
      .subNavItem:nth-child(2) {
        @include animationDurationOverride(550ms, slideUpSmallest);
      }
      .subNavItem:nth-child(3) {
        @include animationDurationOverride(800ms, slideUpSmallest);
      }
      .subNavItem:nth-child(n + 4) {
        @include animationDurationOverride(1050ms, slideUpSmallest);
      }
      .subNavItem:nth-child(n + 5) {
        @include animationDurationOverride(1200ms, slideUpSmallest);
      }
    }

    @include mqSmaller($bpS) {
      height: $headerHeight;
      flex-wrap: nowrap;
      &.subNavIsOpen {
        flex-wrap: wrap;
        height: $subNavHeightXs;
        .title {
          padding-top: $spaceXxs + 4px;
        }

      }
    }

  }

  // SUBNAV
  .subNav {
    display: flex;
    box-sizing: border-box;
    max-width: 100%;
    padding-left: calc(60px - 20px);
    transform: translateY(4px);

    @include mqSmaller($bpS) {
      padding-left: 0px;
      margin-left: - $spaceXxs;
      margin-right: - $spaceXs;
      width: calc(100% + #{$spaceXs + $spaceXxs});
      max-width: calc(100% + #{$spaceXs + $spaceXxs});
      transform: translateY(9px);

      .subNavItem:first-child {
        padding-left: $spaceXs;
        .border {
          margin-left: $spaceXs - 1px;
        }
      }
    }
  }
  .subNavItem {
    cursor: pointer;

    position: relative;
    text-align: center;
    padding: 12px;
    padding-bottom: $spaceXs + 4px;
    padding-top: 0;

    text-decoration: none;
    color: black;

    @include transition(color);

    &:hover {
      color: $colorPrimary1;
    }

    &.active {
      color: $colorPrimary1;

      .border {
        background-color: $colorPrimary1;
      }
    }

    &:hover.active {
      .border {
        width: calc(100% - #{2 * $spaceS});
        margin-left: $spaceS;
      }
    }

    .border {
      position: absolute;
      top: 33px;
      left: 0px;
      margin-left: 12px;
      width: calc(100% - #{2 * 12px});
      height: 2px;

      background-color: rgba(0,0,0,0);
      @include transition(width, margin-left, background-color);

      @include mqSmaller($bpS) {
        top: 31px;
      }
    }
  }

  // ELEMENTS
  .logo {
    padding-left: $spaceXxs;
  }
  .quickAccess, .profile, .helpMenu {
    margin-left: $spaceS;

    @include transition(opacity);
    &.hidden {
      pointer-events: none;
      opacity: 0;
    }

    @include mqSmaller($bpS) {
      margin-left: $spaceXs;
    }
  }

  .helpMenu {
    @include mqSmaller($bpS) {
      margin-left: initial;
    }
  }

  .quickAccess {
    // override QuickAccessMenu
    margin-top: -12px;
    margin-left: -35px;
    @include mqSmaller($bpS) {
      margin-top: 0px;
      margin-right: 0px;
    }
  }
  .burger, .menuClose {
    @include iconWithBackground($colorGrey4);
    margin-left: $spaceXxs;

    @include transition(opacity, background-color);

    &.hidden {
      pointer-events: none;
      opacity: 0;
    }
  }
  .menuClose {
    // transition-delay: 150ms;
  }
  .menuClose.hidden {
    transition-delay: 0ms;
  }
  .profile, .burger {
    @include animation(fadeIn);
  }
  .menuClose {
    position: absolute;
    right: 0;
    top: 0;
  }
  .title {
    display: flex;
    align-items: center;

    @include type('LabelL');
    margin-left: $spaceXxs;

    @include mqLargerEqual($bpS) {
      width: 100%;
    }

    @include mqSmaller($bpS) {
      @include ellipsis();
      flex: 1;
    }
  }
  .title > a {
    color: $colorPrimary1;
    text-decoration: none;
  }

  .breadcrumb {
    $parentWidth: 72px;
    $breadCrumbArrowWidth: 24px;

    display: flex;
    align-items: center;
    max-width: calc(100% - #{$parentWidth} - #{$breadCrumbArrowWidth});

    color: $colorPrimary1;
    text-decoration: none;
    white-space: nowrap;
  }
  .breadcrumb a {
    display: inline-block;
    color: $colorPrimary1;
    text-decoration: none;

    opacity: 0;
    @include animationLong(fadeIn);
    animation-delay: 150ms;
    animation-fill-mode: forwards;

    @include ellipsis();
  }
  .breadcrumbArrow {
    display: inline-block;
    margin-left: $spaceXxs;
    margin-right: $spaceXxs;

    > svg {
      transform: translateY(2px);
    }
    > svg path {
      fill: $colorPrimary1;
    }
  }
}
