@import '/src/styles-imports/shared';

/* QuestionFreeText */
.questionFreeText {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .bottomText {
    display: flex;
    justify-content: space-between;
    margin-top: $spaceXxs;

    .error {
      @include type('Hint');
      color: $colorDanger1;
    }
    .hint {
      flex: 1 0;

      @include type('Hint');
      padding-left: $spaceXxs;

      text-align: right;
    }
  }
}
