@import '/src/styles-imports/shared';

/* CALL HELP */
.callHelp {
  display: flex;
  justify-content: center;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    padding: space('Xs') space('M');
    display: flex;
    align-items: center;
    gap: space('S');
    border-radius: space('Xxs');
    background-color: $colorDanger3;

    svg {
      min-width: 30px;
      min-height: 30px;
    }

    @include mqSmaller($bpS) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
