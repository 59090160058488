@import '/src/styles-imports/shared';

.poweredBy {
  display: flex;
  align-items: center;
  span {
    @include type('Hint');
  }
  img {
    width: 80px;
    height: auto;
    margin-left: space('Xxs');
    margin-top: 1px;
  }

  @include mqSmaller($bpS) {
    span {
      font-size: 9px;
    }
    img {
      width: 66px;
    }
  }
}
