@import '/src/styles-imports/shared';

$itemHeight: 30px;
$itemPaddingX: 4px;
$markerXY: 30px;

/* TeamDiagram9LevelsNext */
.teamDiagram9LevelsNext {

  /* INTERACTION */

  /* MODIFIERS */
  &.turquoise {
    // default
    .itemContainer:hover {
      .marker {
        border-color: $colorTurquoise1 !important;
      }
      .marker .content {
        background-color: rgba($colorTurquoise2, 0.3) !important;
      }
    }
  }
  &.yellow {
    .itemContainer:hover {
      .marker {
        border-color: $colorYellow1 !important;
      }
      .marker .content {
        background-color: rgba($colorYellow2, 0.3) !important;
      }
    }
    .marker, .center, .average {
      border-color: rgba($colorYellow1, 0.5) !important;
    }
    .marker .content {
      background-color: rgba($colorYellow2, 0.1) !important;
    }
    .barContainerLeft .bar {
      background-color: $colorYellow2 !important;
    }
    .barContainerRight .bar {
      background-color: $colorYellow1 !important;
    }
    .range, .rangeLegend {
      border-color: rgba($colorYellow1, 0.5) !important;
      background-color: rgba($colorYellow2, 0.4) !important;
    }
  }
  &.green {
    .itemContainer:hover {
      .marker {
        border-color: $colorGreen1 !important;
      }
      .marker .content {
        background-color: rgba($colorGreen2, 0.3) !important;
      }
    }
    .marker, .center, .average {
      border-color: rgba($colorGreen1, 0.5) !important;
    }
    .marker .content {
      background-color: rgba($colorGreen2, 0.1) !important;
    }
    .barContainerLeft .bar {
      background-color: $colorGreen2 !important;
    }
    .barContainerRight .bar {
      background-color: $colorGreen1 !important;
    }
    .range, .rangeLegend {
      border-color: rgba($colorGreen1, 0.5) !important;
      background-color: rgba($colorGreen2, 0.4) !important;
    }
  }
  &.orange {
    .itemContainer:hover {
      .marker {
        border-color: $colorOrange1 !important;
      }
      .marker .content {
        background-color: rgba($colorOrange2, 0.3) !important;
      }
    }
    .marker, .center, .average {
      border-color: rgba($colorOrange1, 0.5) !important;
    }
    .marker .content {
      background-color: rgba($colorOrange2, 0.1) !important;
    }
    .barContainerLeft .bar {
      background-color: $colorOrange2 !important;
    }
    .barContainerRight .bar {
      background-color: $colorOrange1 !important;
    }
    .range, .rangeLegend {
      border-color: rgba($colorOrange1, 0.5) !important;
      background-color: rgba($colorOrange2, 0.4) !important;
    }
  }
  &.blue {
    .itemContainer:hover {
      .marker {
        border-color: $colorBlue1 !important;
      }
      .marker .content {
        background-color: rgba($colorBlue2, 0.3) !important;
      }
    }
    .marker, .center, .average {
      border-color: rgba($colorBlue1, 0.5) !important;
    }
    .marker .content {
      background-color: rgba($colorBlue2, 0.1) !important;
    }
    .barContainerLeft .bar {
      background-color: $colorBlue2 !important;
    }
    .barContainerRight .bar {
      background-color: $colorBlue1 !important;
    }
    .range, .rangeLegend {
      border-color: rgba($colorBlue1, 0.5) !important;
      background-color: rgba($colorBlue2, 0.4) !important;
    }
  }
  &.red {
    .itemContainer:hover {
      .marker {
        border-color: $colorRed1 !important;
      }
      .marker .content {
        background-color: rgba($colorRed2, 0.3) !important;
      }
    }
    .marker, .center, .average {
      border-color: rgba($colorRed1, 0.5) !important;
    }
    .marker .content {
      background-color: rgba($colorRed2, 0.1) !important;
    }
    .barContainerLeft .bar {
      background-color: $colorRed2 !important;
    }
    .barContainerRight .bar {
      background-color: $colorRed1 !important;
    }
    .range, .rangeLegend {
      border-color: rgba($colorRed1, 0.5) !important;
      background-color: rgba($colorRed2, 0.4) !important;
    }
  }
  &.purple {
    .itemContainer:hover {
      .marker {
        border-color: $colorPurple1 !important;
      }
      .marker .content {
        background-color: rgba($colorPurple2, 0.3) !important;
      }
    }
    .marker, .center, .average {
      border-color: rgba($colorPurple1, 0.5) !important;
    }
    .marker .content {
      background-color: rgba($colorPurple2, 0.1) !important;
    }
    .barContainerLeft .bar {
      background-color: $colorPurple2 !important;
    }
    .barContainerRight .bar {
      background-color: $colorPurple1 !important;
    }
    .range, .rangeLegend {
      border-color: rgba($colorPurple1, 0.5) !important;
      background-color: rgba($colorPurple2, 0.4) !important;
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */


  // LABELS
  .labels {
    display: flex;

    padding: $spaceXxs 0;

    @include type('Hint');
    color: $colorGrey2;

    &.grey1 {
      color: $colorGrey1;
    }

    > div {
      box-sizing: border-box;
      width: 50%;

      overflow: hidden;
      white-space: normal;
    }
    .labelLeft {
      padding-right: $spaceXxs;
    }
    .labelRight {
      padding-left: $spaceXxs;
      text-align: right;
    }
    .highlight {
      padding-left: $spaceXxxs;
      color: $colorPrimary1;
    }

    .rangeLabel {
      text-align: right;
      @include type('Copy');

      .rangeLegend {
        display: inline-block;
        width: 30px;
        height: 20px;
        margin-right: $spaceXxs;
        margin-bottom: -5px;

        border: 1px dashed rgba($colorTurquoise1, 0.5);
        border-top: 0;
        border-bottom: 0;

        background-color: rgba($colorTurquoise2, 0.4);

        @include transition(background-color, border-color);
      }
    }
  }

  // GRAPH
  .graph {
    position: relative;
    margin-bottom: $spaceXxs;

    &.animating {
      .marker {
        @include transitionImportant(border-color);
      }
      .marker .content {
        @include transitionImportant(background-color);
      }
      .bar {
        @include transitionImportant(background-color, opacity, width);
      }
    }


    // ITEMCONTAINER
    .itemContainer {
      cursor: pointer;

      position: relative;
      padding: $itemPaddingX 0;

      z-index: 1;

      // MODIFIERS
      .itemContainer:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }

      // INTERACTION
      &:hover {
        .marker {
          border-color: $colorTurquoise1;
          @include transitionImportant(border-color);
        }
        .marker .content {
          background-color: rgba($colorTurquoise2, 0.3);
          @include transitionImportant(background-color);
        }
        .barContainerLeft .bar {
          opacity: 1 !important;
          @include transitionImportant(opacity);
        }
        .barContainerRight .bar {
          opacity: 1 !important;
          @include transitionImportant(opacity)
        }
      }


      // CHILDREN
      // ITEM
      .item {
        position: relative;
        height: $itemHeight;

        display: flex;
        justify-content: center;
        align-items: center;


        // MARKER
        .marker {
          box-sizing: border-box;
          width: $markerXY;
          height: $markerXY;

          border-radius: 50%;

          display: flex;
          justify-content: center;

          border: 3px solid rgba($colorTurquoise1, 0.5);
          background-color: white;
          z-index: 2;

          @include transitionLongest(border-color);

          .content {
            width: 100%;
            height: 100%;
            border-radius: 50%;

            background-color: rgba($colorTurquoise2, 0.1);

            @include transitionLongest(background-color);
          }
        }

        // NAME
        .name {
          position: absolute;
          box-sizing: border-box;
          width: 50%;
          height: 100%;
          top: 0;
          left: 0;
          padding-right: calc($itemHeight / 2) + $spaceXxs;
          padding-left: $spaceXxs;

          text-align: right;
          @include type('LabelS');
          line-height: $itemHeight !important;
          @include ellipsis();

          z-index: 2;
        }


        // BARS
        .bars {
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          display: flex;
          justify-content: center;
          z-index: 1;

          [class*=barContainer] {
            width: 50%;
            height: 100%;
            .bar {
              height: 100%;
              @include transitionLongest(opacity, width, background-color);
            }
          }
          .barContainerLeft {

            display: flex;
            justify-content: flex-end;

            .bar {

              border-top-left-radius: calc($itemHeight / 2);
              border-bottom-left-radius: calc($itemHeight / 2);
              background-color: $colorTurquoise2;
              opacity: 0.3;
            }
          }
          .barContainerRight {

            .bar {
              border-top-right-radius: calc($itemHeight / 2);
              border-bottom-right-radius: calc($itemHeight / 2);
              background-color: $colorTurquoise1;
              opacity: 0.5;
            }
          }
        }


      }
    }

    // GRID
    .grid {
      position: absolute;
      top: $spaceXxxs;
      left: 0;

      display: flex;
      justify-content: center;

      height: calc(100% - #{2 * $spaceXxxs});
      width: 100%;

      // default
      &.turquoise {

      }

      .center {

        box-sizing: border-box;
        width: 2px;
        height: 100%;

        border: 1px solid rgba($colorTurquoise1, 0.5);

        @include transition(border-color);
      }

      .average {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        width: 2px;
        height: calc(100% + #{$spaceXxs});

        border: 1px dashed rgba($colorTurquoise2, 0.5);
        opacity: 0.5;

        @include transition(border-color, margin);
      }

      .rangeContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .range {
          width: 0%;
          height: 100%;

          border: 1px dashed rgba($colorTurquoise1, 0.5);
          border-top: 0;
          border-bottom: 0;

          background-color: rgba($colorTurquoise2, 0.4);

          @include transition(width, margin-left, background-color, border-color);
        }

      }
    }
  }
}
