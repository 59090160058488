@import '/src/styles-imports/shared';

/* QuestionPersonalInfo */
.questionPersonalInfo {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include dialogQuestion;

  .checkBox {
    margin-top: space('M');

    &:last-child {
      margin-top: space('S');
    }

    .policy {
      color: $colorPrimary1 !important;
      cursor: pointer;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: space('M');
  }
}
