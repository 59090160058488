@import '/src/styles-imports/shared';

.teamSkillsResult {
  padding-bottom: space('L');

  @include mqSmaller($bpS) {
    padding-bottom: space('M');
  }

  .mainHeader {
    padding: space('M') space('M') 0;

    @include mqSmaller($bpM) {
      padding: space('S') space('Xs') 0;
    }
  }

  .dimensions {
    margin-top: space('Xs');
  }


  .rowsParent {
    position: relative;
  }

  .rows {
    margin-top: space('Xs');
    .row {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      .competenciesCollapsibleHeader {
        padding: 12px 0;
      }
      .header {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        align-items: center;
        overflow: hidden;
        .left {
          position: relative;
          flex: 1;
        }
        .right {
          flex: 1;
          display: flex;
          align-items: center;
          overflow: hidden;
          .points {
            flex: 0 0 28px;
            margin-left: 16px;
            .blue {
              color: $colorPrimary1;
            }
          }
          .user {
            display: flex;
            align-items: center;
            margin-left: space('Xs');
            @include ellipsis();
            .avatar {
              flex: 0 0 24px;
            }
            .name {
              margin-left: space('Xxs');
              @include type('LabelL');
              @include ellipsis();
            }
          }
        }
      }
      .body {
        padding: 0 space('Xs');
        .subItemRow {
          display: flex;
          align-items: center;
          padding-bottom: 24px;
          .right {
            margin-left: 16px;
          }
          &:first-child {
            margin-top: 24px;
          }
        }
      }
    }
  }

  .bottomText {
    width: 100%;
    margin-top: space('Xs');
    text-align: center;
    @include type('Hint');
    span {
      color: $colorPrimary1;
    }
  }

  // RANGE AREA
  .rangeContainer {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    .range {
      width: 0%;
      height: 100%;

      border-right: 1px dashed rgba($colorPrimary1, 0.5);

      @include transition(width, margin-left);
    }
  }

  @include mqSmaller($bpM) {
    overflow-x: hidden;
    .rows {
      margin-top: 0;
      .row {
        position: relative;
        padding: 0 0 8px;
        .competenciesCollapsibleHeader {
          padding: 12px 0;
          div:last-child {
            svg {
              position: absolute;
              right: 0;
              top: 17px;
            }
          }
        }
        .header {
          flex-direction: column-reverse;
          align-items: flex-start !important;
          position: relative;
          padding-right: 8px;
          .left {
            margin-top: 10px;
          }
          .right {
            width: 100%;
            flex-direction: row-reverse;;
            justify-content: space-between;
            margin-left: 0;
            .name {
              padding-left: 0;
            }
            .points {
              margin-right: space('Xs');
            }
            .user {
              margin-left: 0;
            }
          }
        }
        .body {
          width: 100%;
          box-sizing: border-box;
          padding: 0;
          .subItemRow {
            flex-direction: column-reverse;
            align-items: flex-start;
            .right {
              width: 100%;
              margin-left: 0;
              .competenciesInfoTitle {
                margin-left: 0;
                margin-bottom: 16px;
              }
            }
          }
        }
      }
      .row:first-child {
        .competenciesCollapsibleHeader {
          padding-top: space('S');
          svg {
            top: 29px !important;
          }
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    .container {
      padding: 0 0 32px;
    }
    .rows {
      margin-top: 0;
      .row {
        position: relative;
        .competenciesCollapsibleHeader {
          div:last-child {
            svg {
              position: absolute;
              right: 0;
              top: 16px;
            }
          }
        }
        .header {
          flex-direction: column-reverse;
          align-items: flex-start !important;
          position: relative;
          padding-right: 8px;
          .right {
            width: 100%;
            flex-direction: row-reverse;;
            justify-content: space-between;
            margin-left: 0;
            .name {
              padding-left: 0;
            }
            .user {
              margin-left: 0;
            }
          }
        }
        .body {
          width: 100%;
          box-sizing: border-box;
          padding: 0;
          .subItemRow {
            flex-direction: column-reverse;
            align-items: flex-start;
            .right {
              width: 100%;
              margin-left: 0;
              .competenciesInfoTitle {
                margin-left: 0;
                margin-bottom: 16px;
              }
            }
          }
        }
      }
      .row:first-child {
        margin-top: 6px;
      }
    }
  }
}
