@import '/src/styles-imports/shared';

.react-select__root {
  width: 100%;
  box-sizing: border-box;
  &.sizeS {
    .react-select__control {
      height: 50px;
    }
  }
  &.sizeL {
    .react-select__control {
      height: 123px;
    }
  }
  .react-select-outer-component {
    height: 100%;
  }
  .react-select__control {
    width: 100%;
    height: 100%;
    background-color: $colorGrey4;
    color: $colorGrey1;
    border: none;
    border-radius: 2px;
    border: none;
    padding-left: 16px;
    @include transition(background-color, box-shadow);
    .react-select__placeholder,
    .react-select__single-value {
      @include type('Copy');
      width: 100%;
      color: $colorGrey1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .react-select__value-container {
      border: none;
      padding: 0;
      height: 100%;
    }
    .react-select__indicator-separator {
      background: none;
    }
    .react-select__dropdown-indicator {
      color: $colorGrey1;
    }
  }
  .react-select__menu {
    padding: 0;
    border: none;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    z-index: zIndex('Buffer3');
    .react-select__menu-list {
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 0 0 2px 2px;
      .react-select__option {
        height: 100%;
        border: none;
        color: $colorGrey1;
        background-color: $colorWhite;
        border-left: 3px solid $colorWhite;
        padding: 15px 0 15px 16px;
        margin: auto;
        @include type('Copy');
        &:hover {
          background-color: $colorPrimary4;
          border-color: $colorPrimary1;
        }
      }
    }
  }
  .react-select__control--menu-is-open {
    border-radius: 2px 2px 0 0;
    border: none;
    .react-select__placeholder,
    .react-select__single-value {
      color: $colorPrimary1;
    }
    .react-select__dropdown-indicator {
      transform: rotate(180deg);
      color: $colorPrimary1;
    }
  }
  .react-select__control--is-focused {
    box-sizing: border-box;
    border-color: $colorPrimary1;
    background-color: $colorWhite;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  }
  .react-select__control:hover {
    background-color: $colorWhite;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  }
  &.disabled {
    .react-select__control {
      pointer-events: none;
      background-color: $colorGrey5;
      .react-select__placeholder,
      .react-select__single-value {
        color: $colorGrey2;
      }
      .react-select__indicators .react-select__dropdown-indicator svg path {
        fill: $colorGrey2;
      }
    }
  }
  &.error {
    .react-select__placeholder,
    .react-select__single-value,
    .react-select__dropdown-indicator {
      color: $colorDanger1;
    }
    .error-message {
      @include type('Hint');
      display: block;
      padding: 8px 0 0 16px;
    }
  }
  .hint {
    @include type('Hint');
    color: $colorGrey2;
    margin: space('Xxs') space('Xs') 0 space('Xs');
  }
}
