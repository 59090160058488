@import '/src/styles-imports/shared';

/* DETAILED RESULTS */
.detailedResults {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .section {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: space('S');
    span:first-child {
      font-weight: 600;
    }

    &.scores {
      flex-direction: row;
      align-items: center;
      gap: 12px;
      :first-child {
        flex: 1;
      }
    }
  }

  .hint {
    @include type('Hint');
    margin: $spaceS 0 $spaceM;
  }

  .bars {
    margin: 0 32px;
    > * {
      margin-bottom: 32px;
    }

    @include mqSmaller($bpS) {
      margin: 0;
    }
  }

  .risk {
    display: flex;
    align-items: center;

    background-color: $colorDanger3;
    border-radius: space('Xs');
    padding: space('Xs') space('M');
    gap: space('Xs');
    > * {
      margin-bottom: 0;
    }

    .alertWrapper {
      background-color: $colorDanger1;
      color: $colorDanger3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
      min-height: 30px;
      .alert {
        font-weight: 600;
        font-size: 26px;
      }
    }
  }
}
