@import '/src/styles-imports/shared';

/* MODALWRAPPER */
.modalWrapper {

  position: fixed; // to break out of mainLayout
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  overflow-y: auto;
  overflow-x: hidden;

  z-index: zIndex('Modal');

  /* INTERACTION */

  /* MODIFIERS */
  &.instantBackground {
    background-color: white;
  }
  &.animate {
    .content {
      opacity: 0;
      @include animation(slideUp);
      animation-fill-mode: forwards;
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    background-color: white;
    width: 100%;
    height: 100%;
    opacity: 0;
    @include animation(fadeIn);
    animation-fill-mode: forwards;
  }

}
