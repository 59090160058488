@import '/src/styles-imports/shared';

/* VIDEOINSTRUCTIONAL */
.videoInstructional {
  position: relative;

  padding: $spaceM;
  border-radius: $spaceXs;

  background-color: $colorGrey5;
  overflow: hidden;


  .loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .video {
    position: relative;
    border-radius: $spaceXs;

    overflow:hidden;
    z-index: 1;
    opacity: 0;

    @include transition(opacity);

    &.visible {
      opacity: 1;
    }

    video {
      display: block
    }
  }


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  @include mqSmaller($bpM) {
    padding: $spaceS;
  }
  @include mqSmaller($bpS) {
    padding: $spaceXs;
  }

  /* CHILDREN */
}
