@import '/src/styles-imports/shared';

/* IterationControl */
.iterationControl {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .flexContainer {
    display: flex;

    .textContent {
      flex: 1;

      .hv {
        @include type('Xxs');
      }
    }

    .controls {
      margin-left: $spaceXs;
      display: flex;

      & > div:last-child {
        margin-left: $spaceXs;
      }
    }
  }

  .centralHv {
    margin-top: $spaceXs;
    display: flex;
    justify-content: space-between;
    gap: $spaceXxs;
    .descriptionHv {
      margin-left: 20px;
    }
    > div:last-child {
      min-width: 150px;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    gap: $spaceXs;
    > :last-child {
      width: 50%;
    }
  }

  & > div > button {
    display: block;
    margin: $spaceXs 0 0 auto;
  }

  .bold {
    font-weight: 600;
  }
  
  .shareLink {
    display: flex;
    align-items: center;
    gap: $spaceXxs;
    word-break: break-all;
    svg {
      min-width: 20px;
    }
  }
  .blue {
    cursor: pointer;
    color: $colorPrimary1;
    fill: $colorPrimary1;
    &:hover, &:focus {
      @include transition(color);
      color: $colorPrimary2;
      & > svg path {
        @include transition(fill);
        fill: $colorPrimary2;
      }
    }
  }
  .note {
    color: $colorGrey2;
    font-size: 12px;
  }

  .showCurrentResult {
    display: flex;
    align-items: center;

    margin-top: $spaceXxxs;
    color: $colorPrimary1;

    & > svg {
      flex-shrink: 0;
      width: 14px;
      height: 14px;
      margin-right: $spaceXxs;

      path {
        fill: $colorPrimary1;
      }
    }

    @include transition(color, fill);
    &:hover {
      cursor: pointer;
      color: $colorPrimary2;

      & > svg path {
        fill: $colorPrimary2;
      }
    }
  }

  .edit {
    margin-top: $spaceXxxs;
    display: flex;
    align-items: center;
    gap: $spaceXxxs;
    font-weight: 600;
  }
}

.latestResultText {
  @include type('Copy');
  color: $colorGrey2;
  margin-top: $spaceXxxs;

  &.hv {
    color: $colorBlack;
    font-weight: 600;
  }

  .active {
    display: flex;
    gap: 6px;
    .activeDot {
      margin-top: 3px;
      min-width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $colorPrimary3;
    }
  }
}
