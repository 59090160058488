@import '/src/styles-imports/shared';

/* PEER BREAKDOWN */
.peerBreakdown {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .dimension, .diagram {
    margin-top: $spaceL;
  }

  .dimension.forCustomReport:first-child {
    margin-top: 0;

    .diagram:first-child {
      margin-top: 0;
    }
  }
}
