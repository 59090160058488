@import '/src/styles-imports/shared';

@mixin clickableIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px; // size of largest icon
  min-height: 20px;
  cursor: pointer;
  
  path {
    fill: $colorPrimary1;
  }

  // Expand clickable area
  &:before {
    content: '';
    position: absolute;
    width: space('M');
    height: space('M');
    z-index: zIndex('Modal');
  }
}


/* RECOMMENDATION CARD */
.recommendationCard {
  @include animationLong(fadeIn);

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .container {
    position: relative;
    height: inherit;

    > div:first-child {
      align-self: stretch;
      flex-grow: 1;
    }

    .arrowRight {
      @include clickableIcon;
      position: absolute;
      right: -#{space('S')};
      top: 50%;

      @include mqSmaller($bpM) {
      right: -12px;
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    gap: space('Xxs');

    > div {
      white-space: normal;
    }

    .content {
      display: flex;
      align-items: center;
      gap: space('Xxxs');
      word-break: break-word;
      font-size: 12px;
    }
    span {
      font-weight: 600;
      line-height: 20px;
      cursor: pointer;
      color: $colorPrimary1;
  
      &.archived {
        color: $colorGrey2;
      }
    }
  }

  .groups {
    display: flex;
    flex-shrink: 1;
    justify-content: space-between;
    gap: space('Xs');
    margin-top: 12px;

    @include mqSmaller($bpS) {
      flex-direction: column;
    }

    .buttons {
      display: flex;
      align-self: flex-start;
      flex-wrap: wrap;
      gap: 12px;
    }
  }
}

.types {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-self: flex-start;
  gap: space('Xxs');
  .typePill {
    background-color: $colorPrimary3;
    border-radius: space('Xxs');
    font-size: 12px;
    line-height: space('Xs');
    padding: space('Xxxs') space('Xxs');

    &.light {
      background: $colorPrimary4;
    }
    &.archived {
      background: $colorGrey3;
    }
  }
}

.infoModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .banner {
    @include clickableIcon;

    margin-top: 4px;
    @include mqSmaller($bpM) {
      margin-top: 2px;
    }
  }
}

.infoModalContent {
  margin: space('Xs') 0;
}

.infoModalFooter div {
  padding-top: space('Xxs');
  font-size: 12px;
  line-height: space('Xs');
  color: $colorGrey2;
}