@import '/src/styles-imports/shared';

/* LineDiagram */
.lineDiagram {
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  svg {
    width: 100%;
    overflow: initial;
  }

  .grey {
    fill: $colorGrey3;
  }

  .marker {
    transition: cx 0.3s ease-in-out;
    &.distribution {
      fill: $colorBlue04;
    }
    &.primary, &.blue {
      fill: $colorBlueMain;
    }
    &.yellow {
      fill: $colorYellowMain;
    }
    &.green {
      fill: $colorGreenMain;
    }
    &.red {
      fill: $colorRedMain;
    }
    &.purple {
      fill: $colorPurpleMain;
    }
    &.grey {
      fill: $colorGrey01;
    }
    @include diagramFills();
  }

  .range {
    transition: width 0.3s ease-in-out, x 0.3s ease-in-out;
    &.primary, &.blue {
      stroke: $colorBlue05;
    }
    &.yellow {
      stroke: $colorYellow05;
    }
    &.green {
      stroke: $colorGreen05;
    }
    &.red {
      stroke: $colorRed05;
    }
    &.purple {
      stroke: $colorPurple05;
    }
    &.grey {
      stroke: $colorGrey06;
    }
  }
}
