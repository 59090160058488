@import '/src/styles-imports/shared';

/* QuestionCodeConfirmation */
.questionCodeConfirmation {
  text-align: center;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  & > div, > a, > button {
    display: block;
    margin: space('M') auto 0;
  }
  & > div:first-child a {
    font-size: $typeSizeCopyStrong;
    &:after {
      content: none;
    }
  }

  .codeInputGroupContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
