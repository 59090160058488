@import '/src/styles-imports/shared';

/* AssessmentRadioButtonGroup */
.assessmentRadioButtonGroup {

  /* INTERACTION */

  /* MODIFIERS */
  &.horizontal {
    display: flex;

    // AssessmentRadioButton
    & > label {
      width: auto;
      flex-shrink: 0;
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
}
