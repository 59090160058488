@import '/src/styles-imports/shared';

/* MODALSANDPANELS */
.modalsAndPanels {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .toastTitle {
    @include type('LabelL');
    text-align: left;

    margin-right: $spaceM;
    overflow: hidden;
  }

  .toastDescription {
    @include type('Copy');
    text-align: left;

    margin-top: $spaceXxs;
  }
}
