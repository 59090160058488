@import '/src/styles-imports/shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0;
  transform: translateY(-5px);
}
.enterActive, .enterDone {
  opacity: 1;
  transform: translateY(0px);
}
.exit {
  opacity: 1;
  transform: translateY(0px);

  // color chip red on leave, independent from hover state for touch devices
  border-color: $colorDanger1 !important;
  color: $colorDanger1 !important;
  svg path { fill: $colorDanger1 !important; }
}
.exitActive, .exitDone {
  opacity: 0;
  transform: translateY(-5px);

  // color chip red on leave, independent from hover state for touch devices
  border-color: $colorDanger1 !important;
  color: $colorDanger1 !important;
  svg path { fill: $colorDanger1 !important; }
}


$searchResultHeight: 75px;

/* hotChipsPEOPLE */
.hotChipsPeople {


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .label {
    margin-bottom: space('Xs');
    @include type('LabelL');
  }

  .hint {
    margin-top: space('Xxs');
    margin-left: 2px; // move right so flyout completely overlaps
    @include type('Hint');
    color: $colorGrey2;
  }

  .chips {
    padding-top: space('Xxs');

    > div /*transitinogroup*/ > div /*chips*/ {
      margin-top: space('Xs');
      margin-right: space('Xxs');
    }
  }

  // FLYOUT
  .flyoutContainer {
    position: relative;
  }
  .flyout {
    box-sizing: border-box;
    position: absolute;
    top: space('Xxs') + 2px; // move down so it completely overlaps chips
    left: 0;
    width: 100%;
    background-color: white;
    border-radius: $borderRadius;
    filter: $dropShadow;
    overflow: hidden;
    z-index: zIndex('Buffer3');
    &.topFlyout {
      top: auto;
      bottom: space('Xxs') + $spaceL; // move up if space below is insufficient
    }

    @include animation(slideUp);

    > div {
      overflow-y: auto;
      max-height: 300px;
    }
  }
  .searchResult, .skeleton {
    box-sizing: border-box;
    height: $searchResultHeight;
    padding: space('S');
  }
  .searchResult {
    display: flex;
    align-items: center;

    border-bottom: 1px solid $colorGrey4;
    background-color: white;

    @include transition(background-color, border-color);

    &:last-child {
      border-bottom: 0;
    }

    &:hover, &.active {
      cursor: pointer;
      border-color: $colorPrimary3;
      background-color: $colorPrimary4;
    }

    .imgCircle {
      flex: 0 0 36px;
      padding-right: space('Xs');
    }
    .labels {
      flex: 1 0;
      min-width: 0px; // for ellipsis to work on flex-child child element (.label & .sublabel)
      .label {
        @include type('LabelL');
        margin: 0; // override .label on top level
        @include ellipsis();
      }
      .subLabel {
        margin-top: space('Xxxs');
        @include ellipsis();
      }
    }
    &.disabled {
      pointer-events: none;
      > * {
        filter: grayscale(100%) opacity(.6);
      }
    }
  }

  .showMoreResults {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 84px;

    .loading {
      width: 100%;
      margin: $spaceS;
    }
  }

  .message {
    padding: space('Xs');
  }

  .skeleton {
    height: 100%;
    margin-top: space('Xxxs');
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
