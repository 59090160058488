@import '/src/styles-imports/shared';

/* NESTEDWRAPPER */
.nestedWrapper {
  box-sizing: border-box;

  @include animation(slideUp);
  z-index: zIndex('Modal');

  /* INTERACTION */

  /* MODIFIERS */
  &.autoMargins {
    padding: $spaceL 0;
    margin: 0 $gridMarginLarge;

    @include mqSmaller($bpS) {
      padding: $spaceS 0;
      margin: 0 $gridMarginSmall;
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
}
