@import '/src/styles-imports/shared';

/* SinglePageAssessment */
.singlePageAssessment {
  @include basicGrid;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .gridContent {
    grid-column: 4 / 10;
    margin-top: $spaceL;
    margin-bottom: $spaceM;

    @include mqSmaller($bpM) {
      grid-column: 2 / 8;
    }

    @include mqSmaller($bpS) {
      grid-column: start / end;
    }
  }

  .questions {
    margin-top: $spaceL;

    .question {
      padding: $spaceM 0;
      &:not(.explanatory):not(:first-child) {
        border-top: 1px solid $colorGrey4;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: $spaceL;
    & > button:last-child {
      margin-left: $spaceXs;
    }
  }

  .finalPage, .answersSaved {
    text-align: center;
    .graphic {
      font-size: 48px;
      line-height: 48px;

      @include mqSmaller($bpM) {
        font-size: 32px;
        line-height: 40px;
      }
    }

    & > button {
      margin: $spaceS auto 0;
    }
  }
}
