@import '/src/styles-imports/shared';

/* QUESTIONDRAGDROPBESTLEAST */
.questionDragDropBestLeast {

  @include basicGrid;

  @include transition(transform, opacity);

  padding-bottom: space('L');

  /* INTERACTION */

  /* MODIFIERS */
  &.closing {
    transform: translateY(-30px);
    opacity: 0;
  }


  /* CHILDREN */
  .container {
    grid-column: 4/10;
    text-align: center;

    & > button {
      display: block;
      margin: $spaceM auto 0;
    }

  }

  .questionHeader {
    margin-bottom: $spaceM;

    .questionTitle {
      @include type('S');
    }
    .questionDescription {
      max-width: 750px;
      margin: auto;
      margin-top: $spaceXs;
  
      @include type('CopyStrong');
    }
  }
  .dragDrop {
    display: flex;
    > div {
      text-align: left;
      box-sizing: border-box;
      width: 50%;
    }
  }
  .left {
    padding-right: $spaceXs;
  }
  .right {
    padding-left: $spaceXs;
  }
  .title, .titleWithMargin {
    @include type('LabelM');
  }
  .titleWithMargin {
    margin-top: $spaceS;
  }
  .options {
    margin-top: $spaceXs;
  }

  .hintFlex {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      margin: 0 $spaceXxxs;
    }
    svg:last-child {
      margin: 0 $spaceXxxs 0 0;
    }
  }

  /* RESPONSIVE */
  @include mqSmaller($bpM) {
    .container {
      grid-column: 2/8;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
      padding-top: 4px;
    }
    .dragDrop {
      display: block;

      > div {
        width: 100%;
        padding: 0px;
      }
    }
    .right {
      margin-top: $spaceM;
    }
  }

}

.helpMeGraphic {
  display: block;
  width: 300px;
  height: 169px;

  margin: $spaceXxs auto 0;

  @include mqSmaller($bpS) {
    width: 256px;
    height: 145px;
  }
}

.option {
  cursor: grab;
  user-select: none;

  display: flex;
  align-items: center;

  position: relative;
  box-sizing: border-box;
  min-height: 64px;
  padding: 10px $spaceXs;
  padding-right: $spaceM;
  margin-bottom: $spaceXxs;

  border-radius: 4px;
  background-color: $colorGrey5;
  z-index: zIndex('Buffer3');

  @include transition(box-shadow);

  &:hover {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
  }

  &:before {
    position: absolute;
    content: " ";
    right: $spaceXs;
    width: $spaceXs;
    height: $spaceXs;
    background-image: url('icons/icn_drag_indicator.svg');
  }

  &.dragged {
    &:before {
      background-image: none;
    }
  }

  &.isDragging {
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  }

  &.empty {
    cursor: auto;
    padding-left: $spaceM;

    color: $colorGrey2;

    border: 1px dashed $colorGrey2;
    background-color: transparent;
    z-index: zIndex('Buffer2');

    @include transition(border-color);

    &.draggedOver {
      border-color: $colorPrimary1;
    }

    &:before {
      right: auto;
      left: $spaceXxs;
      background-image: url('icons/icn_arrow.svg');
    }
  }

  .optionLabel {
    @include type('Copy');

    padding-right: $spaceXxs;
    white-space: pre-wrap;
    word-break: break-word;
  }
  .optionHints {
    @include type('Hint');

    margin-top: $spaceXxs;
    padding-right: $spaceXxs;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.closeIcon {
  width: $spaceXs;
  height: $spaceXs;

  position: absolute;
  right: $spaceXs;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
  @include transition(fill);

  &:hover {
    path {
      fill: $colorPrimary2;
    }
  }
}
