@import '/src/styles-imports/shared';

$codeInputSize: 54px;

/* CodeInput */
.codeInput {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  & > div > input {
    width: $codeInputSize;
    height: $codeInputSize;
    min-width: $codeInputSize;

    box-sizing: border-box;
    padding: 15px space('Xs');

    @include type('CopyStrong');
    text-align: center;
  }
}
