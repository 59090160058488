@import '/src/styles-imports/shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  transform: translateX(-244px) !important;
}
.enterActive, .enterDone {
  transform: translateX(0px) !important;
  @include transitionLong(transform);
}
.exit {
  transform: translateX(0px) !important;
}
.exitActive, .exitDone {
  transform: translateX(-244px) !important;
  @include transitionLong(transform);
}
