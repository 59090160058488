@import '/src/styles-imports/shared';

/* GarminIndividualResult */
.garminIndividualResult {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  // Card component
  & > div:first-child {
    padding: space('M') 0 !important;

    @include mqSmaller($bpM) {
      padding: space('S') 0 space('M') !important;
    }
  }

  .infoTitle {
    @include mqSmaller($bpM) {
      justify-content: flex-start;
    }
  }

  .blockWithPaddings {
    padding: 0 space('M');

    @include mqSmaller($bpM) {
      padding: 0 space('Xs');
    }

    & > svg {
      margin-top: space('Xs');
    }
  }

  .additionalInfo {
    @include type('Copy');
    color: $colorPrimary1;
    margin-top: space('Xs');

    @include transition(color);
    &:hover {
      cursor: pointer;
      color: $colorPrimary2;
    }
  }

  .collapsiblePanels {
    margin-top: space('Xs');

    .collapsibleHeader {
      padding-left: space('M');
      padding-right: space('M');

      &:hover {
        background-color: $colorPrimary4;
      }

      @include mqSmaller($bpM) {
        padding-left: space('Xs');
        padding-right: space('Xs');
      }
    }

    .collapsibleInnerHeader {
      display: flex;
      align-items: center;
      span {
        margin-left: space('Xxs');
      }
    }

    & > div > div:last-child {
      padding-left: space('M');
      padding-right: space('M');

      @include mqSmaller($bpM) {
        padding-left: space('Xs');
        padding-right: space('Xs');
      }
    }
  }

  .hint {
    @include type('Hint');
    color: $colorPrimary1;
    margin-top: space('Xs');
  }

}
