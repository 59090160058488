@import '/src/styles-imports/shared';

body {
  overflow-y: auto;
  overflow-x: hidden;
}

.overview {
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$spaceM});
  background-color: $colorGrey6;
  padding-bottom: space('M');

  .teasers {
    @include basicGrid;
    margin-top: space('L') !important;
    margin-right: 0 !important; // breakt out to the right
    margin-left: 0px !important;
    overflow-x: hidden;
    overflow-y: hidden;
    max-width: 100%;
    .title {
      @include type('Xxs');
      color: $colorGrey1;
      grid-column: 1/13;
      animation: slideUp $animationDuration $animationFunction;
      margin-left: 60px;
    }
    .titleWithScrollable {
      grid-column: start/end;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: slideUp $animationDuration $animationFunction;
      max-width: calc(100% - 120px);
      margin-left: 60px;
      margin-right: 60px;

      @include type('Xxs');
      color: $colorGrey1;

      @include mqSmaller($bpS) {
        margin-left: space('Xs');
        margin-right: space('Xs');
      }
    }
    .descriptionBlock {
      grid-column: start/end;
      display: flex;
      align-items: center;
      justify-content: space-between;
      animation: slideUp $animationDuration $animationFunction;
      max-width: calc(100% - 120px);
      margin-left: 60px;
      margin-right: 60px;
      margin-top: space('Xs');
      .description {
        @include type('Copy');
        color: $colorGrey1;
      }
    }
    .subTeasers {
      grid-column: 1/13;
      animation: slideUp $animationDuration $animationFunction;
      max-width: 100%;

      margin: space('Xxs') 60px 0 60px;
      @include transition(margin-left);

      .myProfileTeaser {
        margin-top: space('Xs');
      }
    }
  }

  .applicationSection {
    grid-column: 1/13;
    margin: 0 60px;

    @include mqSmaller($bpS) {
      margin: 0 $spaceXs;
    }
  
    .title {
      @include type('Xxs');
      color: $colorGrey1;
      animation: slideUp $animationDuration $animationFunction;
      margin: $spaceM 0;
    }
    
    .content {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: $spaceXs;

      @include mqSmaller($bpS) {
        flex-direction: column;
      }

      > :first-child {
        flex: 3;
      }
      > :last-child {
        flex: 2;
      }

      .flows {
        display: flex;
        flex-direction: column;
        gap: $spaceXs;
      }
    }
  }

  .welcomeTitle {
    @include type('S');
    margin-bottom: space('S');
  }

  .teaser {
    min-width: 100%;
    margin-right: space('Xs');
    & > div:first-child {
      height: 100%;
      & > div:first-child {
        height: 100%;
        & > div:first-child {
          height: 100%;
        }
      }
    }
  }

  .onboardingTeasersContainer {
    $navWidth: 40px;

    display: flex;
    width: 100%;

    .onboardingTeasersNav {
      width: $navWidth;
      display: flex;
      align-items: center;
      > * {
        margin-top: calc((-40px - $spaceXs) / 2); // compensate for pagination
      }
    }
    .onboardingTeasers {
      padding: 0px $spaceXs;
      box-sizing: border-box;
      width: calc(100% - 2 * #{$navWidth});
    }

    @include mqSmaller($bpL) {
      .onboardingTeasersNav {
        display: none;
      }
      .onboardingTeasers {
        padding: 0;
        width: 100%;
      }
    }
  }


  .orgDevelopmentItems {
    margin-top: space('L');
    margin-left: $gridMarginLarge;
    margin-right: $gridMarginLarge;
  }

  .other {
    @include basicGrid;
    margin-top: space('L');
    margin-right: 0 !important; // breakt out to the right
    margin-bottom: space('M');
    margin-left: 0px !important;
    max-width: 100%;
    overflow: hidden;
    .titleBlock {
      grid-column: 1/13;
      display: flex;
      justify-content: space-between;
      animation: slideUp $animationDuration $animationFunction;
      max-width: calc(100% - 120px);
      margin-left: 60px;
      .title {
        @include type('Xxs');
        color: $colorGrey1;
        grid-column: 1/13;
      }
    }
    .actions {
      display: flex;
      margin-left: 0;
      grid-column: 1/13;
      animation: slideUp $animationDuration $animationFunction;
      @include transition(margin-left);
      max-width: 100%;
      overflow: auto;
      margin-left: 40px;
      padding-left: 20px;
      padding-bottom: 24px;
      .action {
        //calculation of width for each action teaser
        //108 = 60 + 48, where 60 - margin on the right of actions container, and 48 - margins between teasers
        min-width: calc((100% - 108px) / 4);
        max-width: calc((100% - 108px) / 4);
        border: 1px solid $colorGrey4;
        border-radius: 10px;
        margin-top: space('S');
        margin-right: space('Xs');
        cursor: pointer;
        box-sizing: border-box;

        background: $colorWhite;

        @include transition(box-shadow);
        &:hover {
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
        &:focus {
          outline: none;
          box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
        }
        .imageBlock {
          width: 100%;
          position: relative;
          //16:9 aspect ratio
          padding-top: 56.25%;
          overflow: hidden;
          img {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .text {
          padding: 8px 28px 32px;
          .title {
            @include type('LabelL');
            color: $colorPrimary1;
            text-align: center;
          }
          .description {
            @include type('Copy');
            color: $colorGrey1;
            margin-top: space('Xxs');
            text-align: center;
          }
        }
      }
    }

    @include mqSmaller($bpS) {
      margin-top: space('L');
    }

    .actions::-webkit-scrollbar {
      display: none;
    }
  }

  .pagingButtons, .scrollingButtons {
    display: flex;

    & > div:first-child {
      margin-right: space('Xxs');
    }

    .left, .right {
      width: 40px;
      height: 40px;
      border: 1px solid $colorGrey3;
      border-radius: 50%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      @include transition(border);
      &.active {
        cursor: pointer;
        border-color: $colorPrimary1;
        @include transition(border-color, background-color);
        svg {
          path {
            fill: $colorPrimary1;
          }
        }
      }
      &.active:hover {
        background-color: $colorPrimary3;
        border-color: $colorPrimary2;
        svg {
          path {
            fill: $colorPrimary2;
          }
        }
      }
      svg {
        path {
          fill: $colorGrey3;
        }
      }
    }
    .left {
      margin-right: space('Xxs');
    }
  }
  @include mqSmaller($bpM) {
    .teasers {
      grid-column: 1/9;
      .title {
        grid-column: 1/9;
      }
      .descriptionBlock {
        grid-column: start/end;
        .description {
          padding-right: 75px;
        }
      }
      .subTeasers {
        grid-column: 1/9;
      }
    }
    .other {
      .titleBlock {
        grid-column: 1/9;
      }
      .actions {
        grid-column: 1/9;
        .action {
          //calculation of width for each action teaser
          //92 = 60 + 32, where 60 - margin on the right of actions container, and 32 - margins between actions
          min-width: calc((100% - 96px) / 3);
          max-width: calc((100% - 96px) / 3);
        }
      }
    }
  }
  @include mqSmaller($bpS) {
    .teasers {
      grid-column: start/end;
      .title {
        grid-column: start/end;
        margin-left: 16px;
      }
      .descriptionBlock {
        grid-column: start/end;
        margin-left: space('Xs');
        margin-right: space('Xs');
      }
      .subTeasers {
        grid-column: start/end;
        margin-left: space('Xs');
        margin-right: space('Xs');
      }
    }
    .orgDevelopmentItems {
      margin-left: $gridMarginSmall;
      margin-right: $gridMarginSmall;
    }
    .other {
      .titleBlock {
        margin-left: 16px;
      }
      .actions {
        grid-column: start/end;
        margin-left: 0;
        padding-left: 16px;
        .action {
          min-width: calc(100% - 148px);
          max-width: calc(100% - 148px);
        }
      }
    }
    .pagingButtons, .scrollingButtons {
      display: none;
    }
  }
}
