@import '/src/styles-imports/shared';

/* STARTASSESSMENTS */
.startAssessments {

  @include dialogIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    margin-top: - $spaceS;

    .title {
      margin-bottom: space('M');
    }
  }
  .content.continue {
    margin-top: 0px;
  }

  .breakoutContent {
    margin-top: $spaceS;
    width: 100%;
    position: relative;

  }

  .helpText {
    margin-top: $spaceS;
    @include type('Xxs');
  }

  .helpButton {
    margin-top: $spaceS;

    svg {
      height: 14px;
    }
  }
}
