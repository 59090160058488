@import '/src/styles-imports/shared';


// forwards page transitions
$pageForwardsInDuration: 400ms;
$pageForwardsInDelay: 400ms;
$pageForwardsOutDuration: 450ms;

$pageBackwardsInDuration: 600ms;
$pageBackwardsInDelay: 600ms;
$pageBackwardsOutDuration: 600ms;

:export {
  pageForwardsInDuration: $pageForwardsInDuration;
  pageForwardsInDelay: $pageForwardsInDelay;
  pageForwardsOutDuration: $pageForwardsOutDuration;
  pageBackwardsInDuration: $pageBackwardsInDuration;
  pageBackwardsInDelay: $pageBackwardsInDelay;
  pageBackwardsOutDuration: $pageBackwardsOutDuration;
}


/* DIALOG SEQUENCE */
.dialogSequence {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;

  z-index: z('FullscreenOverlay');

  background-color: rgba(215,215,215,0.3);
  backdrop-filter: blur(10px);

  animation: show $animationDurationLong;

  @keyframes show {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes hide {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes moveUp {
    from {
      opacity: 1;
    }
    to {
      transform: translateY(-30px);
      opacity: 0;
    }
  }

  /* INTERACTION */

  /* MODIFIERS */
  &.hide {
    pointer-events: none;
    animation: hide $animationDurationLong;
    animation-delay: $animationDurationLong * 2;
    animation-fill-mode: both;
  }
  &.smallHeader {
    .header {
      height: $headerHeight;
      transform: translateY(-#{$headerHeight + 1px});

      @keyframes headerIn {
        from {
          transform: translateY(-#{$headerHeight} + 1px);
        }
        to {
          transform: translateY(0px);
        }
      }
      @keyframes headerOut {
        from {
          transform: translateY(0px);
        }
        to {
          transform: translateY(-#{$headerHeight} + 1px);
        }
      }
    }

    .progressBarContainer {

      @keyframes progressIn {
        from {
          opacity: 0;
          transform: translateY(-#{$headerHeight});
        }
        to {
          opacity: 1;
          transform: translateY(0px);
        }
      }
      @keyframes progressOut {
        from {
          transform: translateY(0px);
        }
        to {
          opacity: 0;
          transform: translateY(-#{$headerHeight});
        }
      }
    }

    .dialogContent {
      min-height: calc(100vh - #{$headerHeight} - #{$progressBarHeight * 2}); // * 2 for buffer

      @keyframes contentIn {
        from {
          opacity: 0;
          transform: translateY(#{$headerHeight});
        }
        to {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */

  // OVERLAY
  .overlay {
    height: 100%;

    background-color: white;

    opacity: 0;
    animation: show $animationDurationLong;
    animation-delay: $animationDuration;
    animation-fill-mode: both;

    &.hide {
      pointer-events: none;
      animation: hide $animationDurationLong;
      animation-delay: $animationDurationLong + $animationDuration;
      animation-fill-mode: both;
    }
  }

  // HEADER
  .header {
    position: relative;

    $borderHeight: 1px;

    display: flex;
    align-items: center;
    height: $headerHeightMedium;

    border-bottom: $borderHeight solid $colorGrey4;
    background-color: white;

    z-index: z('HomeHeader');

    transform: translateY(-#{$headerHeightMedium + $borderHeight});

    animation: headerIn $animationDurationLong;
    animation-delay: $animationDurationLong;
    animation-fill-mode: both;



    @keyframes headerIn {
      from {
        transform: translateY(-#{$headerHeightMedium} + $borderHeight);
      }
      to {
        transform: translateY(0px);
      }
    }
    @keyframes headerOut {
      from {
        transform: translateY(0px);
      }
      to {
        transform: translateY(-#{$headerHeightMedium} + $borderHeight);
      }
    }

    &.hide {
      pointer-events: none;
      animation: headerOut $animationDurationLong;
      animation-delay: $animationDuration;
      animation-fill-mode: both;
    }

  }

  // PROGRESS BAR
  .progressBarContainer {
    position: relative;

    z-index: 0;

    @include transitionLong(margin);
    animation: progressIn $animationDurationLong;
    animation-delay: $animationDurationLong + calc($animationDuration / 2);
    animation-fill-mode: both;


    @keyframes progressIn {
      from {
        opacity: 0;
        transform: translateY(-#{$headerHeightMedium});
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes progressOut {
      from {
        transform: translateY(0px);
      }
      to {
        opacity: 0;
        transform: translateY(-#{$headerHeightMedium});
      }
    }

    &.hide {
      animation: progressOut $animationDurationLong;
      animation-delay: $animationDuration;
      animation-fill-mode: both;
    }

    &.hideInBetween {
      margin-top: -#{$progressBarHeight};
      margin-bottom: $progressBarHeight; // no content jump for everything belwo
    }


    .progressBar {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: $progressBarHeight;
    }
  }

  // DIALOG CONTENT
  .dialogContent {
    position: relative;

    top: $progressBarHeight;

    min-height: calc(100vh - #{$headerHeightMedium} - #{$progressBarHeight * 2}); // * 2 for buffer
    text-align: center;

    overflow: auto;
    opacity: 0;

    animation: contentIn $animationDurationLong;
    animation-delay: $animationDurationLong + $animationDuration;
    animation-fill-mode: both;

    @keyframes contentIn {
      from {
        opacity: 0;
        transform: translateY(#{$headerHeightMedium});
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes contentOut {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }

    &.hide {
      pointer-events: none;
      animation: contentOut $animationDurationLong;
    }
  }

  // PAGE
  .page {
    position: absolute;
    display: flex;
    justify-content: center;
    text-align: left;

    box-sizing: border-box;
    top: 0;
    width: 100%;
    min-height: calc(100% - #{$spaceM});
    padding: $spaceXl $spaceM 0 $spaceM;

    // MODIFIERS
    &.begin {
      &:nth-child(2) {
        opacity: 0;
      }
    }

    &.forwards {
      &.in {
        animation: pageForwardsIn $pageForwardsInDuration;
        animation-delay: $pageForwardsInDelay;
        animation-fill-mode: both;
      }
      &.out {
        pointer-events: none;
        animation: pageForwardsOut $pageForwardsOutDuration;
        animation-fill-mode: both;
      }
    }
    &.backwards {
      &.in {
        animation: pageBackwardsIn $pageBackwardsInDuration ease-out;
        animation-delay: $pageBackwardsInDelay;
        animation-fill-mode: both;
      }
      &.out {
        pointer-events: none;
        animation: pageBackwardsOut $pageBackwardsOutDuration;
        animation-fill-mode: both;
      }
    }


    @keyframes pageForwardsIn {
      from {
        opacity: 0;
        transform: translateY(30px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes pageForwardsOut {
      from {
        opacity: 1;
        transform: translateY(0px);
      }
      to {
        opacity: 0;
        transform: translateY(-30px);
      }
    }
    @keyframes pageBackwardsIn {
      from {
        opacity: 0;
        transform: translateY(-30px);
      }
      to {
        opacity: 1;
        transform: translateY(0px);
      }
    }
    @keyframes pageBackwardsOut {
      from {
        opacity: 1;
        transform: translateY(0px);
      }
      to {
        opacity: 0;
        transform: translateY(30px);
      }
    }


    // RESPONSIVE
    @include mqSmaller($bpM) {
      padding: $spaceL $spaceXs $spaceXs $spaceXs;
    }


    .pageContent {
      width: 100%;
      // min-height: 100%;

      &:focus {
        outline: none;
      }
    }

  }

  .question {

    @include basicGridNoMargins;

    /* INTERACTION */

    /* MODIFIERS */

    /* RESPONSIVE */

    @include mqSmaller($bpL) {
      .title {
        grid-column: 3 / 11 !important;
      }
      .content, .controls .controlsInner {
        grid-column: 4 / 10 !important;
      }
    }

    @include mqSmaller($bpM) {
      .title {
        grid-column: 2 / 8 !important;
      }
      .content, .controls .controlsInner {
        grid-column: 3 / 7 !important;
      }
    }

    @include mqSmaller($bpS) {
      .content, .title, .controls .controlsInner {
        grid-column: start / end !important;
      }
    }


    /* CHILDREN */
    .title {
      @include type('M');
      text-align:center;
      grid-column: 4 / 10;
    }

    .content {
      margin-top: $spaceXs;
      grid-column: 5 / 9;
      &.customLayout {
        grid-column: start / end !important;
      }
    }
    .formRow {
      margin-top: $spaceXs;
    }


    .controls {

      margin-top: $spaceM;
      margin-bottom: $spaceM;

      &.customLayout {
        @include basicGridNoMargins;
        .controlsInner {
          grid-column: 5 / 9;
        }
      }

      &.wideControls {
        @include basicGridNoMargins;
        .controlsInner {
          grid-column: 4 / 10;
        }
      }

      .controlsInner {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

    }
    .exit {
      display: flex;
      justify-content: flex-end;

      margin-top: $spaceM;
    }
  }

}
