@import '/src/styles-imports/shared';

.assessmentsList {
  margin-top: $spaceXs;

  .listItem {
    display: flex;
    padding: $spaceXs;
    border-bottom: 1px solid $colorGrey4;

    &:first-child {
      border-top: 1px solid $colorGrey4;
    }

    .left {
      width: calc(100% - #{$spaceXxs} - 48px);

      & > div {
        @include type('Copy');
        &:last-child {
          color: $colorGrey2;
          margin-top: $spaceXxs;
        }
      }
    }

    .right {
      margin-left: $spaceXxs;
    }
  }
}
