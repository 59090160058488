@import '/src/styles-imports/shared';

$height: 40px; // ButtonCircular Height
$bubbleWidth: 16px;
$numberWidth: 26px;
$bubbleContainerWidth: 172px;


/* PAGINATION */
.pagination {
  display: flex;

  /* CHILDREN */
  .pagerButtonLeft, .pagerButtonRight {
    height: $height;
    width: $height;
    flex: 0 0 40px;
  }
  .bubbles {
    height: $height;
    flex: 1 1 0;
  }

  /* BUBBLES */
  .bubblesContainer {
    width: $bubbleContainerWidth;
    height: 100%;
    margin: auto;
    overflow: hidden;
  }
  .bubblesScroll {
    height: 100%;

    display: flex;
    align-items: center;
    justify-items: center;
    flex-wrap: nowrap;
    overflow: visible;
    margin-top: 2px; // looks better

    @include transitionLong(opacity, transform);

    &.hidden {
      opacity: 0;
    }
  }

  /* INTERACTION */

  /* MODIFIERS */

  // BUBBLES, NUMBERS
  .bubbleBox, .numberBox { // give a nice big click area
    cursor: pointer;
    width: $bubbleWidth;
    height: $bubbleWidth;

    flex: 0 0 $bubbleWidth;
    display: flex;
    justify-content: center;
    align-items: center;

    /* interaction: bubble hover */
    &:hover .bubble, &:hover .number {
      background-color: $colorPrimary1;
    }
    &:hover .number {
      color: $colorWhite;
    }
  }
  .bubble, .number {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    background-color: $colorGrey3;

    transform: scale(0);

    @include transition(background-color, transform, color);

    /* interaction: bubble overflow */
    &.active {
      color: $colorWhite;
      background-color: $colorPrimary1;
      transform: scale(1);
    }
    &.medium {
      transform: scale(0.8);
    }
    &.small {
      transform: scale(0.65);
    }
    &.tiny {
      transform: scale(0.45);
    }

  }


  // showNumbers
  .numberBox {
    width: $numberWidth;
    height: $numberWidth;

    flex: 0 0 $numberWidth;
  }
  .number {
    width: $numberWidth - 4px;
    height: $numberWidth - 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: $colorPrimary1;

    background-color: rgba(0,0,0,0);

    &.medium {
      transform: scale(0.9);
    }
    &.small {
      transform: scale(0.7);
    }
    &.tiny {
      transform: scale(0.5);
    }

  }
}

:export {
  bubbleWidth: $bubbleWidth;
  numberWidth: $numberWidth;
  bubbleContainerWidth: $bubbleContainerWidth;
}
