@import '/src/styles-imports/shared';

/* Peer360ResultItem */
.peer360ResultItem {
  box-sizing: border-box;
  width: 100%;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .peer360ResultItemCollapsibleHeader {
    position: relative;
    padding: 0 !important;

    // CollapsibleNextContent
    + div {
      padding-bottom: 0 !important;

      @include mqSmaller($bpM) {
        padding-left: $spaceXs;
        padding-right: $spaceXs;
      }
    }

    &.isExpandable {
      @include mqSmaller($bpM) {
        //arrow icon
        & > div > div:last-child {
          position: absolute;
          top: $spaceXs;
          right: 0;
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    padding: $spaceXs 0;

    @include mqSmaller($bpM) {
      flex-direction: column-reverse;

      .textContent {
        width: 100%;
        box-sizing: border-box;
        margin-left: 0;

        padding-right: 22px;
      }
    }
  }

  .textContent {
    width: 50%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: $spaceS;

    // dimension name
    & > span:first-child {
      white-space: pre-wrap;
    }

    // dimension value
    & > span:last-child {
      padding-left: $spaceXxs;

      @include type('Copy');
      color: $colorPrimary1;
    }
  }

  .subDimensionsContainer {
    padding-left: $spaceXxs;

    @include mqSmaller($bpM) {
      padding-left: 0;
    }

    .subDimension {
      display: flex;
      align-items: center;

      padding: $spaceXs 0;

      .subDimensionTextContent {
        width: 50%;

        display: flex;
        justify-content: flex-start;

        margin-left: $spaceS;

        // sub dimension name
        & > span:first-child {
          white-space: pre-wrap;
        }

        & > svg {
          flex-shrink: 0;
          margin-top: 3px;
          margin-left: $spaceXxs;

          &:hover {
            cursor: pointer;

            & > path {
              @include transition(fill);
              fill: $colorPrimary2;
            }
          }
        }
      }

      @include mqSmaller($bpM) {
        flex-direction: column-reverse;

        .subDimensionTextContent {
          width: 100%;
          margin-left: 0;
          justify-content: space-between;
        }
      }
    }
  }

  .diagram {
    width: 50%;

    @include mqSmaller($bpM) {
      width: 100%;
      margin-top: $spaceXs;
    }
  }
}
