@import '/src/styles-imports/shared';

/* DEBUG */
.debug {
  @include pageBackgroundGrey;
  padding-top: 80px;


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    @include basicPage;
  }
  .buttons {
    margin-top: $spaceXs;
    > * {
      margin-right: $spaceXxs;
    }
  }
}
