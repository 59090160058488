@import '/src/styles-imports/shared';

/* QuestionLanguage */
$animationBreatheDuration: 6.5s;

.questionLanguage {
  position: relative;

  @include basicGrid;
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */


  @include mqSmaller($bpM) {
    .content {
      grid-column: 3 / 7 !important;
    }
  }

  @include mqSmaller($bpS) {
    .content {
      grid-column: start / end !important;
    }
    .breatheContainer {
      display: block;
    }
    .breathe {
      width: calc(100vw - 48px) !important;
      height: calc(100vw - 48px) !important;
    }
  }

  /* CHILDREN */
  .breatheContainer {
    position: absolute;
    top: -5vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: -1;
  }
  .breathe {
    height: 65vh;
    width: 65vh;
    border-radius: 50%;
    background-color: $colorPrimary4;
    z-index: -1;

    animation: scale $animationBreatheDuration infinite ease-out;


    @keyframes scale {
      0% {
        opacity: 0.65;
        transform: scale(0.8);
      }
      50% {
        opacity: 1;
        transform: scale(1);
      }
      52% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 0.65;
        transform: scale(0.8);
      }
    }
  }



  .content {
    grid-column: 5 / 9;

    & > button {
      display: block;
      margin-left: auto;
      margin-top: space('M');
    }
  }
  .title {
    @include type('M');
    text-align:left;
  }

  .languages {
    margin-top: space('M');
    .rows {
      margin-top: space('Xs');
      .language {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        padding: space('Xs');
        box-sizing: border-box;
        border-top: 1px solid $colorGrey4;
        border-bottom: 1px solid $colorGrey4;

        @include type('Copy');
        color: $colorGrey1;

        @include transition(background-color);

        &:hover {
          background-color: $colorPrimary4;
        }
        &:last-child {
          border-top: none;
        }
        &.active {
          color: $colorPrimary1;
        }
      }
    }
  }

}
