@import '/src/styles-imports/shared';

/* POTENTIAL */
.potential {

  @include dialogIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
