@import '/src/styles-imports/shared';

/* RecurringAssessmentControl */
.recurringAssessmentControl {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .flexContainer {
    display: flex;

    .textContent {
      flex: 1;

      .latestResultText {
        @include type('Copy');
        color: $colorGrey2;
        margin-top: $spaceXxxs;
      }

      .showCurrentResult {
        display: flex;
        align-items: center;

        margin-top: $spaceXxxs;
        color: $colorPrimary1;

        & > svg {
          flex-shrink: 0;
          width: 14px;
          height: 14px;
          margin-right: $spaceXxs;

          path {
            fill: $colorPrimary1;
          }
        }

        @include transition(color, fill);
        &:hover {
          cursor: pointer;
          color: $colorPrimary2;

          & > svg path {
            fill: $colorPrimary2;
          }
        }
      }
    }

    .controls {
      margin-left: $spaceXs;

      display: flex;

      & > div:last-child {
        margin-left: $spaceXs;
      }
    }
  }

  .locked {
    display: flex;
    justify-content: space-between;
    gap: $spaceXs;
    margin-top: $spaceXs;

    .lockedButton {
      text-wrap: nowrap !important;
    }

    @include mqSmaller($bpM) {
      flex-direction: column;
    }
  }

  & > div > button {
    display: block;
    margin: $spaceXs 0 0 auto;
  }
}
