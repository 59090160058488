@import '/src/styles-imports/shared';

/* DOWNLOAD FILE BUTTON */
.downloadFileButton {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .downloadLoader {
    max-height: 14px;
  }

  > button svg {
    height: 14px;
    margin-top: -6px;
    transform: translate(-3px, 3px);
  }
}
