/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''), url('../assets/fonts/montserrat-regular.woff2') format('woff2');
}
/* montserrat-italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local(''), url('../assets/fonts/montserrat-regular-italic.woff2') format('woff2'); 
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''), url('../assets/fonts/montserrat-semibold.woff2') format('woff2');
}
/* montserrat-600italic - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local(''), url('../assets/fonts/montserrat-semibold-italic.woff2') format('woff2');
}




@each $type in $types {
  .bluType#{$type} {
    @include type(#{$type});
  }
}
h1, .h1 {
  @include type('Xxl');
  padding: 0;
  margin: 0;
}
h2, .h2 {
  @include type('Xl');
  padding: 0;
  margin: 0;
}
h3, .h3 {
  @include type('L');
  padding: 0;
  margin: 0;
}
h4, .h4 {
  @include type('M');
  padding: 0;
  margin: 0;
}
h5, .h5 {
  @include type('S');
  padding: 0;
  margin: 0;
}
h6, .h6 {
  @include type('Xs');
  padding: 0;
  margin: 0;
}
.h7 {
  display:block;
  @include type('Xxs');
  padding: 0;
  margin: 0;
}
.bluCopyStrong {
  @include type('CopyStrong');
}

a, .bluLink {
  cursor: pointer;
  color: $colorPrimary1;
  text-decoration: none;

  &.bluNoLinkStyles {
    color: inherit;
    text-decoration: none;
  }
}



.bluTypeCenter {
  text-align: center;
}
