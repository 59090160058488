@import '/src/styles-imports/shared';

$optionHeight: 40px;

/* QuestionMultipleChoice */
.questionMultipleChoice {
  @include basicGrid;
  word-break: break-word;

  /* INTERACTION */

  /* MODIFIERS */
  &.animate {
    .questionTitle, .questionLabel, .questionDescription, .helpButton, .nextButton, .hint, .multipleChoiceOption, .skip {
      @include animation(moveUp);
      animation-fill-mode: forwards;
    }
    .option:not(.selected) {
      @include animation(moveUp);
      animation-fill-mode: forwards;
    }
    .option.selected {
      z-index: 1;
    }

    @keyframes moveUp {
      from {
        opacity: 1;
      }
      to {
        transform: translateY(-30px);
        opacity: 0;
      }
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */

  .gridContentBig {
    grid-column: 4 / 10;
    margin-bottom: $spaceM;

    text-align: center;

    @include mqSmaller($bpM) {
      grid-column: 2 / 8;
    }

    @include mqSmaller($bpS) {
      grid-column: start / end;
    }
  }

  .gridContentSmall {
    grid-column: 5 / 9;
    margin-bottom: $spaceM;

    @include mqSmaller($bpL) {
      grid-column: 4 / 10;
    }

    @include mqSmaller($bpM) {
      grid-column: 2 / 8;
    }

    @include mqSmaller($bpS) {
      grid-column: start / end;
    }
  }

  .questionTitle {
    @include type('S');
  }

  .questionDescription {
    max-width: 750px;
    margin: auto;
    margin-top: $spaceXs;

    @include type('CopyStrong');
  }

  .questionLabel {
    max-width: 750px;
    margin: auto;
    margin-bottom: space('Xs');

    text-align: center;
    @include type('LabelL');
  }

  .helpButton, .nextButton {
    margin-top: $spaceM;
    text-align: center;
  }

  .hint {
    @include type('Hint');
    margin-top: $spaceM;
    text-align: center;

    .line1 {
      padding-bottom: $spaceXxs;
    }
    .line2 {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin: 0 $spaceXxxs;
      }
      svg:last-child {
        margin: 0 $spaceXxxs 0 0;
      }
    }
  }

  .errorHint {
    @include type('Hint');
    color: $colorDanger1;
    text-align: center;

    margin-top: $spaceM;
  }

  .multipleChoiceBlock {
    .multipleChoiceOption {
      &:not(:first-child) {
        margin-top: $spaceXs;
      }
    }
  }

  .singleChoiceBlock {
    .answerOptions {
      display: flex;
      justify-content: center;
    }
  }

  .option {
    cursor: pointer;

    min-height: $optionHeight;
    display: flex;
    align-items: center;
    padding: 0 $spaceXs;

    @include type('CopyStrong');

    padding-top: $spaceXxs;
    padding-bottom: $spaceXxs;

    &.skipAnswerOption {
      padding-top: $spaceS;
      color: $colorGrey2;
    }

    @media (hover: hover) {
      &:hover {
        .checkbox {
          &:after {
            opacity: 1;
            background-color: $colorPrimary3;
          }
        }

        .labelLight {
          color: $colorPrimary1;
        }

        .label {
          color: $colorPrimary1;
        }
      }
    }

    &.selected {
      .checkbox {
        &:after {
          background-color: $colorPrimary1;
          opacity: 1;
        }
      }
    }

  }

  .checkbox {
    flex-shrink: 0;

    $selectedPadding: 4px;
    position: relative;

    height: $optionHeight;
    width: $optionHeight;
    margin-right: $spaceXs;

    border-radius: 50%;

    background-color: $colorGrey4;

    &:after {
      content: ' ';
      position: absolute;

      top: $selectedPadding;
      left: $selectedPadding;
      height: $optionHeight - 2 * $selectedPadding;
      width: $optionHeight - 2 * $selectedPadding;

      border-radius: 50%;
      opacity: 0;
      background-color: $colorGrey4;
      z-index: 1;

      @include transition(opacity, background-color);
    }
  }

  .labelLight {
    width: $spaceXs;
    margin-right: $spaceXs;

    color: $colorGrey2;
    text-align: left;
  }

  .label {
    @include transition(color);
    text-align: left;
  }

  .skip {
    color: $colorGrey2;
    font-size: $typeSizeCopyStrong;
    margin: space('M') 0;
    cursor: pointer;
  }
}
