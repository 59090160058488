@import '/src/styles-imports/shared';

$optionHeight: 40px;
$optionHeightSmall: 24px;

/* questionBubblesHorizontal */
.questionBubblesHorizontal {
  padding-bottom: space('L');
  word-break: break-word;

  /* ANIMATE */
  &.animate {
    .questionTitle, .questionLabel, .questionDescription, .helpButton,
    .skip, .keyBoardNavHint, .hints, .dynamicHint {
      @include animation(moveUp);
      animation-fill-mode: forwards;
    }
    .option:not(.selected) {
      @include animation(moveUp);
      animation-fill-mode: forwards;
    }
    .option.selected {
      z-index: 1;
    }

    @keyframes moveUp {
      from {
        opacity: 1;
      }
      to {
        transform: translateY(-30px);
        opacity: 0;
      }
    }
  }

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .questionLabel {
    max-width: 750px;
    margin: auto;
    margin-bottom: space('Xs');

    text-align: center;
    @include type('LabelL');
  }

  .questionTitle {
    max-width: 750px;
    margin: auto;

    text-align: center;
    @include type('S');

    @include mqSmaller($bpM) {
      max-width: 475px;
    }

    &.isBlue {
      color: $colorPrimary1;
    }
  }

  .questionDescription {
    max-width: 750px;
    margin: auto;
    margin-top: space('Xs');

    text-align: center;
    @include type('CopyStrong');
  }
  .optionsParent {
    display: flex;
    justify-content: center;
    margin-top: $spaceM;
  }
  .optionsContainer {
    .hints {
      display: flex;
      justify-content: space-between;
      padding-bottom: $spaceXxs;
      gap: $spaceS;

      & > span {
        flex: 1;
        width: 70px; // counts as min-width
        @include type('Hint');
        word-break: break-word;
        text-align: left;

        &:last-child {
          text-align: right;
        }
      }
    }

    .dynamicHint {
      height: 20px;

      opacity: 0;
      margin-top: 10px;
      color: $colorPrimary1;
      text-align: left;
      @include type('LabelM');

      @include transition(opacity);

      &.visible {
        opacity: 1;
      }
    }
  }
  .options {
    display: flex;
    justify-content: center;
    gap: 48px;
  }
  .option {
    cursor: pointer;

    height: $optionHeight;
    display: flex;
    align-items: center;

    @include type('CopyStrong');

    &.shrink {
      height: $optionHeightSmall;

      .checkbox {
        $selectedPadding: 2px;

        height: $optionHeightSmall;
        width: $optionHeightSmall;

        &:after {
          height: $optionHeightSmall - 2 * $selectedPadding;
          width: $optionHeightSmall - 2 * $selectedPadding;
          top: $selectedPadding;
          left: $selectedPadding;
        }
      }
    }

    @media (hover: hover) {
      &:hover {
        .checkbox {
          &:after {
            opacity: 1;
            background-color: $colorPrimary3;
          }
        }

        .labelLight {
          color: $colorPrimary1;
        }

        .label {
          color: $colorPrimary1;
        }
      }
    }

    &.selected {
      .checkbox {
        &:after {
          background-color: $colorPrimary1;
          opacity: 1;
        }
      }
    }
  }

  .checkbox {
    $selectedPadding: 4px;
    position: relative;

    height: $optionHeight;
    width: $optionHeight;
    flex-shrink: 0;

    border-radius: 50%;

    background-color: $colorGrey4;

    &:after {
      content: ' ';
      position: absolute;

      top: $selectedPadding;
      left: $selectedPadding;
      height: $optionHeight - 2 * $selectedPadding;
      width: $optionHeight - 2 * $selectedPadding;

      border-radius: 50%;
      opacity: 0;
      background-color: $colorGrey4;
      z-index: 1;

      @include transition(opacity, background-color);
    }
  }

  .labelLight {
    width: $spaceXs;
    margin-right: $spaceXs;
    color: $colorGrey2;
  }

  .label {
    @include transition(color);

    @include mqSmaller($bpS) {
      text-align: left;
    }
  }

  .skip {
    @include type('CopyStrong');
    margin-top: $spaceM;
    color: $colorGrey2;
    text-align: center;

    @include transition(color);
    &:hover {
      cursor: pointer;
      color: $colorGrey3;
    }
  }

  .helpButton {
    margin-top: space('M');
    button {
      display: block;
      margin: 0 auto;
    }
  }

  .keyBoardNavHint {
    @include type('Hint');
    margin-top: space('M');
    .line {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        margin: 0 4px;
      }
      svg:last-child {
        margin: 0 4px 0 0;
      }
    }
  }
}
