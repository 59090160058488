@import '/src/styles-imports/shared';

/* DIALOG HEADER */
.dialogHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0 60px;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpM) {
    padding: 0 $spaceXs;
  }

  /* CHILDREN */
  .left {
    padding-right: space('S');
  }

  .right {
    height: 100%;
    display:flex;
    align-items: center;
  }

  .title {
    @include ellipsis();

    @include type('LabelL');
    color: $colorPrimary1;
  }

  .logo {
    width: 140px;
    svg { width: 100%; display: block; }
    @include mqSmaller($bpS) {
      width: 102px;
    }
  }

  .closeButton {
    @include iconWithBackground;
  }

  .close {
    cursor: pointer;

    display: flex;
    align-items: center;

    height: 100%;
    padding-left: $spaceXs;

    @include type('Copy');
    color: $colorPrimary1;

    &:hover {
      color: $colorPrimary2;
    }
  }

}
