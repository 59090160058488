@import '/src/styles-imports/shared';

.enter {
  opacity: 0;
}
.enterActive, .enterDone {
  opacity: 1;
  @include transition(opacity);
}
.exit {
  opacity: 1;
}
.exitActive, .exitDone {
  opacity: 0;
  @include transition(opacity)
}
