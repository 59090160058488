@import '/src/styles-imports/shared';

.bipolarDiagram {
  overflow: hidden;
  padding: 0 1px; // extra pixel in case a bar has to be drawn at +1
  line-height: 0;
  svg {
    overflow: initial;
  }
  .text {
    @include type('Hint');
    fill: $colorGrey2;
    transform: translate(0, -2px); // prevent comma separator from being hidden
  }
  .animate {
    transition: cx .3s ease-in-out, width .3s ease-in-out, x .3s ease-in-out;
  }
}
