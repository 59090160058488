
/* PAGE & GRID */
.bluBasicGrid {
  @include basicGrid;
}
.bluBasicGridNoMargins {
  @include basicGridNoMargins;
}

/* GRID CONTENT */
.bluContent, .bluContent444 {
  grid-column: 5 / 9;
}
.bluContent464 {
  grid-column: 5 / 9;
}

.bluContent466 {
  grid-column: 5 / 9;
}

.bluContent664 {
  grid-column: 4 / 10;
}

.bluContent666 {
  grid-column: 4 / 10;
}

.bluContent686 {
  grid-column: 4 / 10;
}

.bluContentBreakout {
  grid-column: 2 / 12;
}


@include mqSmaller($bpL) {
  .bluContent464, .bluContent466, .bluContent664, .bluContent666 {
    grid-column: 4 / 10 !important;
  }
  .bluContent686  {
    grid-column: 3 / 11 !important;
  }

  .bluContentBreakout {
    grid-column: start / end !important;
  }
}
@include mqSmaller($bpM) {
  .bluContent, .bluContent444, .bluContent464, .bluContent664 {
    grid-column: 3 / 7 !important;
  }
  .bluContent466, .bluContent666, .bluContent686 {
    grid-column: 2 / 8 !important;
  }
  .bluContentBreakout {
    grid-column: start / end !important;
  }
}

@include mqSmaller($bpS) {
  .bluContent, .bluContent444, .bluContent464,
  .bluContent466, .bluContent664, .bluContent666,
  .bluContent686 {
    grid-column: start / end !important;
  }
}


/* DIALOG */
.bluDialogControls {
  display: flex;
  justify-content: space-between;

  margin-top: $spaceM;
}
