@import '/src/styles-imports/shared';
$strokeWidth: 0.15;
$strokeWidthHover: 0.5;
$labelWidth: 160;

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0 !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
}
.exit {
  opacity: 1 !important;
}
.exitActive, .exitDone {
  opacity: 0 !important;
}

/* SPIDERDIAGRAMNEXT */
.spiderDiagramNext {
  overflow: hidden;

  /* LABELS */
  .labels {
    position: relative;
    margin-top: space('S');

  }
  .textLabel {
    cursor:pointer;

    position: absolute;
    padding: 10px;
    // center and some rather random offset
    top: -19px;
    left: calc(50% + 6px);
    width: #{$labelWidth}px;

    @include type('Hint');
    // elipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include transition(opacity, color);

    &:hover, &.hover {
      color: $colorPrimary1;
    }

    // RESPONSIVE: HIDE text labels on M or Smaller
    @include mqSmaller($bpM) {
      opacity: 0;
    }
  }

  .valueLabel {
    cursor:pointer;

    position: absolute;
    // center and some rather random offset
    padding: 4px;
    top: -11px;
    left: calc(50% + 10px);
    border-radius: 4px;

    @include type('Hint');
    max-width: #{$labelWidth}px + 25px;
    // elipsis
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    background: $colorWhite;
    box-shadow: $shadowDark;

    @include transition(opacity);

    .value {
      display: inline-block;
      color: $colorPrimary1;
    }

    .text {
      display: none;
      margin-left: 5px;

      // RESPONSIVE: SHOW text labels on M or smaller
      @include mqSmaller($bpM) {
        display: inline-block;
      }
    }

  }


  /* SVG */
  svg {
    width: 270px;
    height: 270px;
    display:block;
    margin: space('S') auto space('Xs');

    /* gridLine */
    line.gridLine {
      stroke-width: $strokeWidth;
      stroke: $colorGrey4;
      @include transitionLong(stroke, stroke-width);
    }
    line.gridLine.hover {
      cursor: pointer;
      stroke: $colorPrimary1;
      stroke-width: $strokeWidthHover;
      z-index: 1; // otherwise gets weird overlay artefacts at svg viewport origin [10.5, 10.5]
    }

    /* gridCircle */
    circle.gridCircle {
      stroke-width: $strokeWidth;
      stroke: $colorGrey4;
      fill: transparent;
      &.zero {
        stroke: $colorGrey3;
      }
    }

    /* gridNumber */
    text.gridNumber {
      cursor:pointer;
      font-size: 1.2px;
      fill: $colorGrey2;
      @include transition(fill, font-weight);
    }
    text.gridNumber.hover, text.gridNumber:hover {
      fill: $colorPrimary1;
    }

    /* dataPoint */
    circle.dataPoint {
      pointer-events:none;

      fill: $colorPrimary1;
      stroke: $colorPrimary1;
      stroke-width: 0.2;
      @include transition(stroke, stroke-width);
    }
    circle.dataPoint.hover, circle.dataPoint:hover, circle.dataPointHoverArea:hover + circle.dataPoint {
      fill: $colorPrimary2;
      stroke: $colorPrimary2;
      stroke-width: 0.6;
    }
    circle.dataPointHoverArea {
      cursor: pointer;
      fill: transparent;
      stroke: transparent;
    }

    /* dataLine */
    .dataLine {
      stroke-width: $strokeWidth;
      stroke: $colorPrimary1;
      fill:none;
    }

    .dataLineSecondary {
      stroke-width: $strokeWidth;
      stroke: $colorGrey2;
      fill:none;
    }

    /* dataRange */
    .dataRange {
      cursor:pointer;
      fill:$colorPrimary1;
      fill-rule: evenodd;
      opacity: 0.2;
      @include transition (opacity);
    }
    .dataRange:hover {
      opacity: 0.35;
    }

  }
}



:export {
  strokeWidth: $strokeWidth;
  strokeWidthHover: $strokeWidthHover;
  labelWidth: $labelWidth;
}
