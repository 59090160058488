@import '/src/styles-imports/shared';

/* ProgressBarMock */
.progressBarMock {
  .progressBar {
    position: relative;
    width:100%;
    height: $progressBarHeightLarge;
    background-color: $colorGrey4;
    border-radius: 30px;
  }

  .progressLabel {
    padding-bottom: space('Xs');

    @include type('LabelL');
    color: $colorPrimary1;
    text-align: center;

    &::after {
      content: ' %';
    }
  }

  .progressIndicator {
    position:absolute;
    width: 100%;
    height: $progressBarHeightLarge;
    background-color: $colorPrimary1;
    border-radius: 30px;

    animation-name: scaleTo100;
    animation-timing-function: ease-in-out;

    &.stopAt90 {
      width: 90%;
      animation-name: scaleTo90;
    }
  }
}

@keyframes scaleTo100 {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

@keyframes scaleTo90 {
  0% {
    width: 0;
  }
  100% {
    width: 90%;
  }
}
