@import '/src/styles-imports/shared';

.rmpReport {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: $colorWhite;
  left: 0;
  top:0;
  z-index: 9;

  animation: slideUp .3s ease-in-out;

  .header {
    @include type('LabelL');
    color: $colorPrimary1;
    height: 60px;
    padding: 20px 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .close {
      @include iconWithBackgroundGrey;
    }
  }
  .bodyContainer {
    height: calc(100vh - 60px);
    overflow-y: auto;
  }
  .body {
    @include basicGrid;
    .main {
      grid-column: 4/10;
      padding: 48px 0;
      color: $colorGrey1;
      .mTitle {
        @include type('M');
      }

      .sTitle {
        @include type('S');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xs');
      }
      .contents {
        margin-top: space('S');
        a {
          display: block;
          margin-top: space('Xs');
        }
      }
      .generalInfo {
        margin-top: space('L');
      }
      .classifyResults {
        margin-top: space('M');
        .diagram {
          margin-top: space('S');
          .headerLabels {
            display: flex;
            justify-content: space-between;
            text-align: left;
            span {
              @include type('LabelL');
              color: $colorPrimary1;
              flex: 1;
              &.center {
                color: $colorGrey1;
                text-align: center;
              }
              &.right {
                text-align: right;
              }
            }
          }
          img {
            width: 100%;
            height: auto;
            margin-top: space('Xs');
          }
          .footerLabels {
            @include type('Copy');
            display: flex;
            justify-content: space-between;
            margin-top: space('Xxs');
            text-align: center;
            span {
              flex: 1;
            }
          }
        }
      }
      .quickResults {
        margin-top: space('L');
        .subBlock {
          margin-top: space('S');
          .title {
            @include type('LabelL');
          }
          .links {
            display: flex;
            flex-wrap: wrap;
            margin-top: space('Xxs');
            a {
              margin-right: space('S');
              margin-top: space('Xs');
            }
          }
        }
      }
      .detailedResults {
        margin-top: space('L');
        .dimension {
          margin-top: space('M');
          .title {
            @include type('Xs');
          }
          .description {
            @include type('Copy');
            margin-top: space('Xxs');
          }
          .diagramBlock {
            margin-top: space('S');
            .diagramHeader {
              display: flex;
              justify-content: space-between;
              .left {
                @include type('Copy');
              }
              .right {
                @include type('Copy');
                color: $colorPrimary1;
              }
            }
            .diagram {
              margin-top: space('Xs');
            }
            .underTitles {
              @include type('Hint');
              display: flex;
              justify-content: space-between;
              width: 100%;
              box-sizing: border-box;
              margin-top: space('Xxs');
              color: $colorGrey2;
              .blue {
                color: $colorPrimary1;
              }
            }
          }
          .lastBlock {
            margin-top: space('S');
            .row {
              padding: 16px 0;
              border-top: 1px solid $colorGrey3;
              &:last-child {
                border-bottom: 1px solid $colorGrey3;
              }
              .label {
                @include type('LabelL');
              }
              .value {
                @include type('Copy');
                margin-top: space('Xxs');
              }
            }
          }
        }
        .dimension:first-child {
          margin-top: space('Xs');
        }
      }
      .callOutContainer {
        margin-top: space('S');
        .triangle {
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 11px solid $colorPrimary4;
          margin: 0 auto;
        }
        .callOut {
          background-color: $colorPrimary4;
          padding: 10px 16px;
          border-radius: 10px;
          .summary {
            @include type('LabelL');
            color: $colorGrey1;
          }
          .details {
            @include type('Copy');
            color: $colorGrey1;
            margin-top: 10px;
          }
        }
      }
      .copyright {
        margin-top: space('L');
        text-align: center;
        span {
          @include type('Hint');
          color: $colorPrimary1;
          cursor: pointer;
        }
      }
    }
  }

  @include mqSmaller($bpM) {
    .body {
      .main {
        grid-column: 2/8;
      }
    }
  }
  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
    .body {
      .main {
        grid-column: start/end;
        .quickResults {
          .links {
            flex-direction: column;
          }
        }
      }
    }
  }
}
