@import '/src/styles-imports/shared';

/* NextSteps */
.nextSteps {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .blue {
    color: $colorPrimary2;
  }
}
