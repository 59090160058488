@import '/src/styles-imports/shared';

/* QuestionBubbles */
.questionBubbles {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  & > div:not(:first-child) {
    margin-top: $spaceXs;
  }
  .radioButtonGroup {
    display: inline-flex;
    flex-direction: column;

    .hints {
      display: flex;
      justify-content: space-between;
      padding-bottom: $spaceXxs;
      gap: $spaceS;

      & > span {
        flex: 1;
        width: 70px; // counts as min-width
        @include type('Hint');
        word-break: break-word;

        &:last-child {
          text-align: right;
        }
      }
    }

    .dynamicHint {
      height: 20px;

      opacity: 0;
      margin-top: 10px;
      color: $colorPrimary1;
      @include type('LabelM');
      @include transition(opacity);

      &.visible {
        opacity: 1;
      }
    }
  }
}
