@import '/src/styles-imports/shared';

.enter {
  transform: translateX(317px);
}
.enterActive, .enterDone {
  transform: translateX(0);
  @include transition(transform);
}
.exit {
  transform: translateX(0);
}
.exitActive, .exitDone {
  transform: translateX(317px);
  @include transition(transform)
}  

.sidePanel {
  left: 0;

  .container {
    right: 0;
  }
}
