@import '/src/styles-imports/shared';

/* PAYMENTCYCLECHANGE */
.paymentCycleChange {
  @include dialogIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
