@import '/src/styles-imports/shared';

/* LEGAL */
.legal {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: zIndex('Modal');
  background-color: $colorWhite; // not needed since ModalWrapper
}

.header {
  position: sticky;
  top: 0;
  padding: 20px 60px;
  height: $headerHeight;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid $colorGrey4;
  display: flex;
  justify-content: space-between;

  @include type('LabelL');
  color: $colorPrimary1;

  background-color: $colorWhite;

  @include mqSmaller($bpS) {
    padding: 20px 16px;
  }

  .close {
    cursor: pointer;
  }
}

.contentGrid {
  padding-top: $spaceL;
  padding-bottom: space('L');

  @include basicGrid;

  .content {
    @include mqLargerEqual($bpM) {
      grid-column: 4/10;
    }

    @include mqSmaller($bpM) {
      grid-column: 2/8;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }
}
