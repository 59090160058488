@import '/src/styles-imports/shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0;
  transform: translateY(-5px);
}
.enterActive, .enterDone {
  opacity: 1;
  transform: translateY(0px);
}
.exit {
  opacity: 1;
  transform: translateY(0px);

  // color chip red on leave, independent from hover state for touch devices
  border-color: $colorDanger1 !important;
  color: $colorDanger1 !important;
  svg path { fill: $colorDanger1 !important; }
}
.exitActive, .exitDone {
  opacity: 0;
  transform: translateY(-5px);

  // color chip red on leave, independent from hover state for touch devices
  border-color: $colorDanger1 !important;
  color: $colorDanger1 !important;
  svg path { fill: $colorDanger1 !important; }
}


/* hotChips */
.hotChips {

  .label {
    margin-bottom: space('Xs');
    @include type('LabelL');
  }

  .hint {
    margin-top: space('Xxs');
    @include type('Hint');
    color: $colorGrey2;

    &.error {
      color: $colorDanger1;
    }
  }

  .flyoutContainer {
    position: relative;
  }
  .flyout {
    position: absolute;

    box-sizing: border-box;
    padding: space('Xs');
    top: space('Xxs') + 2px; // move down so it completely overlaps chips
    left: 0;
    width: 100%;

    border-radius: $borderRadius;
    background-color: white;
    filter: $dropShadow;

    overflow: hidden;
    z-index: zIndex('Buffer3');

    @include animation(slideUp);
  }

  .chips {
    padding-top: space('Xxs');

    > div /*transitinogroup*/ > div /*chips*/ {
      margin-top: space('Xs');
      margin-right: space('Xxs');
    }
  }

}


@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
