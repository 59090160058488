@import '/src/styles-imports/shared';

.adminUserGroup {
  @include basicGridNoMargins;
  .main {
    grid-column: start/mid;
    color: $colorGrey1;
    .title {
      @include type('Xxs');
    }
    .description {
      @include type('Copy');
      margin-top: space('Xs');
      .descRow {
        display: flex;
        margin-top: space('Xxs');
        span:last-child {
          padding-left: space('Xxs');
        }
      }
    }

    .profileSettings {
      margin-top: space('L');

      .checkBoxes {
        & > label {
          margin-top: space('Xs');
        }
      }
    }

    @include mqSmaller($bpM) {
      grid-column: start/7;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }
}
