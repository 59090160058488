@import '/src/styles-imports/shared';

/* StripeForm */
.stripeForm {
  @include dialogIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: space('S');
  }

  .billingCycle {
    margin-top: space('S');

    & > label {
      margin-top: space('Xs');
    }
  }

}
