@import '/src/styles-imports/shared';

/* SegmentedLineDiagram */
.segmentedLineDiagramNext {
  width: 100%;
  height: 20px;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .maskWrapper { 
    transform: translateX(-4px);
  }

  .diagram {
    fill: $colorGrey3;
    &.blue {
      fill: $colorPrimary1;
    }
    @include diagramFills();
    .animate {
      transition: width .3s ease-in-out;
    }
  }

  .referenceLine {
    width: 4px;
    height: 20px;
    &.blue {
      fill: $colorPrimary1;
    }
    @include diagramFills();
  }
  .fillColor {
    fill: white;
  }
}
