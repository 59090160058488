@import '/src/styles-imports/shared';

.externalProfile {
  @include basicGrid;
  .loginContainer {
    animation: signUpSlideIn .300s ease-out;
    margin-top: 176px;
    grid-column: 5/9;

    @include mqSmaller($bpM) {
      grid-column: 3/7;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: 92px;
    }

    .mTitle {
      @include type('M');
    }
    .copyStrong {
      @include type('CopyStrong');
      margin-top: space('Xs');
    }
    .copy {
      @include type('Copy');
      margin-top: space('S');
    }
    .password {
      margin-top: space('S');
    }
    button {
      display: block;
      margin-left: auto;
      margin-top: space('S');
    }
  }
}

@keyframes signUpSlideIn {
  0% {
    margin-top: 150px;;
    opacity: 0;
  }
  100% {
    margin-top: 176px;
    opacity: 1;
  }
}
