@import '/src/styles-imports/shared';

.mindfulnessJourneyScore {
  text-align: center;
  .diagram {
    position: relative;
    width: 171px;
    height: 126px;
    margin: 0 auto;
    .vector {
      position: absolute;
      bottom: 0;
      left: 25%;
      transform-origin: 52% 80%;
    }
  }

  .title {
    @include type('Xxs');
    margin-top: space('Xs');
    span {
      color: $colorPrimary1;
    }
  }
  .legend {
    margin-top: space('S');
    display: flex;
    .blue, .gray {
      .rect {
        width: 16px;
        height: 3px;
        background-color: $colorPrimary1;

        display: inline-block;
        vertical-align: middle;
      }
      span {
        padding-left: space('Xxs');
      }
    }
    .gray {
      margin-left: 22px;
      .rect {
        background-color: $colorGrey3;
      }
    }
  }
}
