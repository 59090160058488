@import '/src/styles-imports/shared';

$menuItemHeight: 48px;

:export {
  menuItemHeight: $menuItemHeight;
}

//TODO
ul {
  margin: 0;
  padding: 0;
}

/* MAINNAV */
.mainNav {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-right: 1px solid $colorGrey4;
  display: flex;
  flex-direction: column;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  header {
    box-sizing: border-box;
    min-height: $headerHeight;

    display: flex;
    justify-content: space-between;
    align-items: center;


    .logo {
      padding-left: 12px;
    }

    .menuCloseIcon {
      @include iconWithBackground($colorGrey4);
      margin-right: 5px;
    }
  }

  .content {
    position: relative;
    max-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding-bottom: $spaceXxs;
  }

  footer {
    flex: 1;
    display: flex;
    align-items: flex-end;
    .poweredBy {
      height: $spaceXl;
      display: flex;
      > * {
        margin-left: $spaceXs;
      }
    }
  }

  .menuItem {
    user-select: none;
    cursor: pointer;

    display: block;
    box-sizing: border-box;
    min-height: $menuItemHeight;
    padding: 15px 0px 0px 20px;

    text-decoration: none;
    color: $colorGrey1;
    @include type("Copy");

    overflow: hidden;
    background-color: $colorWhite;

    @include transition(background-color);

    &:hover {
      background-color: $colorPrimary4;
    }
    &.highlight {
      background-color: $colorPrimary4;
    }
    &.small {
      @include type("Hint");
    }
  }
}
