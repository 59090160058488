@import '/src/styles-imports/shared';

/* ProfileSummary */
.profileSummary {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .buttons {
    margin-top: space('S');
    display: flex;

    button {
      margin-right: space('Xs');
    }
  }

  .addToVacanciesContent {
    & > div:last-child {
      margin-top: space('S');
    }
  }
}
