@import '/src/styles-imports/shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0 !important;
  transform: translateY(-20px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  opacity: 0 !important;
  transform: translateY(-30px) !important;
  @include transition(transform, opacity);
}

/* HelpMenu */
.helpMenu {
  height: 32px;
  @include transition(fill);

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  &:hover {
    cursor: pointer;

    & > svg > path {
      fill: $colorPrimary2;
    }
  }

  .menu {
    box-sizing: border-box;
    width: 257px;
    position: fixed;
    padding: 18px 0;
    top: 60px;
    right: 61px;
    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: $colorWhite;
    z-index: zIndex('Modal');

    &:focus {
      outline: none;
    }

    .menuItem {
      height: 33px;
      overflow: hidden;
      padding: 15px 0px 0px 20px;

      color: $colorGrey1;
      @include type('Copy');
      cursor: pointer;

      @include transition(background-color);

      &:hover {
        background-color: $colorPrimary4;
      }
    }

    @include mqSmaller($bpS) {
      width: calc(100% - #{$spaceM});
      box-sizing: border-box;
      top: 60px;
      right: $spaceXs;
    }
  }
}
