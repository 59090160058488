@import '/src/styles-imports/shared';

/* QUESTIONDRAGDROP */
.questionDragDrop {

  @include basicGrid;

  @include transition(transform, opacity);

  /* INTERACTION */

  /* MODIFIERS */
  &.closing {
    transform: translateY(-30px);
    opacity: 0;
  }
  &.valid {
    .button {
      opacity: 1 !important;
    }
  }

  /* RESPONSIVE */

  @include mqSmaller($bpM) {
    .container {
      grid-column: 2/8 !important;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end !important;
      padding-top: 4px;
    }
  }

  /* CHILDREN */
  .container {
    grid-column: 4/10;
    text-align: center;
    padding-top: 35px;
    padding-bottom: 21px;

    .questionContainer {
      .question {
        @include type('S');
      }
      .questionDescription {
        max-width: 750px;
        margin: auto;
        margin-top: $spaceXs;
  
        @include type('CopyStrong');
      }
      .sliderBlock {
        position: relative;
        width: 100%;
        margin: 32px auto 0;
        text-align: left;
        .labels {
          margin-top: space('S');
          display: flex;
          justify-content: space-between;
          @include type('Copy')
        }

        @include mqSmaller($bpS) {
          margin-top: space('S');
        }
      }
      .button {
        opacity: 0;
        @include transition(opacity);
      }
      button {
        display: block;
        margin: space('L') auto 0;
      }
      .hint {
        @include type('Hint');
        margin-top: space('L');
        text-align: center;
        color: $colorGrey2;

        opacity: 0;
        @include transition(opacity);

        &.show {
          opacity: 1;
        }

        @include mqSmaller($bpS) {
          margin-left: space('S');
          margin-right: space('S');
        }
      }
    }
  }

}
