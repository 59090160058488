@import '/src/styles-imports/shared';

/* STANDLONEHEADER */
.standaloneHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;
  height: 100%;
  width: 100%;
  @include gridMarginsAsPaddings;

  border-bottom: 1px solid $colorGrey4;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .left {
    display: flex;
  }
  .right {

  }
}
