@import '/src/styles-imports/shared';

$valueMinWidth: 28px;
$valueBigMinWidth: 70px;

/* SteppedScaleResultItem */
.steppedScaleResultItem {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @include transition(background-color);
  @include transition(fill);

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .header, .headerWithNoSubDimensions {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
    .left {
      display: flex;
      width: calc(50% - #{$spaceXxs});
    }
    .right {
      width: calc(50% - #{$spaceXxs});
      display: flex;
      align-items: center;

      margin-left: space('Xs');
      .points {
        min-width: $valueMinWidth;
        color: $colorPrimary1;
        white-space: nowrap;

        padding-right: $spaceXxs;

        &.big {
          min-width: $valueBigMinWidth;
        }
      }

      .user {
        display: flex;
        align-items: center;
        .name {
          @include type('LabelL');
        }
      }
    }

    @include mqSmaller($bpM) {
      position: relative;
      flex-direction: column-reverse;
      align-items: flex-start !important;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        flex-direction: row-reverse;;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 0;
        .points {
          margin-right: space('Xs');
          padding-right: 0;
        }
        .name {
          padding-left: 0;
        }
      }
    }
  }

  .headerWithNoSubDimensions {
    padding: space('Xs') space('M');
    @include transition(background-color);
    @include transition(fill);

    $collapsibleArrowWidth: 38px;
    $leftWidth: calc((100% - #{$collapsibleArrowWidth}) / 2);
    .left {
      width: $leftWidth;
    }
    .right {
      width: calc(100% - #{$leftWidth});
    }

    @include mqSmaller($bpM) {
      padding: space('Xxs') space('Xs') space('Xs');

      .left, .right {
        width: 100%;
      }
      .right {
        position: relative;
        .points {
          &:not(.forUser) {
            position: absolute;
            right: $spaceXxs;
          }
          &.forUser {
            margin-right: 0;
          }
        }

        div[data-test='InfoTitle'] > span {
          $infoIconSize: 14px;
          max-width: calc(100% - #{$spaceXs} - #{$valueMinWidth} - #{$infoIconSize});
        }
      }
    }

    &:hover {
      cursor: pointer;
      background-color: $colorPrimary4;
      @include diagramGapColor($colorPrimary4);
    }
  }

  .body {
    .subItemRow {
      display: flex;
      align-items: center;
      padding: space('Xs') space('M') space('Xs') space('Xl');
      cursor: pointer;
      @include transition(background-color);
      @include transition(fill);

      &:hover {
        background-color: $colorPrimary4;
        @include diagramGapColor($colorPrimary4);
      }
      &:focus {
        background-color: $colorPrimary3;
        outline: none;
        border: none;
        @include diagramGapColor($colorPrimary3);
      }

      .right {
        width: 100%;
        margin-left: 18px;
      }
    }

    @include mqSmaller($bpM) {
      width: 100%;
      box-sizing: border-box;
      .subItemRow {
        flex-direction: column-reverse;
        padding: space('Xxs') space('M') space('Xs') space('M');
        .right {
          width: 100%;
          margin-left: 0;
          .infoTitle {
            margin-left: 0;
          }
        }
      }
    }
  }

  .infoTitle {
    align-items: flex-start;
    margin-left: 0;

    span {
      white-space: pre-wrap;
    }

    svg {
      margin-top: 3px;
    }

    @include mqSmaller($bpS) {
      margin-left: 0;
    }
  }

  .collapsibleHeader {
    padding: space('Xs') space('M') !important;
    @include transition(background-color);
    @include transition(fill);

    &:hover {
      background-color: $colorPrimary4;
      @include diagramGapColor($colorPrimary4);
    }

    &.active {
      background-color: $colorPrimary3;
      @include diagramGapColor($colorPrimary3);
    }

    @include mqSmaller($bpM) {
      padding: space('Xs') space('Xs') !important;

      div:last-child {
        svg {
          position: absolute;
          right: space('Xs');
          top: 19px;
        }
      }
    }
  }

}
