@import '/src/styles-imports/shared';

/* COMPONENT */
.jobs {
  @include dialogIntermission;

  margin-top: -40px;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  & > div:nth-child(2) {
    margin-top: space('S');
    overflow: hidden;
  }
}
