@import '/src/styles-imports/shared';

/* ASSESSMENTCAROUSEL */
.assessmentCarousel {
  .teaser {
    min-width: 100%;
    margin-right: space('Xs');

    & > div:first-child {
      height: 100%;
      & > div:first-child {
        height: 100%;
        & > div:first-child {
          height: 100%;
        }
      }
    }
  }
}
