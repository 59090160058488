@import '/src/styles-imports/shared';

/* INPUT CONTAINER */

.inputContainer {
  position:relative;
  width: 100%;

  &.sizeL {
    .label {
      @include type('CopyStrong');
    }
    .input {
      height: $inputHeightL;
      @include type('CopyStrong');
      &::placeholder {
        opacity: 1;
      }
    }
  }

  &.sizeResponsive {

    @include mqLargerEqual($bpM) {
      .label {
        @include type('CopyStrong');
      }
      .input {
        height: $inputHeightL;
        @include type('CopyStrong');
        &::placeholder {
          opacity: 1;
        }
      }
    }
  }
}

/* LABEL */
.label {
  position: absolute;

  pointer-events: none;
  color: $colorGrey1;
  height: $inputHeight;
  @include type('Copy');
  transform: translate(17px, 15px);
  transform-origin: 0 0;

  // transition
  @include transition(transform);
  &.small {
    transform: translate(17px, 3px) scale(calc($typeSizeHint / $typeSizeCopy));
  }
  &.disabled {
    color: $colorGrey2;
  }
}



/* INPUT */
.input {
  // remove iOS input top shadow
  appearance:none; // https://stackoverflow.com/questions/23211656/remove-ios-input-shadow

  height: 50px;
  width: 100%;
  min-width: 150px;
  box-sizing: border-box;
  padding: 16px 16px 0px 16px;

  color: $colorGrey1;
  caret-color: $colorPrimary1;
  @include type('Copy');

  border: 1px $colorGrey4 solid;
  border-bottom-width: 2px;
  border-radius: 2px;

  outline: none;

  background-color: $colorGrey4;

  // transitions
  @include transition(color, background-color, box-shadow, border-color);


  &:hover, &.hover {
    box-shadow: $shadowDark;
    border-color: $colorWhite;
    background-color: $colorWhite;
  }
  &:focus, &.focus {
    box-shadow: $shadowDark;
    border-bottom-color: $colorPrimary1;
    background-color: $colorWhite;
  }
  &:disabled {
    color: $colorGrey2;
    box-shadow: none;
    border-color: $colorGrey5;
    background-color: $colorGrey5;
  }
  &[placeholder] {
    text-overflow: ellipsis;
  }
  &.error {
    color: $colorDanger1;
    border-bottom-color: $colorDanger1;
  }
  &.noBorder {
    border-color: rgba(0,0,0,0);
  }
  &.placeholder {
    padding-top: 3px;
  }
  &.small {
    padding-top: 15px;
  }
}

//HINT
.hint {
  @include type('Hint');
  margin-top: space('Xxs');
  margin-left: space('Xs');
  color: $colorGrey2;
}

//ERROR MESSAGE
.errorMessage {
  @include type('Hint');
  margin-top: space('Xxs');
  margin-left: space('Xs');
  color: $colorDanger1;
}
