@import '/src/styles-imports/shared';

/* TOP BOTTOM X */
.topBottomX {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .divider {
    height: $spaceS;
  }

  .blue {
    color: $colorPrimary1;
  }

  .dimensionWrapper {
    display: flex;
    flex-direction: column;
    gap: $spaceM;
    margin: $spaceM 0;
    .dimension {
      .title {
        display: flex;
        gap: $spaceS;
        margin-bottom: $spaceXs;
        align-items: center;

        .label {
          .topLabel {
            display: flex;
            flex-direction: column;
            @include type('LabelL');
          }
          > :last-child {
            font-size: 16px;
          }
        }
        > :first-child {
          min-width: $spaceS;
          text-wrap: nowrap;
        }

        > :last-child {
          flex: 1;
          text-align: right;
          font-size: 14px;
          text-wrap: nowrap;
          align-self: end;
        }
      }

      .lowestHighestValues {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;

        margin-top: $spaceXxs;
        @include type('Hint');

        & > div {
          &:last-child {
            margin-left: 39px;
          }

          & > span:last-child {
            padding-left: 6px;
            color: $colorPrimary1;
          }
        }
      }
    }
  } 
}
