@import '/src/styles-imports/shared';

/* GarminBarDiagram */
.garminBarDiagram {
  width: 100%;
  box-sizing: border-box;
  line-height: 0;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .diagram {
    &.blue {
      fill: $colorPrimary1;
    }
    &.lightBlue {
      fill: rgb(164, 191, 248);
    }
    &.lightRed {
      fill: rgb(238, 166, 165);
    }
    &.red {
      fill: $colorDanger1;
    }
    &.grey {
      fill: $colorGrey4;
    }
    @include diagramFills();

    &.animate > * {
      transition: cx .3s ease-in-out, width .3s ease-in-out;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    margin-top: space('Xxs');

    @include type('LabelS');
  }
}
