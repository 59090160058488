@import '/src/styles-imports/shared';

/* Peer360MANAGE */
.peer360Manage {

  &.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: zIndex('Modal');
    background-color: $colorWhite;

    // HEADER
    .header {
      @include type('LabelL');
      color: $colorPrimary1;
      padding: 20px 60px;
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid $colorGrey4;
      display: flex;
      justify-content: space-between;
      .close {
        cursor: pointer;

        display: flex;
        align-items: center;

        height: 100%;
        padding-left: $spaceXs;

        @include type('Copy');
        color: $colorPrimary1;

        &:hover {
          color: $colorPrimary2;
        }
      }
    }

    .scrollableContent {
      height: calc(100vh - #{$headerHeight});
      overflow-y: auto;
      overflow-x: hidden;
      scroll-behavior: smooth;
    }

    .gridContent {
      @include basicGrid;
      margin-top: space('Xl') !important;
      margin-bottom: space('L');
    }
  }

  &:not(.modal) {
    .header {
      display: none;
    }

    .gridContent {
      @include basicGridNoMargins;
    }
  }

  .gridContent {
    .skeleton {
      margin-top: space('L');
    }

    .main {
      grid-column: 4/10;
      color: $colorGrey1;

      .preTitle {
        @include type('LabelL');
        margin-bottom: space('Xxs');
        color: $colorPrimary1;
      }

      .title {
        @include type('S');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xs');
      }

      // DYNAMIC PEER CONTENT
      .peerGroup {
        margin: 40px 0;
        .titleContainer {
          margin-bottom: 8px;
          display: flex;
          align-items: baseline;
          .title {
            @include type('Xxs');
          }
        }

        .buttonContainer {
          margin-top: 16px;
        }

        .selfCompleted {
          margin: 8px 0;
        }

        .peer {
          display: flex;
          align-items: center;
          margin: 16px 0;
          .icon {
            min-width: space('M');
            display: flex;
            align-items: center;

            .missing {
              fill: $colorGrey2;
              align-items: center;
            }
            .completed {
              width: 20px;
              height: 20px;
            }
          }
          .mail {
            @include ellipsis();
            flex-grow: 1;
          }
          .status {
            margin-left: space('Xs');
            white-space: nowrap;
            text-align: right;
            .peerEditIcon {
              width: $spaceXs;
              height: $spaceXs;
            }
            svg {
              margin-bottom: -2px;
              transition: fill 300ms ease;
            }
            :not(.inactive):hover {
              svg {
                fill: $colorPrimary2;
              }
            }
          }
        }

        .add {
          display: flex;
          align-items: center;
          margin-top: $spaceXs;

          color: $colorPrimary1;
          @include transition(color, fill);

          &:hover {
            cursor: pointer;
            color: $colorPrimary2;

            & > svg > path {
              fill: $colorPrimary2;
            }
          }

          &.disabled {
            pointer-events: none;
            & > span {
              color: $colorPrimary3;
            }
            & > svg > path {
              fill: $colorPrimary3;
            }
          }

          & > svg {
            width: 14px;
            height: 14px;

            & > path {
              fill: $colorPrimary1;
            }
          }

          & > span {
            padding-left: $spaceXxs;
          }
        }
      }

      .bottom {
        display: flex;
        justify-content: flex-end;
        gap: space('Xs');
        margin-top: space('L');
      }

      .completedAt {
        color: $colorGrey2;
        fill: $colorGrey2;
      }
    }
  }

  @include mqSmaller($bpM) {
    .gridContent {
      .main {
        grid-column: 2/8;
      }
    }
  }

  @include mqSmaller($bpS) {
    .header {
      padding: 20px 16px;
    }
    .gridContent {
      margin-top: space('M') !important;
      margin-bottom: space('Xxl') !important;
      .main {
        grid-column: start/end;
      }
    }
    .bottom {
      flex-direction: column;
      align-items: flex-end;
      gap: 0;
    }
  }

  /* FLOATERS */
  .reminderToast p {
    margin: 8px 0;
  }
}
