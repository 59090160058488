@import '/src/styles-imports/shared';

.inputFile {
  @include type('Copy');

  label {
    color: $colorPrimary1;
    user-select: none;
    outline: none;
    cursor: pointer;
  }

  input {
    display:none;
  }
}

.inputAlt {
  display: flex;

  svg {
    width: 22px;
    height: 22px;
    transform: translate(0, -1px);
  }

  span {
    flex-grow: 1;
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.unselected {
  label {
    @include transition(background-color, color, border-color);

    display: inline-block;
    border: 1px $colorPrimary1 solid;
    border-radius: 22px;
    padding: 7px 20px 8px 20px;

    &:hover {
      background: $colorPrimary3;
      border: 1px rgba(0, 0, 0, 0) solid;
    }

    &:focus {
      background: $colorPrimary3;
      border: 1px $colorPrimary1 solid;
    }

    span {
      svg {
        width: 16px;
        height: 16px;
        transform: translate(0, 3px);
      }
    }
  }
}

.selected {
  label {
    @include type('LabelL');
    background: $colorGrey5;
    display: block;
    padding: 15px;
  }
}

.error {
  color: $colorDanger1;
  margin-top: 20px;
}

.disabled {
  label {
    cursor: not-allowed;
  }

  &.unselected {
    label {
      border-color: $colorPrimary3;
      background: $colorPrimary3;
    }
  }

  &.selected {
    label {
      color: $colorGrey2;

      svg path {
        fill: $colorGrey2;
      }
    }
  }
}
