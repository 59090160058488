@import '/src/styles-imports/shared';

/* JOB */
.job {
  @include dialogIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
