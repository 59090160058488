@import '/src/styles-imports/shared';

.detailPageSkeleton {
  width: 100%;
  grid-column: start/end;
  display: flex;
  animation: loadAssessmentResult 1.5s infinite ease;

  .top {
    width: 100%;
    height: 24px;
    border-radius: 12px;
    background-color: $colorGrey4;
  }

  .left {
    width: 33%;
    .content {
      margin-top: space('S');
      .row {
        width: 100%;
        height: 14px;
        background-color: $colorGrey4;
        border-radius: 12px;
        margin-bottom: space('Xxs');

        &:nth-child(odd) {
          width: 72%;
        }
      }
    }
  }

  .right {
    width: 66%;
    margin-left: 46px;

    @include mqSmaller($bpM) {
      margin-left: space('Xs');
    }

    .contentBlock {
      margin-top: space('S');
      .row {
        width: 94%;
        height: 14px;
        background-color: $colorGrey4;
        border-radius: 12px;
        margin-bottom: space('Xxs');

        &:nth-child(odd) {
          width: 72%;
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    flex-direction: column;

    .left {
      width: 100%;
    }

    .right {
      width: 100%;
      margin-top: space('L');
      margin-left: 0;
    }
  }
}
