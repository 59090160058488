@import '/src/styles-imports/shared';

/* WELCOME */

.gridContainer {
  @include basicGrid;
  margin-top: space('L');
  margin-bottom: -(space('Xs'));

  .welcomeTitle {
    grid-column: start/end;

    @include type('S');
  }
}

.welcome {
  @include basicGrid;
  margin-top: space('M');
  height: 349px;
  border: 1px solid $colorGrey4;
  border-radius: 10px;
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  padding-left: space('M');
  box-sizing: border-box;
  // animation: slideUp $animationDuration $animationFunction;
  background-color: white;
  background-size: contain;

  @include mqSmaller($bpM) {
    background-color:rgba(0, 0, 0, 0);
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .welcomeAltImage {
    display: none;
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    width: 100%;
    grid-column: 1/13;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: white;
  }
  .content {
    grid-column: 1/5;
    margin: auto 0;
    .title {
      @include type('S');
      color: $colorGrey1;
    }
    .description {
      @include type('Copy');
      color: $colorGrey1;
      margin-top: space('Xs');
    }
    button {
      margin-top: space('S');
    }
  }

  @include mqSmaller($bpM) {
    background-image: none !important;
    padding-left: 0;
    border: none;
    grid-column: start/end;
    height: auto;
    .welcomeAltImage {
      display: block;
      height: 208px;
      grid-column: start/end;
    }
    .content {
      grid-column: start/end;
      margin-top: space('Xs');
    }
  }

  @include mqSmaller($bpS) {
    .welcomeAltImage {
      display: block;
      height: 216px;
      background-size: cover;
    }
  }
}
