@import '/src/styles-imports/shared';

.userAssessmentsResults {
  width: 100%;
  height: 100%;
  background-color: $colorGrey6;
  color: $colorGrey1;

  @include mqSmaller($bpS) {
    min-height: calc(100vh - #{$headerHeight} - #{$footerHeightLarge});
  }

  .gridContainer {
    @include basicGrid;
    padding-bottom: space('M');
    padding-top: space('M');
  }

  .personalInfo {
    grid-column: 1/5;

    .subBlock {
      .profile {
        margin-bottom: space('M');
      }
    }

    .attachments {
      margin-bottom: $spaceM;
    }
  }

  .assessmentsInfo {
    grid-column: 5/13;

    .title {
      @include type('Xxs');
      margin-bottom: $spaceXs;
    }

    &.fullWidth {
      grid-column: start / end;

      @include mqSmaller($bpM) {
        grid-column: start / end;
      }
    }

    .assessmentsResult {
      display: flex;
      flex-direction: column;
      gap: $spaceXs;
      .assessmentsResultsContainer {
        display: flex;
        flex-direction: column;
        gap: $spaceXs;
      }
    }

    @include mqSmaller($bpM) {
      grid-column: 5/9;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: space('M');
    }
  }

}
