@import '/src/styles-imports/shared';

$checkboxSize: 36px;

/* AssessmentsList */
.assessmentsList {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .listRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: space('Xs');

    &:first-child {
      margin-top: space('S');
    }
    & > label {
      flex-basis: 50%;
    }
    & > span {
      flex-basis: 50%;

      padding-left: space('Xs');
      @include type('Copy');
      text-align: right;
    }

    @include mqSmaller($bpS) {
      flex-direction: column;
      align-items: flex-start;

      & > span {
        padding-top: space('Xxs');
        padding-left: calc(#{$checkboxSize} + #{$spaceXxs});
      }
    }
  }

  .grantManagementAccess {
    margin-top: $spaceXxs;
    margin-left: 44px;
  }
}
