@import '/src/styles-imports/shared';

.collapsiblePanel {
  width: 100%;
  .header {
    @include type('Copy');
    color: $colorGrey1;
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    svg.arrow {
      transform: scale(1);
      @include transition(transform);
      path {
        @include transition(fill);
      }
    }
    &.showBorder {
      border-top: 1px solid $colorGrey3;
    }
    &:hover {
      @include transition(color);
      color: $colorPrimary1 !important;

      svg.arrow path {
        fill: $colorPrimary1;
      }
    }
    &.expanded {
      color: $colorPrimary1 !important;
      svg.arrow {
        transform: scale(-1);
        path {
          fill: $colorPrimary1;
        }
      }
      &:hover {
        color: $colorPrimary2 !important;
        svg.arrow path {
          fill: $colorPrimary2;
        }
      }
    }

    svg.arrow {
      margin-left: 16px;
    }
    .arrow {
      margin-left: 8px;
    }
  }
  .body {
    @include type('Copy');
    color: $colorGrey1;
    opacity: 0;
    animation: load-body .3s ease-out;
  }
  .bodyExpanded {
    opacity: 1;
  }

  @keyframes load-body {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
