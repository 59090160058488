@import 'colors';
@import 'responsive';

/**
  - Montserrat
    - Regular 400
    - Semibold 600
    - Link https://fonts.google.com/specimen/Montserrat?selection.family=Montserrat:400,600
**/

$types: (
  "Xxl",
  "Xl",
  "L",
  "M",
  "S",
  "Xs",
  "Xxs",
  "CopyStrong",
  "LabelL",
  "LabelM",
  "LabelS",
  "LabelButton",
  "Copy",
  "CopySecondary",
  "Hint"
);

$typeFamily: "Montserrat", "DejaVu Sans", Verdana, -apple-system, sans-serif;

// Xxl
$typeFamilyXxl: $typeFamily;
$typeWeightXxl: 600;
$typeSizeXxl: 80px;
$typeLineHeightXxl: 88px;
$typeSizeXxlSmaller: 50px;
$typeLineHeightXxlSmaller: 58px;

// Xl
$typeFamilyXl: $typeFamily;
$typeWeightXl: 600;
$typeSizeXl: 60px;
$typeLineHeightXl: 68px;
$typeSizeXlSmaller: 40px;
$typeLineHeightXlSmaller: 48px;

// L
$typeFamilyL: $typeFamily;
$typeWeightL: 600;
$typeSizeL: 48px;
$typeLineHeightL: 60px;
$typeSizeLSmaller: 32px;
$typeLineHeightLSmaller: 40px;

// M
$typeFamilyM: $typeFamily;
$typeWeightM: 600;
$typeSizeM: 34px;
$typeLineHeightM: 44px;
$typeSizeMSmaller: 26px;
$typeLineHeightMSmaller: 32px;

// S
$typeFamilyS: $typeFamily;
$typeWeightS: 600;
$typeSizeS: 24px;
$typeLineHeightS: 32px;
$typeSizeSSmaller: 20px;
$typeLineHeightSSmaller: 28px;

// Xs
$typeFamilyXs: $typeFamily;
$typeWeightXs: 600;
$typeSizeXs: 20px;
$typeLineHeightXs: 28px;
$typeSizeXsSmaller: 18px;
$typeLineHeightXsSmaller: 28px;

// Xxs
$typeFamilyXxs: $typeFamily;
$typeWeightXxs: 600;
$typeSizeXxs: 18px;
$typeLineHeightXxs: 28px;
$typeSizeXxsSmaller: 16px;
$typeLineHeightXxsSmaller: 24px;

// CopyStrong
$typeFamilyCopyStrong: $typeFamily;
$typeWeightCopyStrong: 400;
$typeSizeCopyStrong: 16px;
$typeLineHeightCopyStrong: 24px;
$typeSizeCopyStrongSmaller: 16px;
$typeLineHeightCopyStrongSmaller: 24px;

// Label L
$typeFamilyLabelL: $typeFamily;
$typeWeightLabelL: 600;
$typeSizeLabelL: 14px;
$typeLineHeightLabelL: 20px;
$typeSizeLabelLSmaller: 14px;
$typeLineHeightLabelLSmaller: 20px;

// Label M
$typeFamilyLabelM: $typeFamily;
$typeWeightLabelM: 600;
$typeSizeLabelM: 12px;
$typeLineHeightLabelM: 16px;
$typeSizeLabelMSmaller: 12px;
$typeLineHeightLabelMSmaller: 16px;

// Label S
$typeFamilyLabelS: $typeFamily;
$typeWeightLabelS: 600;
$typeSizeLabelS: 10px;
$typeLineHeightLabelS: 14px;
$typeSizeLabelSSmaller: 10px;
$typeLineHeightLabelSSmaller: 14px;

// LabelButton
$typeFamilyLabelButton: $typeFamily;
$typeWeightLabelButton: 400;
$typeSizeLabelButton: 14px;
$typeLineHeightLabelButton: 14px;
$typeSizeLabelButtonSmaller: 14px;
$typeLineHeightLabelButtonSmaller: 14px;

// Copy
$typeFamilyCopy: $typeFamily;
$typeWeightCopy: 400;
$typeSizeCopy: 14px;
$typeLineHeightCopy: 20px;
$typeSizeCopySmaller: 14px;
$typeLineHeightCopySmaller: 20px;

//CopySecondary
$typeFamilyCopySecondary: $typeFamily;
$typeWeightCopySecondary: 400;
$typeSizeCopySecondary: 14px;
$typeLineHeightCopySecondary: 20px;
$typeSizeCopySecondarySmaller: 14px;
$typeLineHeightCopySecondarySmaller: 20px;
$typeColorCopySecondary: $colorGrey2;

//CopySecondary
$typeFamilyCopySecondary: $typeFamily;
$typeWeightCopySecondary: 400;
$typeSizeCopySecondary: 14px;
$typeLineHeightCopySecondary: 20px;
$typeSizeCopySecondarySmaller: 14px;
$typeLineHeightCopySecondarySmaller: 20px;
$typeColorCopySecondary: $colorGrey2;

// Hint
$typeFamilyHint: $typeFamily;
$typeWeightHint: 400;
$typeSizeHint: 12px;
$typeLineHeightHint: 16px;
$typeSizeHintSmaller: 12px;
$typeLineHeightHintSmaller: 16px;

@mixin type($typeId) {
  @if $typeId == "Xxl" {
    font-family: $typeFamilyXxl;
    font-weight: $typeWeightXxl;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXxl;
      line-height: $typeLineHeightXxl;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXxlSmaller;
      line-height: $typeLineHeightXxlSmaller;
    }
  } @else if $typeId == "Xl" {
    font-family: $typeFamilyXl;
    font-weight: $typeWeightXl;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXl;
      line-height: $typeLineHeightXl;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXlSmaller;
      line-height: $typeLineHeightXlSmaller;
    }
  } @else if $typeId == "L" {
    font-family: $typeFamilyL;
    font-weight: $typeWeightL;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeL;
      line-height: $typeLineHeightL;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLSmaller;
      line-height: $typeLineHeightLSmaller;
    }
  } @else if $typeId == "M" {
    font-family: $typeFamilyM;
    font-weight: $typeWeightM;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeM;
      line-height: $typeLineHeightM;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeMSmaller;
      line-height: $typeLineHeightMSmaller;
    }
  } @else if $typeId == "S" {
    font-family: $typeFamilyS;
    font-weight: $typeWeightS;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeS;
      line-height: $typeLineHeightS;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeSSmaller;
      line-height: $typeLineHeightSSmaller;
    }
  } @else if $typeId == "Xs" {
    font-family: $typeFamilyXs;
    font-weight: $typeWeightXs;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXs;
      line-height: $typeLineHeightXs;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXsSmaller;
      line-height: $typeLineHeightXsSmaller;
    }
  } @else if $typeId == "Xxs" {
    font-family: $typeFamilyXxs;
    font-weight: $typeWeightXxs;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeXxs;
      line-height: $typeLineHeightXxs;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeXxsSmaller;
      line-height: $typeLineHeightXxsSmaller;
    }
  } @else if $typeId == "CopyStrong" {
    font-family: $typeFamilyCopyStrong;
    font-weight: $typeWeightCopyStrong;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeCopyStrong;
      line-height: $typeLineHeightCopyStrong;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeCopyStrongSmaller;
      line-height: $typeLineHeightCopyStrongSmaller;
    }
  } @else if $typeId == "LabelL" {
    font-family: $typeFamilyLabelL;
    font-weight: $typeWeightLabelL;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeLabelL;
      line-height: $typeLineHeightLabelL;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLabelLSmaller;
      line-height: $typeLineHeightLabelLSmaller;
    }
  } @else if $typeId == "LabelM" {
    font-family: $typeFamilyLabelM;
    font-weight: $typeWeightLabelM;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeLabelM;
      line-height: $typeLineHeightLabelM;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLabelMSmaller;
      line-height: $typeLineHeightLabelMSmaller;
    }
  } @else if $typeId == "LabelS" {
    font-family: $typeFamilyLabelS;
    font-weight: $typeWeightLabelS;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeLabelS;
      line-height: $typeLineHeightLabelS;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLabelSSmaller;
      line-height: $typeLineHeightLabelSSmaller;
    }

  } @else if $typeId == "LabelButton" {
    font-family: $typeFamilyLabelButton;
    font-weight: $typeWeightLabelButton;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeLabelButton;
      line-height: $typeLineHeightLabelButton;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeLabelButtonSmaller;
      line-height: $typeLineHeightLabelButtonSmaller;
    }
  } @else if $typeId == "Copy" {
    font-family: $typeFamilyCopy;
    font-weight: $typeWeightCopy;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeCopy;
      line-height: $typeLineHeightCopy;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeCopySmaller;
      line-height: $typeLineHeightCopySmaller;
    }
  } @else if $typeId == "CopySecondary" {
    font-family: $typeFamilyCopySecondary;
    font-weight: $typeWeightCopySecondary;
    color: $typeColorCopySecondary;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeCopySecondary;
      line-height: $typeLineHeightCopySecondary;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeCopySecondarySmaller;
      line-height: $typeLineHeightCopySecondarySmaller;
    }
  } @else if $typeId == "Hint" {
    font-family: $typeFamilyHint;
    font-weight: $typeWeightHint;
    @include mqLargerEqual($bpM) {
      font-size: $typeSizeHint;
      line-height: $typeLineHeightHint;
    }
    @include mqSmaller($bpM) {
      font-size: $typeSizeHintSmaller;
      line-height: $typeLineHeightHintSmaller;
    }
  } @else {
    @error 'type($typeId) invalid typeId';
  }
}

@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bluLink {
  cursor: pointer;
  @include type('Copy');
  color: $colorPrimary1;
  text-decoration: none;

  &:hover {
    color: $colorPrimary2;
  }
}

:export {
  types: $types;

  typeFamily: $typeFamily;

  typeFamilyXxl: $typeFamilyXxl;
  typeWeightXxl: $typeWeightXxl;
  typeSizeXxl: $typeSizeXxl;
  typeLineHeightXxl: $typeLineHeightXxl;
  typeSizeXxlSmaller: $typeSizeXxlSmaller;
  typeLineHeightXxlSmaller: $typeLineHeightXxlSmaller;

  typeFamilyXl: $typeFamilyXl;
  typeWeightXl: $typeWeightXl;
  typeSizeXl: $typeSizeXl;
  typeLineHeightXl: $typeLineHeightXl;
  typeSizeXlSmaller: $typeSizeXlSmaller;
  typeLineHeightXlSmaller: $typeLineHeightXlSmaller;

  typeFamilyL: $typeFamilyL;
  typeWeightL: $typeWeightL;
  typeSizeL: $typeSizeL;
  typeLineHeightL: $typeLineHeightL;
  typeSizeLSmaller: $typeSizeLSmaller;
  typeLineHeightLSmaller: $typeLineHeightLSmaller;

  typeFamilyM: $typeFamilyM;
  typeWeightM: $typeWeightM;
  typeSizeM: $typeSizeM;
  typeLineHeightM: $typeLineHeightM;
  typeSizeMSmaller: $typeSizeMSmaller;
  typeLineHeightMSmaller: $typeLineHeightMSmaller;

  typeFamilyS: $typeFamilyS;
  typeWeightS: $typeWeightS;
  typeSizeS: $typeSizeS;
  typeLineHeightS: $typeLineHeightS;
  typeSizeSSmaller: $typeSizeSSmaller;
  typeLineHeightSSmaller: $typeLineHeightSSmaller;

  typeFamilyXs: $typeFamilyXs;
  typeWeightXs: $typeWeightXs;
  typeSizeXs: $typeSizeXs;
  typeLineHeightXs: $typeLineHeightXs;
  typeSizeXsSmaller: $typeSizeXsSmaller;
  typeLineHeightXsSmaller: $typeLineHeightXsSmaller;

  typeFamilyXxs: $typeFamilyXxs;
  typeWeightXxs: $typeWeightXxs;
  typeSizeXxs: $typeSizeXxs;
  typeLineHeightXxs: $typeLineHeightXxs;
  typeSizeXxsSmaller: $typeSizeXxsSmaller;
  typeLineHeightXxsSmaller: $typeLineHeightXxsSmaller;

  typeFamilyCopyStrong: $typeFamilyCopyStrong;
  typeWeightCopyStrong: $typeWeightCopyStrong;
  typeSizeCopyStrong: $typeSizeCopyStrong;
  typeLineHeightCopyStrong: $typeLineHeightCopyStrong;
  typeSizeCopyStrongSmaller: $typeSizeCopyStrongSmaller;
  typeLineHeightCopyStrongSmaller: $typeLineHeightCopyStrongSmaller;

  typeFamilyLabelL: $typeFamilyLabelL;
  typeWeightLabelL: $typeWeightLabelL;
  typeSizeLabelL: $typeSizeLabelL;
  typeLineHeightLabelL: $typeLineHeightLabelL;
  typeSizeLabelLSmaller: $typeSizeLabelLSmaller;
  typeLineHeightLabelLSmaller: $typeLineHeightLabelLSmaller;

  typeFamilyLabelM: $typeFamilyLabelM;
  typeWeightLabelM: $typeWeightLabelM;
  typeSizeLabelM: $typeSizeLabelM;
  typeLineHeightLabelM: $typeLineHeightLabelM;
  typeSizeLabelMSmaller: $typeSizeLabelMSmaller;
  typeLineHeightLabelMSmaller: $typeLineHeightLabelMSmaller;

  typeFamilyLabelS: $typeFamilyLabelS;
  typeWeightLabelS: $typeWeightLabelS;
  typeSizeLabelS: $typeSizeLabelS;
  typeLineHeightLabelS: $typeLineHeightLabelS;
  typeSizeLabelSSmaller: $typeSizeLabelSSmaller;
  typeLineHeightLabelSSmaller: $typeLineHeightLabelSSmaller;

  typeFamilyLabelButton: $typeFamilyLabelButton;
  typeWeightLabelButton: $typeWeightLabelButton;
  typeSizeLabelButton: $typeSizeLabelButton;
  typeLineHeightLabelButton: $typeLineHeightLabelButton;
  typeSizeLabelButtonSmaller: $typeSizeLabelButtonSmaller;
  typeLineHeightLabelButtonSmaller: $typeLineHeightLabelButtonSmaller;

  typeFamilyCopy: $typeFamilyCopy;
  typeWeightCopy: $typeWeightCopy;
  typeSizeCopy: $typeSizeCopy;
  typeLineHeightCopy: $typeLineHeightCopy;
  typeSizeCopySmaller: $typeSizeCopySmaller;
  typeLineHeightCopySmaller: $typeLineHeightCopySmaller;

  typeFamilyCopySecondary: $typeFamilyCopySecondary;
  typeWeightCopySecondary: $typeWeightCopySecondary;
  typeSizeCopySecondary: $typeSizeCopySecondary;
  typeLineHeightCopySecondary: $typeLineHeightCopySecondary;
  typeSizeCopySecondarySmaller: $typeSizeCopySecondarySmaller;
  typeLineHeightCopySecondarySmaller: $typeLineHeightCopySecondarySmaller;

  typeFamilyHint: $typeFamilyHint;
  typeWeightHint: $typeWeightHint;
  typeSizeHint: $typeSizeHint;
  typeLineHeightHint: $typeLineHeightHint;
  typeSizeHintSmaller: $typeSizeHintSmaller;
  typeLineHeightHintSmaller: $typeLineHeightHintSmaller;
}
