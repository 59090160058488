@import '/src/styles-imports/shared';

/* NINE LEVELS DIAGRAM */
.nineLevelsDiagram {
  overflow: hidden;
  line-height: 0;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .barContainer {
    display: flex;
    gap: 2px;
    .bar {
      flex: 1;
      svg {
        width: 200%;
        transition: width .3s ease-in-out, cx .3s ease-in-out;
      }
      &.flipped {
        transform: scaleX(-1);
      }
    }
  }
}
