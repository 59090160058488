@import '/src/styles-imports/shared';

$headerHeight: 70px;
$subHeaderHeight: 52px;
$footerHeight: 76px;

/* EditCandidateProfilePanel */
.editCandidateProfilePanel {
  background-color: $colorWhite;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .header {
    top: 0;
    padding: space('S') space('M');
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;

    @include type('LabelL');

    @include mqSmaller($bpS) {
      padding: space('S') space('Xs');
    }
  }

  .subHeader {
    display: flex;
    padding: space('Xs') space('M') 0;
    border-bottom: 1px solid $colorGrey4;

    @include mqSmaller($bpS) {
      padding: space('Xs') space('Xs') 0;
    }

    .navigationItem {
      padding-bottom: 13px;
      margin-right: space('S');
      cursor: pointer;
      @include type('Copy');
      @include transition(color);

      &:hover {
        color: $colorPrimary2;
      }
      &.active {
        color: $colorPrimary1;
        border-bottom: 2px solid $colorPrimary1;
      }
    }
  }

  .content {
    min-height: calc(100vh - #{$headerHeight} - #{$subHeaderHeight} - #{$footerHeight} - #{$spaceM});
    max-height: calc(100vh - #{$headerHeight} - #{$subHeaderHeight} - #{$footerHeight} - #{$spaceM});
    overflow-y: auto;
    overflow-x: hidden;

    padding: 0 space('M') space('M');

    @include mqSmaller($bpS) {
      padding: 0 space('Xs') space('M');
    }

    .block {
      margin-top: space('M');

      & > div:not(:first-child),
      & > label:not(:first-child) {
        margin-top: space('Xs');
      }

      button {
        display: block;
        margin-left: auto;
      }
    }
  }

  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;

    display: flex;
    justify-content: flex-end;
    padding: space('Xs') space('M');
    border-top: 1px solid $colorGrey4;

    @include mqSmaller($bpS) {
      padding: space('Xs');
    }

    button {
      margin-left: space('Xs');
    }
  }

  .infoBlock1 {
    margin-top: 24px;
    & > * {
      margin-top: 16px;
    }
  }
  .infoBlock2 {
    margin-top: 24px;
    .title {
      @include type('LabelL');
    }
    .selects {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .select {
        width: calc((100% / 2) - 8px);
      }
    }
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 24px;
    button {
      margin-bottom: 16px;
    }
    button:last-child {
      margin-left: 16px;
    }
  }

}
