@import '/src/styles-imports/shared';

/* LOGO */
.logoInside {
  width: $logoWidth;
  height: $logoHeight;
  padding: $spaceXxs;

  overflow: hidden;

  img {
    display: block;
    height: 100%;
  }

  /* INTERACTION */

  /* MODIFIERS */
  &.sizeL {
    height: $logoHeightLarge;
    width: $logoWidthLarge;
  }
  &.sizeCOACHHUB {
    height: 30px;
    width: $logoWidthLarge;

    @include mqSmaller($bpS) {
      width: $logoWidth;

      & > img {
        width: $logoWidth;
      }
    }
  }
  &.compensatePadding {
    transform: translateX(-#{$spaceXxs});
  }
  &.link {
    cursor: pointer;
  }

  /* RESPONSIVE */

  /* CHILDREN */
}
