@import '/src/styles-imports/shared';

/* Section1 */
.section1 {
  color: $colorGrey1;

  .sTitle {
    @include type('S');
  }
  .description {
    @include type('Copy');
    margin-top: space('Xs');
  }
}
