@import '/src/styles-imports/shared';

$toastRightMargin: 60px;
$toastWidth: 348px;

.enter {
  transform: translateX(calc(#{$toastWidth} + #{$toastRightMargin})) !important;
}
.enterActive, .enterDone {
  transform: translateX(0) !important;
  @include transition(transform);
}
.exit {
  transform: translateX(0) !important;
}
.exitActive, .exitDone {
  transform: translateX(calc(#{$toastWidth} + #{$toastRightMargin})) !important;
  @include transition(transform);
}

.toast {
  width: $toastWidth;
  position: fixed;
  padding: space('Xs');
  box-sizing: border-box;
  background-color: $colorWhite;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);;
  border: 1px solid $colorGrey4;
  border-radius: 2px;
  bottom: space('M');
  right: $toastRightMargin;

  z-index: zIndex('Error');
  .content {
    overflow: hidden;
  }
  .headline {
    @include type('LabelL');
  }
  .message {
    margin-top: space('Xxs');
    @include type('Copy');
  }
  svg {
    float: right;
    margin-top: 3px;
    cursor: pointer;
  }

  @include mqSmaller($bpM) {
    width: 312px;
  }
  @include mqSmaller($bpS) {
    width: 288px;
    right: 16px;
  }
}
