@import '/src/styles-imports/shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(-15px) !important;
  @include transition(transform, opacity);
}

.container {
  position: relative;
  user-select: none;

  display: flex;

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }

  &.alignCenter {
    justify-content: center;
    .flyout {
      left: -11 8px;
    }
  }
  &.alignRight {
    justify-content: flex-end;
    .flyout {
      right: -10px;
    }
    @include mqSmaller($bpS) {
      .flyout {
        right: 0px;
      }
    }
  }
  &.containerPositionOverride {
    position: static;
    @include mqSmaller($bpS) {
      .flyout {
        right: 0;
      }
    }
  }
}

.dropDownSubtle {
  color: $colorPrimary1;
  @include type('Copy');

  display: flex;
  align-items: center;
  cursor: pointer;

  // transitions
  @include transition(color);

  svg {
    margin-left: 8px;
    // transition
    @include transition(fill, transform);
    path {
      fill: $colorPrimary1
    }
  }
  &:hover {
    color: $colorPrimary2;
    path {
      fill: $colorPrimary2;
    }
  }
  &.open {
    svg {
      transform: scale(-1);
    }
  }

  &.disabled {
    cursor: inherit;
    .title {
      color: $colorGrey2;
    }
    path {
      fill: $colorGrey2;
    }
  }

  .title {
    display: flex;
    color: initial;
    .counter {
      border-radius: 4px;
      font-size: 12px;
      text-align: center;
      color: $colorPrimary1;
      background-color: $colorPrimary3;
    }
  }

  @include mqSmaller($bpS) {
    margin-top: 0;
  }
}

.flyout {
  position: absolute;
  width: 257px;
  max-height: 400px;
  overflow-x: hidden;
  box-sizing: border-box;

  padding: 24px 0 16px;
  margin-top: 30px;

  border-radius: 10px;
  border: 1px solid #ececec;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: $colorWhite;
  z-index: zIndex('Buffer2');

  .title {
    @include type('LabelL');
    color: $colorGrey1;
    padding: 0 16px 16px;
  }
  .menuItem {
    @include type('Copy');
    color: $colorGrey1;
    padding: 14px;
    cursor: pointer;

    // transitions
    @include transition(background-color);

    &.active {
      background-color: $colorPrimary4;
    }
    &:hover {
      background-color: $colorPrimary4;
    }
  }

  &.multipleChoice {
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  @include mqSmaller($bpS) {
    &.fullWidthForXs {
      width: calc(100vw - #{$spaceXs * 2});
    }
  }
}
