@import '/src/styles-imports/shared';

.enter, .enterActive {
  opacity: 0 !important;
}
.enterDone {
  opacity: 1 !important;
  @include transition(opacity);
}
.exit, .exitActive, .exitDone {
  opacity: 0!important;
}

.userInvitation {
  .header {
    @include type('LabelL');
    color: $colorPrimary1;
    padding: 20px 60px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;

    @include mqSmaller($bpS) {
      padding: 20px 16px;
    }

    .close {
      cursor: pointer;
    }
  }
  .scrollableContainer {
    height: calc(100vh - 61px);
    overflow-y: auto;
  }
  .gridContainer {
    @include basicGrid;
    .gridContent {
      grid-column: 4/10;
      padding-top: space('L');
      padding-bottom: space('L');
      animation: slideUp $animationDuration $animationFunction;

      @include mqSmaller($bpM) {
        grid-column: 2/8;
      }
      @include mqSmaller($bpS) {
        grid-column: start/end;
      }
    }
  }

  section {
    margin-top: space('S');

    &.bigTopMargin {
      margin-top: space('M');
    }
  }

  .select {
    margin-top: space('Xs');
    .option {
      .optionTitle {
        @include type('Copy');
      }
      .optionDescription {
        @include type('Hint');
        color: $colorGrey2;
        margin-top: space('Xxs');;
        white-space: pre-wrap;
      }
    }
  }

  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: space('Xs');
    button {
      margin-left: space('Xs');
    }
  }

}
