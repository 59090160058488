@import '/src/styles-imports/shared';

.resetPasswordRequest {
  @include basicGrid;
  .container {
    margin-top: 176px;
    grid-column: 5/9;

    &.balancedYou {
      margin-top: 126px;
      .poweredBy {
        padding-bottom: space('S');
      }
    }
  }

  @include mqSmaller($bpM) {
    .container {
      grid-column: 3/7;
    }
  }

  @include mqSmaller($bpS) {
    .container {
      grid-column: start/end;
      margin-top: 92px;
      &.balancedYou {
        margin-top: 88px;
        .poweredBy {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
