@import '/src/styles-imports/shared';

/* ExternalAssessmentWrapper */
.externalAssessmentWrapper {
  @include basicGrid;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .gridContent {
    grid-column: 5/9;
    margin-top: 176px;
    word-break: break-word;

    @include mqSmaller($bpM) {
      grid-column: 3/7;
    }
    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: 92px;
    }

    & > button {
      display: block;
      margin-left: auto;
      margin-top: $spaceS;
    }
  }
}
