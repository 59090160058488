@import '/src/styles-imports/shared';

.compactAssessmentTeaser {
  box-sizing: border-box;
  .container {
    width: 100%;
    box-sizing: border-box;
    background-color: $colorWhite;
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    cursor: pointer;
    @include transition(box-shadow);
    &:hover {
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    }
    &:focus {
      outline: none;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    }
    .imageBlock {
      width: 100%;
      overflow: hidden;
      margin: 0;
      //16:9 aspect ratio
      padding-top: 56.25%;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
      }
    }
    .textBlock {
      padding: 0 space('S') space('M');
      > div:first-child {
        padding-top: space('Xxs');
        padding-bottom: space('Xxs');
      }
      .scopeInfo {
        @include type('LabelL');
        color: $colorGrey1;
      }
    }
  }
}
