@import '/src/styles-imports/shared';

/* AdditionalInputAndComments */
.additionalInputAndComments {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .blue {
    color: $colorPrimary2;
  }
}
