@import '/src/styles-imports/shared';

/* BodyBatteryInsights */
.bodyBatteryInsights {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .trends {
    display: flex;
    flex-wrap: wrap;
    margin-top: space('Xs');

    & > div:first-child {
      margin-right: space('Xs');
    }
  }

  .insight {
    width: 100%;
    margin-top: space('S');
    padding: space('Xs');
    box-sizing: border-box;

    border-radius: 8px;
    @include type('Copy');
    overflow: hidden;

    &.blue {
      background-color: $colorPrimary3;
    }

    &.red {
      background-color: $colorDanger3;
    }
  }
}
