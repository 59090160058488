@import '/src/styles-imports/shared';

/* COLLAPSIBLEMENU */
.collapsibleMenu {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  ul {
    list-style-type: none;
    li {
      margin: 8px 0;
      li {
        padding-left: space('Xs');
      }
    }
  }

  .listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 8px space('Xs');
    border-radius: 8px;
    @include transition(color);
    @include transition(background-color);
    @include animation(fadeIn);

    &:hover {
      cursor: pointer;
      background-color: $colorPrimary4;
    }

    &:active {
      background-color: $colorPrimary3;
    }

    &.isSelected {
      color: $colorPrimary1;
      background-color: $colorPrimary4;
      .arrow svg path {
        fill: $colorPrimary1;
      }
    }

    &.isExpanded {
      .arrow svg {
        transform: scale(-1);
      }
    }

    > .arrow {
      display: flex;
      align-items: center;
      padding: 3px;
      padding-left: space('Xs');

      svg {
        display: block;
        @include transition(transform);

        path {
          @include transition(fill);
        }
      }
    }
  }
}
