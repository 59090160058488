@import '/src/styles-imports/shared';

/* IST RESULT */
.istResult {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .mainHeader {
    margin-bottom: space('Xs');
  }
  
  .content {
    margin: space('Xxs') 0;
    
    .legend {
      margin-bottom: space('Xs');
      color: $colorGrey1;
      font-size: 12px;
    }
  }

  .intro {
    margin: space('Xs') 0 space('S') 0;
  }

  .subDimensions {
    margin: space('Xs') 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: space('Xs');

    .subDimension {
      display: flex;
      align-items: center;
      gap: 6px;
    }

    @include mqSmaller($bpM) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include mqSmaller($bpS) {
      grid-template-columns: 1fr;
    }
  }

  .bottom {
    display: flex;
    justify-content: flex-end;
    gap: space('Xs');
    margin-top: space('M');

    @include mqSmaller($bpM) {
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .callHelp {
    display: flex;
    justify-content: center;
    > div {
      width: 80%;
      @include mqSmaller($bpS) {
        width: 100%;
      }
    }
  }
}
