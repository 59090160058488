@import '/src/styles-imports/shared';

$knobXY: $interactionElementHeight;
$interactionPadding: 12px;

$lineHeight: 2px;

/* SLIDER BIPOLAR */
.sliderBipolar {
  user-select: none;
  cursor: pointer;
  position: relative;

  padding: $interactionPadding 0;

  height: $knobXY + 2*$interactionPadding;


  &.transitioning {
    .knobLayer {
      @include transitionShort(left);
    }

    .bar {
      @include transitionShort(transform);
    }
  }


  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  // LINE
  .lineLayer {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
  }
  .line {
    width: 100%;
    height: $lineHeight;
    background-color: $colorGrey4;
  }




  // BARS
  .barsLayer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: $interactionPadding * 2 0;
    transform: translateX(100%);

    .bar {
      width: 100%;
      height: 100%;
      transform-origin: left;
      background-color: $colorPrimary4;
    }

    .barCenter {
      position: absolute;
      width: 2px;
      height: $knobXY;
      background-color: $colorGrey3;
    }
  }



  // KNOB
  .knobLayer {
    pointer-events: none; // prevent clicking outside of component when layer has been moved but keeps overflow for knob
    position: absolute;

    display: flex;
    justify-content: center;
    align-items: center;

    top: 0px;
    height: 100%;
    transform: translateX(-$spaceM);
    &.touched {
      .knobArrows {
        opacity: 0;
      }
    }

  }

  .knob {
    pointer-events: auto;
    padding: $interactionPadding;
    border-radius: 50%;

    @media (hover: hover) {
      &:hover .knobBackground {
        background-color: $colorPrimary2;
      }
    }
  }
  .knobBackground {
    position: relative;
    width: $knobXY;
    height: $knobXY;

    border-radius: 50%;
    background-color: $colorPrimary1;

    @include transition(background-color);
  }
  .knobArrows {
    position: absolute;
    top: 12px;
    left: 4px;

    @include transition(opacity);

    svg, svg path {
      fill: white;
    }
    svg:nth-child(2) {
      margin-left: 5px;
    }
  }

}
