@import '/src/styles-imports/shared';

/* QUESTIONASPECTS */
.questionAspects {

  margin-top: $spaceS;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include dialogQuestion;
}
