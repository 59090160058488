@import '/src/styles-imports/shared';

.resizableTextarea {
  .resizableTextareaContainer {
    position: relative;
  }

  /* LABEL */
  .label {
    position: absolute;
    width: calc(100% - #{$spaceXs});

    pointer-events: none;
    @include type('Copy');

    transform: translate(17px, 15px);
    transform-origin: 0 0;

    @include transition(transform, width);
    &.small {
      width: 100%;
      transform: translate(17px, 3px) scale(calc($typeSizeHint / $typeSizeCopy));
    }
    &.disabled {
      color: $colorGrey2;
    }
  }

  //TEXTAREA
  textarea {
    width: 100%;
    border-radius: 2px;
    background-color: $colorGrey4;
    padding: 20px $spaceXs $spaceXs;
    box-sizing: border-box;
    outline: none;

    border: 1px $colorGrey4 solid;
    border-bottom-width: 1.5px;
    border-radius: 2px;

    @include type('Copy');
    caret-color: $colorPrimary1;

    overflow: auto;
    height: auto;

    // transitions
    @include transition(color, background-color, box-shadow, border, height);

    &:hover {
      box-shadow: $shadowDark;
      border-color: $colorWhite;
      background-color: $colorWhite;
    }
    &:focus {
      box-shadow: $shadowDark;
      border-bottom-color: $colorPrimary1;
      background-color: $colorWhite;

      &.evo {
        border-bottom-color: $colorAssistantDark;
      }
    }
    &:disabled {
      color: $colorGrey2;
      box-shadow: none;
      border-color: $colorGrey5;
      background-color: $colorGrey5;
    }

    &.resizableContent {
      resize: none;
    }
    &.resizableManual {
      resize: vertical;
    }
  }

  .reevaluateBtn {
    font-size: 14px;
    svg {
      height: 10px;
    }
    button {
      position: absolute;
      left: $spaceXxs;
      bottom: $spaceXxs;
      border: 0px;
      box-shadow: $shadowDark;
      min-height: 20px;
      padding: 6px 10px;
      > div {
        gap: $spaceXxxs;
      }
    }
  }
}
