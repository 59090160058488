/* V1.1 based on Jonathan's Gist */
/* https://gist.github.com/gruppjo/b5c6389b1ef71369e815c95cb1b33813 */

$animationDuration: 300ms;
$animationDurationMs: 300;
$animationDurationLong: 600ms;
$animationDurationLongMs: 600;
$animationDurationLonger: 900ms;
$animationDurationLongerMs: 900;
$animationDurationLongest: 1200ms;
$animationDurationLongestMs: 1200;
$animationDurationShort: 200ms;
$animationDurationShortMs: 200;
$animationFunction: ease;


:export {
  animationDuration: $animationDuration;
  animationDurationMs: $animationDurationMs;
  animationDurationLong: $animationDurationLong;
  animationDurationLongMs: $animationDurationLongMs;
  animationDurationLonger: $animationDurationLonger;
  animationDurationLongerMs: $animationDurationLongerMs;
  animationDurationLongest: $animationDurationLongest;
  animationDurationLongestMs: $animationDurationLongestMs;
  animationDurationShort: $animationDurationShort;
  animationDurationShortMs: $animationDurationShortMs;
  animationFunction: $animationFunction;
}


@mixin transition($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $animationDuration $animationFunction, comma);
  }

  transition: $transition;
}

@mixin transitionImportant($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $animationDuration $animationFunction, comma);
  }

  transition: $transition !important;
}


@mixin transitionLong($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $animationDurationLong $animationFunction, comma);
  }

  transition: $transition;
}

@mixin transitionLongImportant($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $animationDurationLong $animationFunction, comma);
  }

  transition: $transition !important;
}

@mixin transitionLonger($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $animationDurationLonger $animationFunction, comma);
  }

  transition: $transition;
}

@mixin transitionLongest($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $animationDurationLongest $animationFunction, comma);
  }

  transition: $transition;
}

@mixin transitionShort($properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $animationDurationShort $animationFunction, comma);
  }

  transition: $transition;
}


@mixin transitionDurationOverride($durationOverride, $properties...) {
  $transition: ();

  @each $property in $properties {
    $transition: append($transition, $property $durationOverride $animationFunction, comma);
  }

  transition: $transition;
}


@mixin animationShort($name, $animationFunction: $animationFunction) {
  animation: $name $animationDurationShort $animationFunction;
}

@mixin animation($name, $animationFunction: $animationFunction) {
  animation: $name $animationDuration $animationFunction;
}

@mixin animationImportant($name, $animationFunction: $animationFunction) {
  animation: $name $animationDuration $animationFunction !important;
}

@mixin animationLong($name, $animationFunction: $animationFunction) {
  animation: $name $animationDurationLong $animationFunction;
}

@mixin animationLonger($name, $animationFunction: $animationFunction) {
  animation: $name $animationDurationLonger $animationFunction;
}

@mixin animationLongest($name, $animationFunction: $animationFunction) {
  animation: $name $animationDurationLongest $animationFunction;
}

@mixin animationDurationOverride($durationOverride, $name, $animationFunction: $animationFunction) {
  animation: $name $durationOverride $animationFunction;
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideDownSmall {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideUpSmallest {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes scale {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }
  50% {
    opacity: 1;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(4);
    opacity: 1;
  }
  75% {
    transform: scale(100);
    opacity: 1;
  }
  100% {
    transform: scale(100);
    opacity: 0;
  }
}

@keyframes loadAssessmentResult {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loadModal {
  0% {
    opacity: 0;
    transform: translate(0, 50px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes closeModal {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 50px);
  }
}

@keyframes loadMenuCloseIcon {
  0% {
    transform: rotateX(0deg);
  }
  100% {
    transform: rotateX(180deg);
  }
}

@keyframes loadMainNavigation {
  0% {
    opacity: 0;
    margin-top: -60px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}

@keyframes slideFromCenterToTop {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  75% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

@keyframes assessmentIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  16% {
    opacity: 0;
    transform: translateY(25px);
  }
  33% {
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
@keyframes assessmentInReverse {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  66% {
    transform: translateY(20px);
  }
  84% {
    opacity: 0;
    transform: translateY(25px);
  }
  100% {
    opacity: 0;
    transform: translateY(30px);
  }
}

@keyframes assessmentOut {
  0% {
    transform: translateY(0px);
  }
  66% {
    opacity: 1;
    transform: translateY(-20px);
  }
  82% {
    opacity: 0;
    transform: translateY(-25px);
  }
  100% {
    opacity: 0;
    transform: translateY(-30px);
  }
}

@keyframes assessmentOutReverse {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  16% {
    opacity: 0;
    transform: translateY(-25px);
  }
  33% {
    opacity: 1;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes assessmentSide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50px);
  }
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeLeft {
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
