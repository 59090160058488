@import '/src/styles-imports/shared';

/* IstRegistration */
.istRegistration {
  text-align: left;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .personalInfo, .physioData {
    margin-top: $spaceM;
  }

  .input {
    width: 100%;
  }

  .inputs {
    display: flex;

    & > div {
      flex-basis: calc((100% - #{$spaceXs}) / 2);

      &:first-child {
        margin-right: $spaceXxs;
      }
      &:last-child {
        margin-left: $spaceXxs;
      }
    }
  }

  .errorMessage {
    @include type('Copy');
    color: $colorDanger1;
    margin-top: $spaceXs;
  }
}
