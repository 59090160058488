@import '/src/styles-imports/shared';

$checkboxSize: 36px;

/* SettingsAndCostOverview */
.settingsAndCostOverview {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .invitedUsers {
    margin-top: space('S');

    .user {
      margin-top: space('Xs');

      &:first-child {
        margin-top: space('Xs');
      }

      .firstRow {
        display: flex;
        align-items: center;

        .username {
          flex-basis: calc(50% - #{$spaceXxs});

          display: flex;
          align-items: center;
          span {
            padding-left: space('Xxs');
          }
        }

        //dropdown
        & > div:last-child {
          flex-basis: calc(50% - #{$spaceXxs});
          margin-left: space('Xs');
        }

        @include mqSmaller($bpS) {
          flex-direction: column;

          .username {
            flex-basis: 100%;
            align-self: flex-start;
          }
          & > div:last-child {
            flex-basis: 100%;
            margin-top: space('Xs');
            margin-left: 0;
          }
        }
      }
    }
  }

  .list {
    .listRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: space('Xs');

      &:first-child {
        margin-top: space('S');
      }
      & > label {
        flex-basis: 50%;
      }
      & > span {
        flex-basis: 50%;

        padding-left: space('Xs');
        @include type('Copy');
        text-align: right;
      }

      @include mqSmaller($bpS) {
        flex-direction: column;
        align-items: flex-start;

        & > span {
          padding-top: space('Xxs');
          padding-left: calc(#{$checkboxSize} + #{$spaceXxs});
        }
      }
    }
  }

  .grantManagementAccess {
    margin-top: $spaceXxs;
    margin-left: 44px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: space('S');
  }
}
