@import '/src/styles-imports/shared';

/* QuestionExplanatory */
.questionExplanatory {
  margin-top: $spaceXs;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .label {
    @include type('LabelM');
    margin-bottom: $spaceXxs;
  }
}
