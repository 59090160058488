@import '/src/styles-imports/shared';

/* Vacancies */
.vacancies {
  @include dialogIntermission;

  margin-top: -40px;
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  & > div:nth-child(2) {
    margin-top: space('S');
    overflow: hidden;
  }
}
