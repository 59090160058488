@import '/src/styles-imports/shared';

/* VERTICAL FLEX WRAP CONTAINER */
.verticalFlexWrapContainer {
  --cols: 1;
  --height: auto;
  --gap: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
  gap: var(--gap);
  height: var(--height);

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  > * {
    width: calc((100% - (var(--cols) - 1) * var(--gap)) / var(--cols));
  }
}
