@import '/src/styles-imports/shared';

$loaderHeight: 48px;

/* LOADINGMEDIUM */
.loadingMedium {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .loader {
    width: $loaderHeight;
    height: $loaderHeight;

    @include animationLonger(rotate);
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }
}
