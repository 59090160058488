@import '/src/styles-imports/shared';

/* NewSkillsResult */
.newSkillsResult {
  width: 100%;
  box-sizing: border-box;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      flex-shrink: 0;
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  button {
    display: block;
    margin-left: auto;
    margin-top: $spaceS;
  }
}
