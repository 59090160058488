@import '/src/styles-imports/shared';

/* WellBeing */
.wellBeing {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .gridContainer {
    @include basicGridNoMargins;

    .collapsible {
      & > div {
        position: relative;
        width: 100%;
        &:after {
          display: block;
          position: absolute;
          width: 100%;
          height: 20px;
          content: '';
          bottom: -6px;
          background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);
        }
      }
    }

    .left {
      grid-column: start / 9;
    }
    .right {
      grid-column: 9 / end;

      & > div:not(:first-child) {
        margin-top: $spaceXs;
      }
    }

    @include mqSmaller($bpM) {
      .left, .right {
        grid-column: start / end;
      }
      .right {
        & > div {
          margin-top: $spaceXs;
        }
      }
    }
  }
}
