@import '/src/styles-imports/shared';

.assessmentRadioButton {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  padding-left: 44px;
  color: $colorGrey1;
  cursor: pointer;
  @include type('Copy');

  &.sizeS {
    height: 22px;
    padding-left: $spaceS;
    .checkmark {
      width: 22px;
      height: 22px;

      &:after {
        width: 18px;
        height: 18px;
        top: 2px;
        left: 2px;
      }
    }
    .name {
      padding-top: 3px;
      padding-left: $spaceXxs;
    }
  }

  // INTERACTION
  &:focus {
    outline: none;
    .checkmark {
      background-color: $colorWhite;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      @include transition(background-color, box-shadow);
    }
  }

  // MODIFIERS
  &.animation {
    animation: slideFromCenterToTop .5s ease-out;
  }

  // CHILDREN
  .name {
    display: block;
    padding-top: 10px;
    padding-left: $spaceXs;
  }
  .number {
    width: 24px;
    box-sizing: border-box;
    padding-right: $spaceXs;
    color: $colorGrey2;
  }
  .checkmark {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 40px;
    width: 40px;
    background-color: $colorGrey4;
    border: 1px solid $colorGrey4;
    border-radius: 50%;
    cursor: pointer;
    @include transition(background-color, border);
    &:after {
      opacity: 0;
      content: '';
      position: absolute;
      left: 4px;
      top: 4px;
      width: 32px;
      height: 32px;
      background-color: $colorPrimary1;
      border-radius: 50%;
      @include transition(opacity);
    }
  }

  &.checked {
    .name {
      color: $colorPrimary1;
    }
    .checkmark {
      display: block;
      background-color: $colorGrey4;
      border: 1px solid $colorGrey4;
      &:after {
        opacity: 1;
        @include transition(opacity);
      }
    }
  }
  &:hover, &:focus {
    .name {
      color: $colorPrimary1;
      @include transition(color);
    }
    .checkmark {
      background-color: $colorWhite;
      box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      @include transition(background-color, box-shadow);
    }
  }
  &.error {
    .checkmark {
      border-color: $colorDanger1;
      &:after {
        background-color: $colorDanger1;
      }
    }
    .name {
      color: $colorDanger1;
    }
  }
  &.disabled {
    pointer-events: none;
    .name {
      color: $colorGrey2;
    }
    .checkmark {
      background-color: $colorGrey5;
      border-color: $colorGrey5;
      box-shadow: none;
      &:after {
        background-color: $colorPrimary3;
      }
    }
  }
}
