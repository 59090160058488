@import '/src/styles-imports/shared';

/* SurveyEnd */
.surveyEnd {
  margin: 0 60px;
  text-align: center;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    margin: 0 space('Xs');
  }


  /* CHILDREN */
  .graphic {
    font-size: 48px;
    line-height: 48px;

    @include mqSmaller($bpM) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  & > button {
    display: block;
    margin: $spaceS auto 0;
  }
}
