@import '/src/styles-imports/shared';

/* AddPeersModal */
.addPeersModal {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .error {
    color: $colorDanger1;
    margin-top: $spaceXs;
  }
}
