@import '/src/styles-imports/shared';

/* Introduction */
.introduction {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .blue {
    color: $colorPrimary2;
  }

  .block {
    margin-top: $spaceXs;

    &:first-child {
      margin-top: 0;
    }

    & > span {
      @include type('Xxs');
    }
    & > div {
      @include type('Copy');
      margin-top: $spaceXxs;
    }
  }
}
