@import '/src/styles-imports/shared';

/* BillingDetailsForm */
.billingDetailsForm {
  @include dialogQuestion;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .orgData, .contactPerson {
    margin-top: space('S');

    .inputs {
      display: flex;
      margin-top: space('Xs');

      & > div:first-child {
        width: calc(80% - #{$spaceXs});
        margin-right: space('Xs');
      }
      & > div:last-child {
        width: 20%;
        & > input {
          min-width: unset;
        }
      }
    }
  }
}
