@import '/src/styles-imports/shared';

/* SUBDIMENSION */
.subdimension {
  margin-bottom: space('Xl');

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .intro {
    display: flex;
    justify-content: space-between;
    gap: space('M');
    margin-bottom: space('M');
  }
}
