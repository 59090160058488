@import '/src/styles-imports/shared';

/* PERSONAL NOTES */
.personalNotes {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .add {
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: $spaceXs;

    color: $colorPrimary1;
    @include transition(color, fill);

    &:hover {
      cursor: pointer;
      color: $colorPrimary2;

      & > svg > path {
        fill: $colorPrimary2;
      }
    }

    & > svg {
      width: 14px;
      height: 14px;

      & > path {
        fill: $colorPrimary1;
      }
    }

    & > span {
      padding-left: $spaceXxs;
    }
  }

  .yourNotes {
    display: flex;
    justify-content: space-between;
    .btn {
      cursor: pointer;
    }
  }
}
