@import '/src/styles-imports/shared';

.teamUsersList {
  width: 100%;

  // CollapsiblePanel
  & > div:first-child {
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    background-color: white;
  }

  // Pagination
  .paginationWrapper {
    margin: $spaceXs $spaceXs 0;
  }

  .loading {
    padding: $spaceXs;
  }

  .header {
    padding: space('Xs') 0;

    @include type('LabelL');
    color: $colorGrey1;
  }

  [class*="CollapsiblePanel_header"] {
    background-color: white;

    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;

    @include transition(background-color);

    &:hover {
      background-color: $colorPrimary4;
    }
  }

  .leaders, .members {
    .label {
      @include type('LabelM');
      padding: 0 $spaceXs $spaceXs;
    }

    .empty {
      @include type('Hint');
      padding: 0 $spaceXs 0;
    }
  }

  .leaders {
    .employee:last-child {
      border-bottom: 1px solid $colorGrey4;
    }
  }

  .members {
    .label:not(.noLeaders) {
      padding-top: $spaceXs;
    }
    .empty {
      padding-bottom: $spaceXs;
    }
  }

  .employee {
    display: flex;
    align-items: center;
    padding: space('Xs');
    border-top: 1px solid $colorGrey4;

    @include transition(background-color);
    &:hover {
      background-color: $colorPrimary4;
      cursor: pointer;
    }

    .imgCircle {
      flex-shrink: 0;
    }

    .namePosition {
      flex: 1 1;
      margin-left: space('Xxs');
      @include ellipsis();
      .name {
        @include type('LabelL');
        @include ellipsis();
      }
      .position {
        @include type('Hint');
        @include ellipsis();
        margin-top: 6px;
      }
    }

    .star {
      flex: 0 0 16px;
      line-height: 16px;
      height: 16px;
      margin-left: space('Xs');
    }

    .optionsMenu {
      margin-left: 4px;
      margin-top: 8px;
    }
  }
}
