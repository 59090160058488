@import '/src/styles-imports/shared';

.title {
  @include type('Xxs');
  white-space: normal;

  margin-bottom: space('Xs');
}

.items {
  .item {
    white-space: normal;

    &:not(:first-child) {
      margin-top: space('Xs');
    }

    .label {
      @include type('LabelL');
    }
    .value {
      @include type('Xxs');
      color: $colorPrimary1;
      margin-top: space('Xxs');
    }
    .hint {
      @include type('Hint');
      margin-top: space('Xxs');
    }
  }
}

.hints {
  margin-top: space('Xs');
  .hint {
    @include type('Hint');
    color: $colorGrey2;
    white-space: normal;

    margin-top: space('Xxs');
  }
}
