@import '/src/styles-imports/shared';

/* ASSESSMENT REPORT NEXT */
.assessmentReportNext {
  position: fixed;
  left: 0;
  top:0;

  width: 100%;
  height: 100vh;
  background-color: $colorWhite;
  animation: slideUp .3s ease-in-out;
  z-index: 9;

  /* CHILDREN */
  .body main {
    color: $colorGrey1;
    word-break: break-word;

    // Titles
    h1 {
      @include type('M');
      margin-bottom: space('L');
    }
    h2 {
      @include type('S');
      margin-bottom: space('M');
      color: $colorPrimary2;
    }
    h3 {
      @include type('Xs');
      margin-bottom: space('Xs');
      color: $colorPrimary2;
    }
    h4 {
      @include type('Xxs');
      margin-bottom: space('Xs');
    }

    // Misc
    ul {
      margin-left: 15px;
      li {
        margin-left: 0;
        list-style-type:  '\2022   '; // Small dot
      }
    }
  }

  .headerWrapper {
    @include type('LabelL');
    color: $colorPrimary1;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: center;
  }

  .header {
    @include basicGridNoMargins;
    padding: 0 60px;
    box-sizing: border-box;
    flex: 1;
    max-width: calc(min(#{$bpXl}, 100%));
    height: 60px;

    .titleSection {
      @include ellipsis();
      grid-column: 1/-2;
      display: flex;
      align-items: center;
      gap: space('Xxs');
    }

    .icon {
      @include iconWithBackgroundGrey;
    }
    .closeSection {
      grid-column-end: -1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .menu {
      display: none;
    }
    .title {
      flex: 1;
      @include ellipsis();
    }
  }

  .bodyWrapper {
    height: calc(100vh - #{$headerHeight} - #{$footerHeight});
    overflow-y: scroll;
    display: flex;
    justify-content: center;

    &.noFooterNavigation {
      height: calc(100vh - #{$headerHeight});
    }
    &.noSideNavigation {
      .body {
        main {
          grid-column: start / end;
        }
      }
    }
  }

  .body {
    @include basicGridNoMargins;
    box-sizing: border-box;
    flex: 1;
    padding: space('L') 60px;
    max-width: calc(min(#{$bpXl}, 100%));

    aside {
      grid-column: start/5;

      .asideContent {
        position: sticky;
        top: space('L');
        height: calc(100vh - 168px);
        overflow-y: auto;
        margin-bottom: -60px;

        h6 {
          @include type('Xxs');
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 2;
          padding-bottom: space('S');
        }
      }
    }

    main {
      grid-column: 6/end;
      margin-bottom: 60px; // Space for footer on iPhone/iPad
    }
  }

  .footerWrapper {
    border-top: 1px solid $colorGrey4;
    background-color: $colorWhite;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .footer {
    @include basicGridNoMargins;
    box-sizing: border-box;
    flex: 1;
    padding: 0 60px;
    max-width: calc(min(#{$bpXl}, 100%));
    height: 60px;

    aside {
      grid-column: start/5;
    }

    main {
      grid-column: 6/end;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include type('LabelL');
      color: $colorPrimary1;

      * {
        flex: 1;
        display: flex;
        align-items: center;
        @include ellipsis();

        &:hover {
          svg path {
            fill: $colorPrimary2 !important;
          }
        }
      }

      .pageTitle {
        display: block;
        padding: space('Xxxs');
        &.right {
          text-align: right;
        }
      }

      .pageArrow {
        max-width: 14px;
        svg path {
          fill: $colorPrimary1;
        }
      }
    }
  }

  .sidePanel {
    margin: 20px;
    margin-right: 0px;

    &.loading {
      margin-right: 20px;
    }

    h6 {
      @include ellipsis();
      @include type('LabelL');
      width: calc(100% - 60px);
      padding-bottom: 20px;
    }

    .collapsibleMenuWrapper {
      height: calc(100vh - 60px);
      overflow-y: auto;
      padding-right: 20px;
    }
  }

  /* RESPONSIVE */

  @include mqSmaller($bpL) {
    aside {
      display: none;
    }
    .body, .footer {
      & main {
        grid-column: start/end;
      }
    }
    .body main {
      padding-bottom: space('L');
    }
    .menu {
      display: inherit !important;
    }
  }

  @include mqSmaller($bpM) {
    .body, .footer {
      & main {
        grid-column: start/end;
      }
    }
  }

  @include mqSmaller($bpS) {
    .body {
      padding: space('L') space('Xs');
    }
    .header {
      padding: 20px space('Xxs');
    }
    .footer, .header {
      padding: 0 space('Xs');
    }
  }
}
