@import '/src/styles-imports/shared';

/* PLAN EXPIRED */
.planExpired {
  @include basicGridNoMargins;
  
  > div {
    margin-top: calc(#{$spaceXl} + #{$headerHeight});

    @include mqSmaller($bpS) {
      margin-left: $spaceXs;
      margin-right: $spaceXs;
    }
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
