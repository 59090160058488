@import '/src/styles-imports/shared';

/* InstanceCreation */
.instanceCreation {
  @include basicGrid;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .title {
    grid-column: 4/10;

    @include type('M');
    text-align: center;

    @include mqSmaller($bpM) {
      grid-column: 2/8;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
    }
  }

  .content {
    grid-column: 5/9;
    margin-top: space('M');

    @include mqSmaller($bpM) {
      grid-column: 3/7;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
    }

    .description {
      @include type('CopyStrong');
      text-align: center;
    }

    .errorMessage {
      @include type('Copy');
      color: $colorDanger1;
      text-align: center;

      padding-bottom: space('Xs');
    }

    //ProgressBar
    & > div:last-child {
      margin-top: space('M');
    }
  }
}
