@import '/src/styles-imports/shared';

/* QuestionFreeText */
.questionFreeText {
  @include basicGrid;
  word-break: break-word;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  &.page1Animation {
    @include animation(moveUp);
    animation-fill-mode: forwards;

    @keyframes moveUp {
      from {
        opacity: 1;
      }
      to {
        transform: translateY(-30px);
        opacity: 0;
      }
    }
  }

  .gridContent {
    grid-column: 4 / 10;

    margin-bottom: $spaceM;

    @include mqSmaller($bpM) {
      grid-column: 2 / 8;
    }

    @include mqSmaller($bpS) {
      grid-column: start / end;
    }

    & > button {
      display: block;
      margin: $spaceM auto 0;
    }

    .questionDescription {
      max-width: 750px;
      margin: auto;
      margin-top: $spaceXs;

      @include type('CopyStrong');
    }

    .questionInput {
      margin-top: $spaceM;
      text-align: left;
    }

    .infoCardWrapper {
      margin-top: $spaceM;
      text-align: left;
    }

    .bottomText {
      display: flex;
      justify-content: space-between;
      margin-top: $spaceXxs;

      .error {
        @include type('Hint');
        color: $colorDanger1;
      }
      .hint {
        flex: 1 0;

        @include type('Hint');
        padding-left: 8px;

        text-align: right;
      }
    }
  }

  .skip {
    color: $colorGrey2;
    font-size: $typeSizeCopyStrong;
    margin: space('M') 0;
    cursor: pointer;
  }
}

.page2Animation {
  @include animationLong(slideUp);
  animation-fill-mode: forwards;
}
