@import '/src/styles-imports/shared';

/* RUN TABLE */
.runTable {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .title {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    @include mqSmaller($bpS) {
      flex-direction: column;
    }
  }

  .table {
    margin-top: 12px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 30px repeat(3, auto) 36px;

    .row {
      display: contents;
      > div {
        padding: 16px 8px;
        border: inherit;
        @include ellipsis();
      }

      &.selectable {
        border-top: 1px solid $colorGrey3;
        cursor: pointer;
        @include transition(background-color, border);
        &:last-child {
          border-bottom: 1px solid $colorGrey3;
        }

        &:hover {
          border-top: 1px solid $colorPrimary3;
          &:last-child {
            border-bottom: 1px solid $colorPrimary3;
          }
          + div {
            border-top: 1px solid $colorPrimary3;
          }

          > div {
            background-color: $colorPrimary4;
            color: $colorPrimary1;
            path {
              fill: $colorPrimary1;
            }
          }
        }
      }
    }

    .bold {
      font-weight: $typeWeightXxl;
    }

    .right {
      text-align: right;
    }

    .highlight {
      background-color: $colorPrimary4;
    }

    .activeDot {
      margin-top: 3px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: $colorPrimary3;
    }

    .arrow {
      margin-top: 2px;
      margin-bottom: -2px;
    }
  }
}
