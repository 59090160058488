@import '/src/styles-imports/shared';

/* CUSTOM SCROLLABLE */
.customScrollable {
  width: 100%;
  overflow: hidden;
  margin: space('Xs') 0;
  padding: 0;
  outline: none;
  cursor: grab;
  user-select: none;
  @include transition(opacity);
  &.isStatic {
    cursor: initial;
  }
  &.dragging {
    cursor: grabbing;
  }

  .slider {
    overflow: hidden;
    display: flex;
    scroll-snap-type: initial;
    scroll-behavior: initial;
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    -webkit-overflow-scrolling: touch;
    &.snap {
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
    }
    > * {
      scroll-snap-align: start;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
