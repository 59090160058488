@import '/src/styles-imports/shared';

.attachmentsCard {
  width: auto;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 2px 0;

    a {
      color: $colorPrimary1;
      text-decoration: none;
      display: block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      span {
        padding-left: 8px;
      }

      &:hover {
        text-decoration: underline;
      }

      svg {
        transform: translate(0, 2px);
      }
    }
  }
}

.addButton {
  margin-top: 20px;
}

.header {
  @include type("LabelL");
  padding: space('S') space('Xs') !important;
  white-space: pre-wrap;
}
