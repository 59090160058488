@import '/src/styles-imports/shared';

/* SIMPLE LINE DIAGRAM */
.simpleLineDiagram {
  display: flex;
  align-items: center;
  gap: $spaceXs;
  line-height: 0;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    flex-direction: column-reverse;
  }

  /* CHILDREN */
  > div {
    flex: 1;
    width: 100%;
  }

  .textContent {
    display: flex;
    gap: $spaceXxs;
    align-items: center;
    @include type('Copy');
    text-align: right;

    .blue {
      white-space: nowrap;
      color: $colorPrimary1;
    }

    @include mqSmaller($bpS) {
      justify-content: space-between;
    }
  }
}
