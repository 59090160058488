@import '/src/styles-imports/shared';

.nineLevelsResult {
  background-color: $colorWhite;

  .container {
    border: 1px solid $colorGrey4;
    border-radius: 10px;
    padding: 32px;
    box-sizing: border-box;
    width: 100%;
  }

  .mainHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      cursor: pointer;
      @include transition(fill);

      &:hover {
        g path {
          fill: $colorPrimary1;
        }
      }

      @include mqSmaller($bpM) {
        margin-left: space('Xxs');
      }
    }
  }

  .pendingText {
    @include type('Copy');
    color: $colorGrey1;
    margin-top: space('S');
  }
  .rows {
    .row {
      @include type('Copy');
      margin-top: space('Xs');

      &:first-child {
        margin-top: space('S');
      }

      .header {
        width: 100%;
        position: relative;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          position: absolute;
          right: 0;
          cursor: pointer;
        }
      }
      .diagram {
        margin-top: space('Xxs');
      }
      .footer {
        display: flex;
        justify-content: space-between;

        @include type('Hint');
        color: $colorGrey2;
      }
    }
  }

  .modalHeaderContent {
    display: flex;
    align-items: center;

    & > div {
      width: 32px;
      height: 16px;
      border-radius: 8px;
    }
    span {
      padding-left: space('Xxs');
    }
  }

  .noDataText {
    @include type('Copy');
    margin-top: space('Xs');
  }

  .footerText {
    @include type('Hint');
    color: $colorPrimary1;
    margin-top: space('M');
  }

  button {
    display: block;
    margin-top: space('S');
    margin-left: auto;
  }

  .copyright {
    margin-top: space('S');

    @include type('Hint');
    color: $colorPrimary1;
    cursor: pointer;
    @include transition(color);

    &:hover {
      color: $colorPrimary2;
    }
  }

  &.forReport {
    .container {
      border: 0;
      padding: 0;
    }
    .copyright {
      display: none;
    }
    .showReport {
      display: none;
    }
  }

  @include mqSmaller($bpM) {
    .container {
      width: unset;
      padding: 24px 16px 32px 16px;
    }
    .rows {
      .row {
        flex-direction: column-reverse;
        position: relative;
        .points {
          position: absolute;
          width: 22px;
          right: 16px;
          top: 24px;
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    .container {
      width: unset;
      padding: 24px 16px 32px 16px;
    }
    .row {
      flex-direction: column-reverse;
      position: relative;
      .points {
        position: absolute;
        width: 22px;
        right: 16px;
        top: 24px;
      }
    }
  }

}


.modalContent {
  margin-top: space('S');
  .dimensionName {
    @include type('Copy');
    text-align: center;
  }
  .diagram {
    margin-top: space('Xs');
  }
  .footer {
    display: flex;
    justify-content: space-between;

    @include type('Hint');
    color: $colorGrey2;
  }
  .description {
    @include type('Copy');
    margin-top: space('S');
  }
  .blocks {
    margin-top: space('S');
    .block {
      border-top: 1px solid $colorGrey3;
      padding: space('Xs') 0;

      &:last-child {
        border-bottom: 1px solid $colorGrey3;
      }

      .label {
        @include type('LabelL');
      }
      .description {
        @include type('Copy');
        margin-top: space('Xxs');
      }
    }
  }
}
