@import '/src/styles-imports/shared';

/* Prices */
.prices {
  @include dialogIntermission;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .tableItems {
    margin-top: space('Xxs');

    .tableItem {
      display: flex;
      justify-content: space-between;
      padding: space('Xs') 0;
      border-bottom: 1px solid $colorGrey3;
      @include type('LabelL');

      & > span:first-child {
        padding-right: space('Xxs');
        white-space: pre-wrap;
      }
      & > span:last-child {
        max-width: 128px;
        white-space: pre-wrap;
        text-align: right;
      }
    }
  }

  .controls {
    margin-top: space('S');
  }

  .continueButton {
    display: block;
    margin-left: auto;
  }
}
