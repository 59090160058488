@import '/src/styles-imports/shared';

.editProfilePanel {
  padding: 62px 16px 48px 16px;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;

  .header {
    @include type('Xxs');
    text-align: center;
  }
  .infoBlock1 {
    margin-top: 24px;
    & > * {
      margin-top: 16px;
    }
  }
  .infoBlock2 {
    margin-top: 24px;
    .title {
      @include type('LabelL');
    }
    .selects {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      .select {
        width: calc((100% / 2) - 8px);
      }
    }
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 24px;
    button {
      margin-bottom: 16px;
    }
    button:last-child {
      margin-left: 16px;
    }
  }
}
