@import '/src/styles-imports/shared';


.infoModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: zIndex('Modal');
  background-color: rgba(0, 0, 0, 0.1);
  animation: fadeIn .3s ease-out;
  &.isClosing {
    animation: fadeOut .3s ease-out;
  }
  .modal {
    box-sizing: border-box;
    background-color: white;
    position: fixed;
    left: 50%;
    top: 50%;
    max-height: 90%;
    overflow-y: auto;
    transform: translate(-50%, -50%);
    padding: 24px 32px 32px;
    border-radius: 10px;
    animation: loadModal .3s ease-out;

    &:focus {
      border: none;
      outline: none;
    }

    &.isClosing {
      animation: closeModal .3s ease-out;
    }
    .header {
      @include type('LabelL');
      padding-bottom: 8px;
      user-select: none;
      width: 328px;
    }
    .body {
      @include type('Copy');
      color: $colorGrey1;
      width: 328px;
      text-align: left;
    }
    .footer {
      display:flex;
      justify-content: flex-end;
      padding-top: space('S');
      &.centered {
        justify-content: center;
      }
    }
    .hint {
      @include type('Hint');
      margin-top: space('S');
    }
  }

  @include mqSmaller($bpM) {
    .modal {
      .header {
        width: 256px;
      }
      .body {
        width: 256px;
      }
    }
  }

  @include mqSmaller($bpS) {
    .modal {
      padding: space('S') space('Xs');
      .header {
        width: 256px;
      }
      .body {
        width: 256px;
      }
    }
  }
}
