@import '/src/styles-imports/shared';

.enter {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(15px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  pointer-events:none;
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  pointer-events:none;
  opacity: 0 !important;
  transform: translateY(15px) !important;
  @include transition(transform, opacity);
}

/* DynamicFilter */
.dynamicFilter {
  width: inherit;

  ::before {
    background: linear-gradient(to right, #fbfbfb, #fbfbfb 50%, rgba(#fbfbfb, 0));
  }
  ::after {
    background: linear-gradient(to left, #fbfbfb, #fbfbfb 50%, rgba(#fbfbfb, 0));
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .pillContainer {
    display: flex;
    gap: space('M');

    .resetFilters {
      display: flex;
      align-items: center;
      gap: space('Xxs');
      cursor: pointer;
      color: $colorGrey2;
      svg {
        width: 14px;
      }
    }
  }
}
