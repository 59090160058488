@import '/src/styles-imports/shared';

$iconSize: 20px;

/* NextAction */
.nextAction {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  @include dialogIntermission;

  .actions {
    margin-top: space('M');

    .action {
      position: relative;

      background-color: $colorWhite;
      border: 1px solid $colorGrey4;
      border-radius: 2px;
      cursor: pointer;
      @include transition('background-color');

      margin-top: space('Xs');
      padding: space('S') space('M');

      &:hover {
        background-color: $colorGrey5;
      }

      .left {
        width: calc(100% - #{$spaceM} - #{$iconSize});
        overflow: hidden;
      }
      .right {
        position: absolute;
        right: space('M');
        top: 50%;
        transform: translate(0, -50%);

        & > svg {
          width: $iconSize;
          height: $iconSize;
        }
      }
    }
  }

  .helpButton {
    margin-top: $spaceS;

    svg {
      height: 14px;
    }
  }
}
