@import '/src/styles-imports/shared';

/* DiscAssessmentEnd */
.discAssessmentEnd {
  background-color: $colorWhite;

  margin: 0 60px;
  text-align: center;

  @include mqSmaller($bpS) {
    margin: 0 space('Xs');
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .graphic {
    font-size: 48px;
    line-height: 48px;

    @include mqSmaller($bpM) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    margin-top: $spaceXs;
  }

  & > button {
    display: block;
    margin: $spaceS auto 0;
  }
}
