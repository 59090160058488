@import '/src/styles-imports/shared';

/* ARCH DIAGRAM */
.archDiagram {
  line-height: 0;
  transform: rotate(180deg);

  .background {
    fill: none;
    stroke: #D9D9D9;
  }
  .score {
    fill: none;
    stroke: $colorPrimary1;
    transition: stroke-dasharray .3s ease-in-out;
  }
}
