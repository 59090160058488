@import '/src/styles-imports/shared';

/* OverlayModal */
.overlayModal {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: zIndex('Modal');
  background-color: $colorWhite;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  //HEADER
  .header {
    @include type('LabelL');
    color: $colorPrimary1;
    padding: 20px 60px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    .close {
      cursor: pointer;
    }

    @include mqSmaller($bpS) {
      padding: 20px space('Xs');
    }
  }

  .scrollableContent {
    height: calc(100vh - #{$headerHeight} - #{$spaceL});
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;

    padding-bottom: $spaceL;

    &.hasFooter {
      height: calc(100vh - #{$headerHeight} - #{$footerHeight} - #{$spaceL});
    }
  }

  //FOOTER
  .footer {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;

    @include type('LabelL');
    color: $colorPrimary1;
    border-top: 1px solid $colorGrey4;
    background-color: $colorWhite;
  }

}
