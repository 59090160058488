@import '/src/styles-imports/shared';

.assessmentEnd {
  @include basicGrid;

  .gridContent {
    grid-column: 4 / 10;
    text-align: center;

    @include mqSmaller($bpM) {
      grid-column: 2 / 8;
    }

    @include mqSmaller($bpS) {
      grid-column: start / end;
    }

    & > button {
      display: block;
      margin: $spaceS auto 0;
    }
  }

  .graphic {
    font-size: 48px;
    line-height: 48px;

    @include mqSmaller($bpM) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  .indicator {
    margin-top: $spaceM;

    & > div {
      margin: 0 auto;
    }
  }

  .textContent {
    text-align: left;
  }

  .buttons {
    margin-top: space('M');

    & > button {
      display: block;
      margin: 0 auto;
    }

    & > button:not(:first-child) {
      margin-top: space('Xs');
    }
  }
}
