@import '/src/styles-imports/shared';

/* BipolarScaleDiagram */
.bipolarScaleDiagram {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .dimensionWrapper {
    display: flex;
    justify-content: space-between;
    > * {
      flex: 1;
    }
    .dimensionRange {
      span {
        color: $colorPrimary1;
      }
    }
    .dimension {
      padding-bottom: $spaceXs;
      text-align: center;
      &.bold {
        font-weight: 600;
      }
    }
    .dimensionScore {
      color: $colorPrimary1;
      text-align: right;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
    margin-top: space('Xxs');

    @include type('Hint');
    & > div:last-child {
      text-align: right;
    }
    .blue {
      color: $colorPrimary1;
    }
  }
}
