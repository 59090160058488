@import '/src/styles-imports/shared';

/* CREATE NEW RUN */
.createNewRun {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: zIndex('Modal');
  background-color: $colorWhite;

  //HEADER
  .header {
    @include type('LabelL');
    color: $colorPrimary1;
    padding: 20px 60px;
    height: 60px;
    box-sizing: border-box;
    border-bottom: 1px solid $colorGrey4;
    display: flex;
    justify-content: space-between;
    .close {
      cursor: pointer;

      display: flex;
      align-items: center;
  
      height: 100%;
      padding-left: $spaceXs;
  
      @include type('Copy');
      color: $colorPrimary1;
  
      &:hover {
        color: $colorPrimary2;
      }
    }
  }

  .scrollableContainer {
    height: calc(100vh - #{$headerHeight});
    overflow-y: auto;
    .gridContainer {
      @include basicGrid;
      .gridContent {
        grid-column: 4/10;
        padding-top: space('L');
        padding-bottom: space('L');
        animation: slideUp $animationDuration $animationFunction;
  
        @include mqSmaller($bpM) {
          grid-column: 2/8;
        }
        @include mqSmaller($bpS) {
          grid-column: start/end;
        }

        .menu {
          margin-top: space('M');
          > div {
            margin-bottom: space('Xs');
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          gap: space('Xs');
          margin-top: space('L');
        }
        .sectionTitle {
          font-size: 16px;
          margin-top: space('L');
          margin-bottom: space('Xxs');
          font-weight: 600;
        }
        .section {
          margin-bottom: space('S');
        }
        .sectionContent {
          margin-top: space('S');
        }
      }
    }
  }

  @include mqSmaller($bpS) {
    .header {
      padding: 20px space('Xs');
    }
    .gridContent {
      margin-top: space('M') !important;
      margin-bottom: space('Xxl') !important;
    }
  }
}
