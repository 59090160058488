@import '/src/styles-imports/shared';

/* RoleMatchSummary */
.roleMatchSummary {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    .progress {
      right: space('Xs');
    }
    .title {
      width: calc(100% - 16px - 72px);
    }
  }

  /* CHILDREN */
  .marginBottomXs {
    @include type('LabelL');
    margin-bottom: space('Xs');
  }
  .matchingText {
    @include type('LabelL');
    margin-top: space('Xs');
  }

  .firstBlock {
    display: flex;
    .left {
      flex-grow: 1;
      padding-right: space('S');

      .title {
        @include type('LabelL');
        padding-bottom: space('Xxs')
      }
      a, span.notLink {
        display: block;
        margin-top: space('Xs');
        @include type('Copy');
        color: $colorPrimary1;
        cursor: pointer;
        text-decoration: none;
        svg {
          margin-left: space('Xxs');
        }
      }
      span.notLink {
        color: $colorGrey2;
        pointer-events: none;
      }
    }
    .progress {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}
