@import '/src/styles-imports/shared';

.resetPasswordRequestSuccessForm {
  width: 100%;
  color: $colorGrey1;
  @include type('M');
  .paragraph {
    @include type('CopyStrong');
    padding-top: 16px;
  }
  .paragraph2 {
    @include type('Copy');
    padding: 16px 0 0;
  }

  @include mqSmaller($bpM) {
    .paragraph2 {
      display: none;
    }
  }
  @include mqSmaller($bpS) {
    text-align: center;
    .paragraph2 {
      display: none;
    }
  }
}
