@import '/src/styles-imports/shared';

/* PeerGroupDescription */
.peerGroupDescription {
  margin-top: $spaceXs;

  &:first-child {
    margin-top: 0;
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
