@import '/src/styles-imports/shared';

/* MYCOACHING */
.myCoaching {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .title {
    @include type('Xxs');
    color: $colorGrey1;
  }

  .cards {
    display: flex;
    gap: $spaceXs;
    margin-top: $spaceS;

    .left {
      width: 66%;

      & > div:not(:first-child) {
        margin-top: $spaceXs;
      }
    }
    .right {
      width: 34%;

      & > div:not(:first-child) {
        margin-top: $spaceXs;
      }
    }

    @include mqSmaller($bpS) {
      flex-direction: column;

      .left, .right {
        width: 100%;
      }
    }
  }
}
