@import '/src/styles-imports/shared';

.dragAndDrop {
  width: 100%;

  display: flex;
  justify-content: space-between;

  > div {
    width: 50%;
    box-sizing: border-box;
  }

  @include mqSmaller($bpS) {
    flex-direction: column-reverse;

    > div {
      width: 100%;
    }

    > div:first-child {
      padding: space('Xs') space('Xxs') 0 space('Xxs');
    }
  }

  .left {
    margin-right: 8px;

    @include mqSmaller($bpS) {
      margin-right: 0;
    }
  }

  .right {
    min-height: 152px;
    margin-left: 8px;
    box-sizing: border-box;
    border: 1px solid $colorGrey3;
    border-radius: 8px;

    align-self: baseline;

    @include mqSmaller($bpS) {
      margin-left: 0;
    }

    .placeholder {
      width: 100%;
      padding: 0 36px space('Xs');
      box-sizing: border-box;

      @include type('Hint');
      color: $colorGrey2;
      text-align: center;
    }

    .label {
      margin-top: space('S');
      text-align: center;
      @include type('Hint');
      color: $colorGrey2;
      text-align: center;

      svg {
        margin-right: space('Xxxs');
        transform: rotate(-90deg);
        vertical-align: middle;
        polygon {
          fill: $colorGrey2;
        }
      }
    }

    .selectedItems {
      padding: space('Xxs');
      box-sizing: border-box;
    }

  }
}

.item {
  cursor: grab;
  user-select: none;

  position: relative;
  padding: 10px 16px;
  border-radius: 4px;
  background-color: $colorGrey5;
  margin-top: space('Xxs');
  margin-bottom: space('Xxs');

  display: flex;
  justify-content: space-between;
  align-items: center;

  animation: fadeIn $animationDuration $animationFunction;

  @include transition(box-shadow);

  svg {
    flex-shrink: 0;
    cursor: pointer;
  }

  &:focus {
    border: none;
    outline: none;
    box-shadow: none;
  }
}
.item:first-child {
  margin-top: 0;
}
.item:last-child {
  margin-bottom: 0;
}
