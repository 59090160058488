@import '/src/styles-imports/shared';

.profileDeleted {
  @include basicGrid;

  .content {
    margin-top: 176px;
    grid-column: 5/9;
    animation: loginSlideIn .300s ease-out;

    @include mqSmaller($bpM) {
      grid-column: 3/7;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
      text-align: center;
      margin-top: 92px;
    }
  }
}
