@import '/src/styles-imports/shared';

/* COACHHUBHEADER */
.coachHubHeader {

  user-select: none;

  height: 100%;

  box-sizing: border-box;
  border-bottom: 1px solid $colorGrey4;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .firstLevel {
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: $headerHeightMedium;

    box-sizing: border-box;
    @include gridMarginsAsPaddings;

    @include mqSmaller($bpS) {
      height: $headerHeightXs;
    }
  }

  .secondLevel {
    @include gridMarginsAsPaddings;
  }

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    display:flex;
    align-items: center;
    justify-content: right;

    //language: switch dropdown
    & > div:last-child {
      margin-left: $spaceS;
    }
  }

  .logo {
    height: 30px;
  }

  .backToCoachHub {
    display: flex;
    align-items: center;

    @include transition(fill, color);

    & > svg path {
      fill: $colorPrimary1;
    }

    & > span {
      color: $colorPrimary1;
      padding-left: $spaceXxs;
    }

    &:hover {
      cursor: pointer;

      & > svg path {
        fill: $colorPrimary2;
      }

      & > span {
        color: $colorPrimary2;
      }
    }
  }

}
