@import '/src/styles-imports/shared';

$dashboardsNavBar: 60px;
$headerXs: 142px;

/* Dashboards */
.dashboardsWrapper {
  position: relative;
}

.dashboards {
  position: absolute;
  width: calc(100% + #{$gridMarginLarge * 2});
  left: -($gridMarginLarge);
  top: -($spaceL);

  @include mqSmaller($bpS) {
    width: calc(100% + #{$gridMarginSmall * 2});
    left: -($gridMarginSmall);
    top: -($spaceS)
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .navBar {
    height: 60px;
    display: flex;
    align-items: center;

    background-color: $colorPrimary4;
    padding: 0 60px;

    @include mqSmaller($bpS) {
      padding: 0 $spaceXs;
    }

    // PillGroup, Scrollable before, after contents
    & > div:before, & > div:after,
    & > div > div:before, & > div > div:after {
      width: 0;
    }
  }

  & > div > iframe {
    width: 100%;
    height: calc(100vh - #{$headerHeightLarge} - #{$footerHeight});
    border: none;
    border-bottom: 1px solid $colorGrey4;

    @include mqSmaller($bpS) {
      height: calc(100vh - #{$headerXs} - #{$footerHeightXs});
    }
  }

  &.withNavBar {
    & > div > iframe {
      height: calc(100vh - #{$headerHeightLarge} - #{$dashboardsNavBar} - #{$footerHeight});
      @include mqSmaller($bpS) {
        height: calc(100vh - #{$headerXs} - #{$dashboardsNavBar} - #{$footerHeightXs});
      }
    }
  }
}
