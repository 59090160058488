@import '/src/styles-imports/shared';

/* RESET ASSESSMENT HANDLE */
.resetAssessmentHandle {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .content {
    color: $colorRed1;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: $spaceXxs;

    &.disabled {
      cursor: initial;
      pointer-events: none;
      opacity: 0.5;
    }
  }
}
