@import '/src/styles-imports/shared';

/* MyStrengthProfile */
.myStrengthProfile {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .loading {
    margin-top: space('Xs');
  }

  .assessmentsInfo {
    grid-column: 5/13;
    overflow: hidden;

    @include animationLong(slideUp);
    animation-fill-mode: forwards;

    .wellBeingContainer {
      margin-bottom: space('M');

      & > div:last-child {
        margin-top: space('Xs');
      }
    }

    .assessmentTeasers {
      padding-bottom: space('M');
    }

    .titleBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .scrollingButtons {
      display: flex;

      & > div:first-child {
        margin-right: space('Xxs');
      }

      @include mqSmaller($bpS) {
        display: none;
      }
    }

    .title {
      color: $colorGrey1;
    }

    .assessment, .teaser {
      margin-top: space('Xs');
      & > div:first-child {
        height: 100%;
        & > div:first-child {
          height: 100%;
          & > div:first-child {
            height: 100%;
          }
        }
      }
    }

    .teaser {
      min-width: 100%;
      margin-right: space('Xs');
    }

    @include mqSmaller($bpM) {
      grid-column: 5/9;
    }

    @include mqSmaller($bpS) {
      grid-column: start/end;
      margin-top: space('M');
    }
  }

  .gridContainer {
    @include basicGridNoMargins;
    margin-top: $spaceXs;

    .left {
      grid-column: start / 9;
    }
    .right {
      grid-column: 9 / end;
    }
    .left, .right {
      & > :not(:first-child) {
        margin-top: $spaceXs;
      }
    }

    @include mqSmaller($bpM) {
      .left, .right {
        grid-column: start / end;
      }
      .right {
        margin-top: 0;

        & > div {
          margin-top: $spaceXs;
        }
      }
    }
  }
}
