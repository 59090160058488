@import '/src/styles-imports/shared';

/* ExtraCard */
.extraCard {
  min-height: 244px;
  box-sizing: border-box;
  padding: $spaceS $spaceS $spaceM;

  background-repeat: no-repeat;
  background-position-x: right;

  /* INTERACTION */

  /* MODIFIERS */
  &.coaching-booking {
    background-image: url('../keyvisuals/coaching.svg');
  }
  &.upselling {
    background-image: url('../keyvisuals/upselling.svg');
  }


  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    padding: $spaceXs $spaceXs $spaceS;
  }

  /* CHILDREN */
  .content {
    width: 70%;
  }

  & > button {
    display: block;
    margin-top: $spaceXs;
    margin-left: auto;
  }
}
