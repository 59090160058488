@import '/src/styles-imports/shared';

/* AssessmentsList */
.assessmentsList {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .rowReset {
    margin-top: space('Xs');
  }
  .row {
    padding: space('Xs');
    border-bottom: 1px solid $colorGrey3;

    &:first-child {
      border-top: 1px solid $colorGrey3;
      margin-top: space('Xs');
    }

    .copy {
      @include type('Copy');
      color: $colorGrey2;
      margin-top: space('Xxs');

      // Remove blu-markdown bottom spacing
      > div {
        line-height: 0;
        p {
          line-height: 20px;
        }
      }
    }

    & > button {
      display: block;
      margin-left: auto;
      margin-top: space('Xs');
    }
  }
}
