@import '/src/styles-imports/shared';

@mixin cardListing {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* RECOMMENDATIONS */
.recommendations {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .skeleton {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: space('Xs');
    margin-top: space('Xs');
    @include mqSmaller($bpM) {
      grid-template-columns: 1fr;
      gap: space('M');
    }
  }

  .container {
    @include basicGridNoMargins;
    position: relative;
    margin-top: 12px;

    @include mqSmaller($bpM) {
      display: flex;
      flex-direction: column;
      gap: space('M');
    }

    .sectionTitle {
      @include type('Xxs');
    }

    .missing {
      color: $colorGrey2;
    }

    .saved {
      grid-column: 1/9;

      .savedIntro {
        margin: space('Xs') 0;
      }
      .cardListing {
        @include cardListing;
      }
      .savedArchived {
        margin-top: 36px;
      }
    }

    .new {
      grid-column: 9/13;

      .missing {
        margin-left: 0;
      }

      .recommendationPage {
        margin-right: space('Xs');
        min-width: 100%;
        @include cardListing;

        > * {
          white-space: initial;
        }
      }
    }
  }
}
