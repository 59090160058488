@import '/src/styles-imports/shared';

/* LegalText */
.legalText {
  @include type('Copy');
  margin-top: space('S');

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
