@import '/src/styles-imports/shared';

.wellBeingResult {
  width: 100%;
  color: $colorGrey1;

  .wellBeingIndexPanel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: space('S');

    @include mqSmaller($bpM) {
      button {
        display: none;
      }
    }

    @include mqSmaller($bpS) {
      button {
        display: none;
      }
    }
  }

  .wellBeingIndex {
    display: flex;
    align-items: center;

    .title {
      @include type('Copy');
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .index {
      @include type('LabelL');
      color: $colorPrimary1;
      padding-left: 12px;
      white-space: nowrap;
    }

    .progress {
      margin-left: space('Xxs');
    }
  }

  .chart {
    margin-top: space('Xs');

    .controls {
      display: flex;
      align-items: center;
      & > div {
        flex: 1;
        &:last-child {
          @include type('Copy');
          color: $colorGrey2;
          text-align: right;
          z-index: zIndex('Buffer1');
        }
      }
    }
  }

  .wellBeingInfoPreHeader {
    svg {
      path {
        fill: $colorPrimary1;
      }
    }
  }

  .wellBeingInfoHeader {
    .index {
      color: $colorPrimary1;
      margin-top: space('Xxs');
    }
  }

  .calloutBlock {
    margin-top: space('Xs');
    text-align: center;
  }

  .xsVisibleButton {
    display: none;
    margin-top: space('S');

    button {
      display: block;
      margin-left: auto;
    }

    @include mqSmaller($bpM) {
      display: block;
    }

    @include mqSmaller($bpS) {
      display: block;
    }
  }
}
