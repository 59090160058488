@import '/src/styles-imports/shared';

/* SHARELINK */
.shareLink {
  svg {
    margin-right: space('Xxs');
    margin-bottom: -2px;
    transition: fill 300ms ease;
  }
  :hover svg {
    fill: $colorPrimary2;
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}

.linkContainter {
  margin-top: space('S');
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: space('Xs');

  > a {
    @include ellipsis();
  }
  > button {
    flex-shrink: 0;
  }
}
