@import '/src/styles-imports/shared';

/* BillingData */
.billingData {
  margin-bottom: space('M');

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  @include dialogIntermission;

  .controls {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;

    & > button {
      margin-top: space('S');
    }
  }
}
