@import '/src/styles-imports/shared';

/* ResultsInDetail */
.resultsInDetail {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .blue {
    color: $colorPrimary2;
  }
  // change DimensionResult main title color
  [class^=PeerBreakdown_dimension] > div:first-child > span:first-child {
    color: $colorPrimary2;
  }
}
