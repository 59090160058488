@import '/src/styles-imports/shared';

.startNewEvaluationLink {
  .content {
    display: flex;
    align-items: center;
    gap: $spaceXxs;

    color: $colorPrimary1;
    cursor: pointer;

    margin-top: $spaceXs;

    svg path {
      fill: $colorPrimary1;
    }

    @include transition(color, fill);

    &.disabled {
      pointer-events: none;
      color: $colorPrimary3;
      svg path {
        fill: $colorPrimary3;
      }
    }

    &:hover {
      color: $colorPrimary2;
      svg path {
        fill: $colorPrimary2;
      }
    }
  }

}
