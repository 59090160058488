@import '/src/styles-imports/shared';

/* TableCard */
.tableCard {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .label {
    @include type('LabelL');
  }

  .subHeader {
    margin-top: space('Xs');

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .labels {
      flex: 1;
      margin-right: space('Xs');
      white-space: nowrap;
      padding-bottom: space('S');
      .subLabels {
        margin-top: space('Xxs');
        @include type('Hint');

        a span {
          @include type('Hint');
        }
      }
    }

    .controls {
      display: flex;
      align-items: center;
      padding-bottom: space('S');

      button {
        margin-right: space('Xs');
      }
    }
  }

  //COLUMNS
  .columns {
    .header {
      display: flex;

      padding-bottom: space('Xs');
      @include type('LabelL');

      .headerItem {
        flex: 1;
        &:first-child {
          margin-right: space('Xs');
        }
      }
    }
    .row {
      display: flex;
      border-bottom: 1px solid $colorGrey4;

      padding: space('Xs') 0;
      @include type('Copy');

      &:first-child {
        border-top: 1px solid $colorGrey4;
      }

      .rowItem {
        flex: 1;
        &:first-child {
          margin-right: space('Xs');
        }

        .hint {
          @include type('Hint');
          color: $colorGrey2;
        }
        .copy {
          @include type('Copy');
          margin-top: space('Xxs');
        }
      }
    }
  }
}
