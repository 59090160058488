@import '/src/styles-imports/shared';

.stringResults {
  display: flex;
  flex-direction: column;
  gap: $spaceL;

  .dimension {
    .name {
      margin-bottom: $spaceXxs;
      @include type('Xs');
    }
    
    .description {
      margin-bottom: $spaceXs;
      @include type('Copy');
    }
    
    .commentLabel {
      margin-bottom: $spaceXs;
      @include type('Xxs');
    }
  }

  .peerGroups {
    display: flex;
    flex-direction: column;
    gap: $spaceXs;
  }

  .peerGroupName {
    font-weight: 600;

    .title {
      margin-bottom: $spaceXs;
    }

    ul li {
      list-style-type: '»  ' !important;
      font-weight: initial;
  
      &:not(:last-child) {
        margin-bottom: $spaceXxs;
      }

      &::marker { 
        color: $colorPrimary1;
      }
    }
  }
}
