@import '/src/styles-imports/shared';

.assessmentsResultsSkeleton {
  width: 100%;
  margin-top: space('M');
  animation: loadAssessmentResult 1.5s infinite ease;

  @include mqSmaller($bpS) {
    width: 100%;
  }

  .bigHeader, .smallHeader {
    width: 100%;
    height: 32px;
    background-color: $colorGrey4;
    border-radius: 16px;
  }
  .smallHeader {
    height: 28px;
    margin-top: space('M');

    &:nth-child(even) {
      width: 75%;
    }
  }
  .bigBlock, .smallBlock {
    width: 66%;
    height: 14px;
    background-color: $colorGrey4;
    border-radius: 12px;
    margin-top: space('Xs');
  }
  .smallBlock {
    width: 38%;
  }

  .content {
    margin-top: space('Xl');

    .bigHeader, .smallHeader {
      width: 100%;
      height: 32px;
      background-color: $colorGrey4;
      border-radius: 16px;
    }
    .smallHeader {
      height: 28px;
      margin-top: space('M');

      &:nth-child(even) {
        width: 75%;
      }
    }
    .bigBlock, .smallBlock {
      width: 66%;
      height: 14px;
      background-color: $colorGrey4;
      border-radius: 12px;
      margin-top: space('Xs');
    }
    .smallBlock {
      width: 38%;
    }
  }
}
