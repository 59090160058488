@import '/src/styles-imports/shared';

/* StripePaymentElement */
.stripePaymentElement {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */

  .billingCycle {
    margin-top: space('S');

    & > label {
      margin-top: space('Xs');
    }
  }

  .controls {
    display: flex;
    justify-content: space-between;
    margin-top: space('S');
  }
}
