@import '/src/styles-imports/shared';

$formattingHeight: 36px;

/* TEXTEDITOR */
.textEditor {


  /* INTERACTION */
  &:hover, &.focussed {
    .box {
      background-color: white !important;
      // animate box shadow
      &:after {
        opacity: 1;
      }
    }
  }

  /* MODIFIERS */
  &.empty .box {
    background-color: $colorGrey4;
  }
  &.disabled {
    pointer-events: none;
  }
  &.focussed  {
    .box {
      padding-bottom: space('Xs');
    }
    // show formatting
    .formattingBar {
      opacity: 1;
      @include transitionLong(opacity);
    }
    // increase height of conent
    :global(.public-DraftEditor-content) {
      margin-top: 52px;
      min-height: 36px;
    }
    // show buttons
    .buttons {
      position: relative;
      & > * {
        opacity: 1;
        @include transitionLong(opacity);
      }
    }
  }
  &.hidePlaceholder {
    :global(.public-DraftEditorPlaceholder-root) {
      display: none;
    }
  }

  /* RESPONSIVE */

  /* CHILDREN */
  .box {
    cursor: text;

    position: relative;
    padding: space('Xs');

    border-radius: $inputBorderRadius;

    @include transition(background-color);

    // box shadow (smoothly animatable)
    &:after {
      pointer-events:none;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 2px;
      box-shadow: $shadowDark;
      opacity: 0;
      @include transition(opacity);
    }

  }

  .formattingBar {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    padding: space('Xs');
    opacity: 0;
  }
  .editorContainer {
    cursor: text;
    position: relative;

    // :global(.public-DraftEditorPlaceholder-root),
    // :global(.public-DraftEditor-content) {
    //   margin: 0 -#{space('Xs')} -#{space('Xs')};
    //   padding: space('Xs');
    // }
    :global(.public-DraftEditorPlaceholder-root) {
      position: absolute;
      top: 0;
      left: 0;
      max-width: 100%; // for ellipsis
      color: $colorGrey2;
    }
    :global(.public-DraftEditorPlaceholder-inner) {
      // ellipsis
      white-space: nowrap !important;
      overflow: hidden;
      text-overflow: ellipsis;
    }


    :global(.DraftEditor-root) {
      caret-color: $colorPrimary1;;
    }

    // LIST STYLING
    ul, ol {
      padding-inline-start: 26px;
      margin-block-start: 8px;
      margin-block-end: 16px;
    }
    li {
      padding-left: 4px;
    }
    ol {
      padding-inline-start: 32px;
      li {
        padding-left: 0px;
      }
    }
    :global(.public-DraftStyleDefault-depth0) {
      margin-left: 0em !important;
    }
    :global(.public-DraftStyleDefault-depth1) {
      margin-left: 2em !important;
    }
    :global(.public-DraftStyleDefault-depth2) {
      margin-left: 4em !important;
    }
  }

  .charCounter {
    margin-top: $spaceXxs;
    @include type('Copy');
    text-align: right;
  }

  .buttons {
    position: absolute;
    display: flex;
    justify-content: flex-end;

    margin-top: space('S');

    & > * {
      opacity: 0;
    }

    & > *:first-child {
      margin-right: space('Xxs');
    }
  }

  .controls {
    display: flex;
    margin-right: space('Xs');
    user-select: none;
  }
  .styleButton {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    box-sizing: border-box;
    height: $formattingHeight;
    width: $formattingHeight;
    margin-right: 4px;

    border-radius: 2px;
    color: #999;

    @include transition(background-color);

    svg path {
      fill: $colorPrimary1;
    }

    &:hover {
      background-color: $colorPrimary4;
    }

    &.active {
      background-color: $colorPrimary3;
    }
  }

}
