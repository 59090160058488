@import '/src/styles-imports/shared';

/* CandidateProfile */
.candidateProfile {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .imageBlock {
    display: flex;
    .left {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: $colorPrimary4;
      line-height: 75px;
      text-align: center;
      &.halfVisible {
        opacity: 50%;
      }
      img {
        width: 75px;
        height: 74px;
        border-radius: 50%;
      }
    }
    .right {
      margin-left: space('Xs');
      word-break: break-all;
      .name {
        @include type('Xxs');
        color: $colorGrey1;
      }
      .position {
        @include type('Copy');
        margin-top: space('Xxxs');
        color: $colorPrimary1;
      }
    }
  }

  .header {
    padding: space('S') space('Xs');
  }

  .listItem {
    padding: space('Xs') space('Xxs');

    //margins with negative values to decrease the margins of CollapsibleNext content
    margin-right: -(space('Xs'));
    margin-left: -(space('Xxs'));

    border-bottom: 1px solid $colorGrey4;

    &:first-child {
      border-top: 1px solid $colorGrey4;
    }

    .label {
      @include type('Hint');
      color: $colorGrey2;
    }
    .value {
      @include type('Copy');
      margin-top: space('Xxs');
      word-break: break-word;

      ul {
        margin-left: space('S');
      }
    }
  }

}
