@import '/src/styles-imports/shared';

.wellbeingDiagram {
  position: relative;

  /* CHILDREN */
  svg.chart {
    height: 100%;
    overflow: visible;

    // GRID
    .verticalLine {
      stroke: $colorGrey3;
      stroke-width: 1;
    }

    .horizontalLine {
      stroke: $colorGrey3;
      stroke-width: 1;
    }
    // SVGFrame
    &.svgFrame {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
    }

    // POLYGON
    .polygon {
      fill: $colorPrimary4;
    }
    .polygonBlank {
      fill: $colorGrey5;
    }

    // CIRCLES
    .valueLine {
      stroke: $colorPrimary1;
      stroke-width: 1;
    }

    circle.smallCircle {
      cursor: pointer;
      pointer-events: none; // let bigCircle take care of hover
      fill: $colorWhite;
      stroke: $colorPrimary1;
      @include transition(fill);

      &.active {
        fill: $colorPrimary1;
      }
    }

    circle.bigCircle {
      cursor: pointer;
      fill: $colorPrimary1;
      @include transition(opacity);

      opacity: 0;

      &.active {
        opacity: 0.2;

        & + circle.smallCircle {
          fill: $colorPrimary1;
        }
      }

      &:hover {
        opacity: 0.2;

        & + circle.smallCircle {
          fill: $colorPrimary1;
        }
      }
    }
  }

  svg.chartFrame {
    pointer-events: none;

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0;

    @include animationLong(fadeIn);
    animation-delay: 800ms; // to time with Scrollable startRightTimeout
    animation-fill-mode: forwards;
  }

  .labels {
    position: relative;
    top: -24px;

    display: flex;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 0;

    @include type('Hint');
    color: $colorGrey1;
    text-align: center;
  }

  // Prevent chart scroll-y
  > div:first-child > div {
    overflow-y: hidden;
  }
}
