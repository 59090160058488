@import-normalize; /* bring in normalize.css styles */
@import '/src/styles-imports/shared';

html {
  width: 100%;
  height: 100vh; // fixing iOS15 100%/100vh problems with DialogSequence & AssessmentNext
  // seems to work fine on iOS14 with the additional AssessmenPageWith footer handling
  font-size: 16px;
  //smooth scrolling is needed for anchor and deep links
  scroll-behavior: smooth;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
  @include type('Copy');
  color: $colorBlack;

  overscroll-behavior-y: none;
  overscroll-behavior-x: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: -webkit-fill-available;

  // remove iOS grey highlight when tapping links (and inputs)
  -webkit-tap-highlight-color: rgba(0,0,0,0); // https://css-tricks.com/snippets/css/remove-gray-highlight-when-tapping-links-in-mobile-safari/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* TYPOGRAPHY */
@import 'src/styles-globals/fonts.scss';

/* FORMS */
@import 'src/styles-globals/forms.scss';

/* LAYOUT */
@import 'src/styles-globals/layout.scss';

/* SPACINGS */
@import 'src/styles-globals/spacings.scss';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



/* COLORS */

.blu-colorPrimary1 {
  color: $colorPrimary1;
}


/* OTHERS */
hr {
  border-top: 1px solid $colorBlack;
}
hr.light {
  border-top: 1px solid $colorWhite;
}

/* GLOBAL TOOLS */
.blu-highlight {
  background-color: $colorPrimary3;
}

.blu-markdown {

  // hint: the headings in the concept don't seem to follow the type system
  h1 {
    @include type('S');
    margin-bottom: space('L') - 40px; // necessary for anchor shift
  }
  h2 {
    // shift anchors down to compensate header height
    // https://stackoverflow.com/questions/10732690/offsetting-an-html-anchor-to-adjust-for-fixed-header
    @include type('Xxs');
    padding-top: space('M') + $headerHeight;
    margin-top: -$headerHeight;
    @include mqSmaller($bpS) {
      padding-top: space('M') + $headerHeight;
      margin-top: -$headerHeight;
    }
  }

  h3, h4, h5 {
    margin-top: space('S') - 7px;
    @include type('LabelL');
  }

  a {
    @include type('Copy');
    color: $colorPrimary1;
    cursor: pointer;
    text-decoration: none;
  }

  // spacings for paragraphs and lists
  p, ul {
    margin-top: space('Xxs');
  }
  li > ul { // no margin-top for nested lists
    margin-top: 0;
  }

  // lists
  ul {
    margin-left: 15px;
  }
  li {
    margin-left: 15px;
  }

  hr {
    border-top: 1px solid $colorWhite;
  }

  blockquote {
    margin-left: 20px;
    padding-left: 20px;
    border-left: 2px solid $colorGrey3;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
  p[data-blu='image-container'] {
    display: flex;
    justify-content: center;
    img {
      margin: 12px;
    }
  }
}
.blu-markdown-copy-strong {
  .blu-markdown {
    a, p, li {
      @include type('CopyStrong');
    }
  }
}

.error {
  @include type('Copy');
  color: $colorDanger1;
  word-break: break-word;
}


// allow code highighting but don't break storybook error screens
code:not(#error-stack):not(.hljs) {
  background: $colorPrimary3;
  border-radius: 5px;
  padding: 1px 5px 1px 5px;
  // border: 1px solid $colorPrimary1;
  font-size: inherit;
}
