@import '/src/styles-imports/shared';

/* RUN SCOPE */
.runScope {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .description {
    margin-top: $spaceXs;
  }

  .table {
    margin: 36px 0 12px 0;
    .addition {
      display: flex;
      align-items: center;
      padding: space('Xs');
      border-top: 1px solid $colorGrey4;
      &:last-child {
        border-bottom: 1px solid $colorGrey4;
      }
      &.clickable {
        @include transition(background-color);
        &:hover {
          background-color: $colorPrimary4;
          cursor: pointer;
        }
      }

      .imgCircle {
        flex-shrink: 0;
      }
  
      .text {
        flex: 1 1;
        margin-left: space('Xxs');
        .title {
          @include type('LabelL');
          @include ellipsis();
        }
        .subtitle {
          margin-top: 6px;
          @include type('Hint');
          @include ellipsis();
        }
      }
    }
  }

  .add {
    margin-top: $spaceM;
  }

  .openLink {
    margin-top: $spaceS;
    > :first-child {
      margin-top: $spaceXs;
    }
  }
}

