@import '/src/styles-imports/shared';

.modalParent {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: zIndex('Modal');
  background-color: rgba(0, 0, 0, 0.1);
  animation: fadeIn $animationDuration ease-out;

  overflow-x: hidden;
  overflow-y: auto;

  &.isClosing {
    animation: fadeOut $animationDuration ease-out;
  }

  .modal {
    position: relative;
    width: 650px;
    height: calc(100% - 50px);

    display: flex;
    align-items: center;

    box-sizing: border-box;

    margin: auto;
    overflow: hidden;

    & > div {
      animation: loadModal $animationDuration ease-out;
    }

    &:focus {
      border: none;
      outline: none;
    }

    &.scrollable {
      display: block;
      height: auto;
      margin-top: space('L');
      margin-bottom: space('L');

      @include mqSmaller($bpS) {
        margin-top: space('M');
      }
    }

    &.isClosing {
      & > div {
        animation: closeModal $animationDuration ease-out;
      }
    }

    @include mqSmaller($bpM) {
      width: 476px;
    }

    @include mqSmaller($bpS) {
      width: 288px;
    }

    .header {
      @include type('Xxs');
      word-break: break-word;
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
    }

    .preHeader {
      margin-bottom: space('Xs');
      text-align: center;
      word-break: break-word;

      & > * {
        margin: auto;
      }
    }

    .subHeader {
      @include type('LabelL');
      padding-top: space('Xs');
      word-break: break-word;

      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
    }

    .content {
      @include type('Copy');
      margin: space('Xxs') 0 0;
      text-align: left;
      white-space: pre-wrap;
    }

    .footer {
      display:flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      &.center {
        justify-content: center;
      }

      & > button {
        margin-left: space('Xs');
        margin-top: space('S');
      }

      &.column {
        flex-direction: column-reverse;
        & > button {
          align-self: flex-end;
        }
      }
    }

    .hint {
      @include type('Hint');
      text-align: center;
      margin-top: space('S');

      &.left {
        text-align: left;
      }
    }

    .feedback {
      margin-top: space('S');
      .feedbackTitle {
        @include type('LabelL');
        text-align: center;
      }
      .feedbackButtons {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        & > button {
          margin: space('Xs') 0 0;
        }
        & > button:last-child {
          margin-left: space('Xxs');
        }
      }
    }
  }
}
