@import '/src/styles-imports/shared';


/* StrengthsAndDevelopmentAreas */
.strengthsAndDevelopmentAreas {
  .blue {
    color: $colorPrimary2;
  }
  .cardinal {
    min-width: space('S');
    white-space: nowrap;
  }
}
