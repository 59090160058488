@import '/src/styles-imports/shared';

/* nineLevelsRegistration */
.nineLevelsRegistration {

  text-align: left;

  @include transition(opacity);

  /* INTERACTION */

  /* MODIFIERS */
  &.hidden {
    opacity: 0;
  }

  /* RESPONSIVE */
  @include mqSmaller($bpS) {
    .twoColumns {
      flex-wrap: wrap;
      .column {
        width: 100% !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  /* CHILDREN */

  .title {
    margin-bottom: $spaceXs;
  }

  .language {
    margin-top: $spaceXxxs + 2px;
  }

  .statistics {
    margin-top: $spaceS;
    .paragraph {
      margin-top: $spaceXxs;
      margin-bottom: $spaceS;
    }

    .formElement {
      margin-bottom: $spaceXs;
    }
  }

  .twoColumns {
    display: flex;

    .column {
      box-sizing: border-box;
      width: 50%;

      &:first-child {
        padding-right: $spaceXxs;
      }

      &:nth-child(2) {
        padding-left: $spaceXxs;
      }
    }

  }
  .legal {
    margin-top: $spaceS;
  }
}
