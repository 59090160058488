@import '/src/styles-imports/shared';

/* FLOW CARD */
.flowCard {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  h6 {
    margin: $spaceXxxs auto;
  }

  .actualStatus {
    margin: $spaceXs 0;
    .actualStatusRow {
      margin: $spaceXxs 0;
      overflow: hidden;
      display: flex;
      gap: $spaceXxs;
      align-items: center;
    }

    .actualStatusPill {
      padding: $spaceXxxs 12px;
      min-height: 20px;
      border: 1px solid $colorBlue05;
      background-color: $colorBlue06;
      border-radius: 15px;
      font-weight: 600;
    }
  }
  
  .flowCardActions {
    margin-top: $spaceXxs;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: $spaceXs $spaceS;
    word-break: break-word;
  }

  .showLink {
    color: $colorPrimary1;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: $spaceXxs;
    svg {
      min-width: 14px;
      path {
        fill: $colorPrimary1;
      }
    }

    &:hover, &:focus {
      color: $colorPrimary2;
      svg path {
        fill: $colorPrimary2;
      }
    }

    &.disabled {
      cursor: initial;
      pointer-events: none;
      opacity: 0.6;
    }
  }
}

/* HISTORY MODAL */
.flowHistoryModal {
  .columns {
    width: 100%;
    word-break: break-word;
    .row {
      padding: $spaceXs 0;
      @include type('Copy');

      display: flex;
      gap: $spaceXs;
      border-bottom: 1px solid $colorGrey4;

      @include mqSmaller($bpM) {
        flex-direction: column;
      }

      &:first-child {
        border-top: 1px solid $colorGrey4;
      }

      .rowItem {
        flex: 1;

        .hint {
          @include type('Hint');
          color: $colorGrey2;
        }
        .copy {
          @include type('Copy');
          margin-top: $spaceXxs;
        }
      }
    }
  }
}