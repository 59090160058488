@import '/src/styles-imports/shared';

/* CodeInputGroup */
.codeInputGroup {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .flexContainer {
    display: flex;
    flex-wrap: wrap;

    & > div:not(:last-child) {
      margin-right: space('Xs');
    }
  }

  .hintParent {
    display: flex;
  }

  .hint {
    width: 0;
    flex-grow: 1;

    @include type('Hint');
    margin-top: space('Xxs');
  }
}
