@import '/src/styles-imports/shared';

.sidePanel {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: zIndex('Modal');
  background-color: rgba(0, 0, 0, 0.1);

  .container {
    position: fixed;
    top: 0;
    height: 100vh;
    background-color: $colorWhite;
    box-sizing: border-box;

    &.sizeL {
      width: 720px;
      @include mqSmaller($bpM) {
        width: 640px;
      }
    }

    &.sizeM {
      width: 411px;
    }

    &.sizeS {
      width: 317px;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: zIndex('Buffer2');

      @include iconWithBackgroundGrey;

      &.arrow {
        svg {
          width: 75%;
          height: 75%;
        }
      }

      &.flip {
        right: auto;
        left: 10px;
      }

      img {
        margin: auto;
      }
    }

    @include mqSmaller($bpS) {
      width: 100% !important;
    }
  }
}
