@import '/src/styles-imports/shared';

/* AssessmentManagerUserGroup */
.assessmentManagerUserGroup {
  @include basicGridNoMargins;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .mainHalf {
    grid-column: start / mid;

    @include mqSmaller($bpM) {
      grid-column: start / 7;
    }
    @include mqSmaller($bpS) {
      grid-column: start / end;
    }
  }

  .mainFull {
    grid-column: start / end;
    margin-top: $spaceL;

    & > div:not(:first-child) {
      margin-top: $spaceS;
    }
  }

  .profileSettings {
    margin-top: $spaceXl;
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > div:first-child {
      width: 40%;
    }

    @include mqSmaller($bpS) {
      flex-wrap: wrap;

      & > div:first-child {
        width: 100%;
      }
      & > div:last-child {
        margin-top: $spaceXs;
        margin-left: auto;
      }
    }
  }

  .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: $spaceXs;
    border-top: 1px solid $colorGrey4;

    &:last-child {
      border-bottom: 1px solid $colorGrey4;
    }

    @include mqSmaller($bpS) {
      flex-direction: column;
      align-items: flex-start;
    }

    .left {
      display: flex;
      align-items: center;

      // ImgCircle
      & > div:first-child {
        flex-shrink: 0;
      }

      .textContent {
        margin-left: $spaceXxs;
        & > div:first-child {
          @include type('LabelL');
        }
        & > div:last-child {
          @include type('Hint');
          margin-top: $spaceXxxs;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      svg {
        width: 14px;
        height: 14px;
      }

      & > div:last-child {
        @include type('Copy');
        color: $colorPrimary1;
        margin-left: $spaceXxs;
      }

      @include transition(color, fill);
      &:hover {
        cursor: pointer;
        & > div:last-child {
          color: $colorPrimary2;
        }
      }

      @include mqSmaller($bpS) {
        margin-left: calc(36px + #{$spaceXxs});
      }
    }

    @include mqSmaller($bpS) {
      padding: $spaceXs 0;
      flex-wrap: wrap;

      .right {
        margin-top: 8px;
      }
    }
  }
}
