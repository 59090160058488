@import '/src/styles-imports/shared';

html {
  scroll-behavior: smooth;
}

.settings {

  @include basicGridNoMargins;

  .main {
    grid-column: 1/7;

    .title {
      @include type('Xxs');
      color: $colorGrey1;
    }
    .description {
      @include type('Copy');
      color: $colorGrey1;
      margin-top: space('Xs');
    }
    .content {
      margin-top: space('M');

      @include animationLonger(slideUp);
      animation-fill-mode: forwards;

      a {
        display: block;
        margin-top: space('Xs');
      }
    }

    .error {
      @include type('Copy');
      color: $colorDanger1;
      padding-top: space('S');
    }

    .languages, .notifications, .changePassword {
      margin-top: space('L');

      @include animationLonger(slideUp);
      animation-fill-mode: forwards;

      .title {
        @include type('LabelL');
        color: $colorGrey1;
      }
      .description {
        @include type('Copy');
        color: $colorGrey1;
        margin-top: space('Xxs');
      }
    }
    .languages {
      .rows {
        margin-top: space('S');
        .language {
          padding: space('Xs');
          box-sizing: border-box;
          border-top: 1px solid $colorGrey4;
          @include type('Copy');
          color: $colorGrey1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: $colorPrimary4;
          }
          &:last-child {
            border-bottom: 1px solid $colorGrey4;
          }
          &.active {
            color: $colorPrimary1;
          }
          svg {
            height: 20px;
          }
        }
      }
    }
    .notifications {
      .rows {
        margin-top: space('Xs');
        .row {
          padding: space('Xs');
          box-sizing: border-box;
          border-top: 1px solid $colorGrey4;
          border-bottom: 1px solid $colorGrey4;
          @include type('Copy');
          color: $colorGrey1;
          &:last-child {
            border-top: none;
          }
          .row1 {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .row2 {
            color: $colorGrey2;
            margin-top: space('Xxs');
            padding-right: 100px;
            box-sizing: border-box;
          }
          & > label:last-child {
            margin-top: space('Xs');
          }
        }
      }
    }
    .changePassword {
      .inputs {
        .input {
          margin-top: space('Xs');
        }
      }
      button {
        display: block;
        margin-top: 36px;
        margin-left: auto;
      }
    }

    .deleteMyProfile {
      margin-top: space('L');
      button {
        margin-top: space('S');
      }
    }
  }
  .other {
    grid-column: 8/12;

    @include animationLong(slideUp);
    animation-fill-mode: forwards;

    & > div {
      margin-top: space('Xs');
    }

    .xxsTitle {
      @include type('Xxs');
      color: $colorGrey1;
    }
    a {
      display: block;
      margin-top: space('S');
    }
  }

  .label {
    @include type('LabelL');
  }
  .copy {
    @include type('Copy');
    margin-top: space('Xxs');
  }

  .deleteMyProfileLoadingHint {
    margin-top: $spaceXxs;
  }

  .toastTitle {
    @include type('LabelL');
    color: $colorGrey1;
    margin-right: 32px;
    overflow: hidden;
  }
  .toastDescription {
    @include type('Copy');
    color: $colorGrey1;
    margin-top: space('Xxs');
  }
  @include mqSmaller($bpM) {
    .other {
      display: none;
    }
  }
  @include mqSmaller($bpS) {
    padding-top: $spaceS;
    padding-bottom: $spaceS;
    .main {
      grid-column: start/end;
    }
    .other {
      display: none;
    }
  }
}

.deleteMyProfileModalContent {
  padding-top: $spaceXxs;
  .error {
    @include type('Copy');
    color: $colorDanger1;
  }

  & > div {
    margin-top: space('Xs');
  }
}
