@import '/src/styles-imports/shared';

$numberingWidth: 20px;

/* GAP ANALYSIS */
.gapAnalysis {
  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .blue {
    color: $colorPrimary1;
  }
  .cardinal {
    min-width: space('S');
    white-space: nowrap;
    margin-right: 12px;
  }

  .classification {
    margin-top: $spaceL;

    &.forCustomReport:first-child {
      margin-top: 0;
    }
  }

  .classificationItem {
    display: flex;
    margin-top: $spaceXs;

    .dimensionName {
      @include type('Xxs');
      font-weight: initial;
    }

    &:first-child {
      margin-top: $spaceM;
    }

    // numbering
    & > div:first-child {
      flex-basis: $numberingWidth;
      flex-shrink: 0;
      @include type('Xxs');
    }
    & > div:last-child {
      flex-basis: 100%;
      max-width: 100%;

      .diagramParent {
        margin-top: $spaceM;
        @include type('Copy');

        .bottomPanel {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          column-gap: $spaceL;
          margin-top: $spaceXs;

          @include mqSmaller($bpS) {
            column-gap: $spaceS;
          }

          .indBlock {
            text-align: center;

            // score value
            & > div:last-child {
              padding-top: $spaceXxs;
              color: $colorPrimary1;
              text-align: center;
            }

            .topBlock {
              display: flex;

              .circle {
                width: 15px;
                height: 15px;
                border-radius: 50%;

                &.primary, &.blue {
                  background-color: $colorBlueMain;
                }
                &.yellow {
                  background-color: $colorYellowMain;
                }
                &.green {
                  background-color: $colorGreenMain;
                }
                &.red {
                  background-color: $colorRedMain;
                }
                &.purple {
                  background-color: $colorPurpleMain;
                }
                &.grey {
                  background-color: $colorGrey01;
                }
              }

              & > span:last-child {
                padding-left: 10px;
              }
            }
          }
        }
      }
    }
  }

}
