@import '/src/styles-imports/shared';

// <BluCSSTransition>
// These must be in top namespace of this CSS Module
// in order for classNames={{ ...styles }} to work
.enter {
  opacity: 0 !important;
  transform: translateY(-20px) !important;
}
.enterActive, .enterDone {
  opacity: 1 !important;
  transform: translateY(0px) !important;
  @include transition(transform, opacity);
}
.exit {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}
.exitActive, .exitDone {
  opacity: 0 !important;
  transform: translateY(-30px) !important;
  @include transition(transform, opacity);
}

/* DOWNLOAD RESULT BUTTON */
.downloadResultButton {
  position: relative;

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .menu {
    box-sizing: border-box;
    width: 257px;
    margin-left: -222px;
    position: absolute;
    padding: 18px 0;
    border-radius: 10px;
    border: 1px solid #ececec;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: $colorWhite;
    z-index: zIndex('Modal');

    &:focus {
      outline: none;
    }

    .menuItem {
      overflow: hidden;
      padding: 15px $spaceXs;

      color: $colorGrey1;
      @include type('Copy');
      cursor: pointer;

      @include transition(background-color);

      &:hover {
        background-color: $colorPrimary4;
      }

      &.disabled {
        pointer-events: none;
        cursor: initial;
        opacity: 0.5;
      }
    }
  }
}
