@import '/src/styles-imports/shared';

.assessmentIntro {
  word-break: break-word;

  .skeleton {
    animation: loadAssessmentResult 1.5s infinite ease;
    svg {
      display:block;
      width: 100%;
      height: auto;
      max-height: 100%;
    }
  }

  .imageBlock {
    position: relative;
    //16:9 aspect ratio
    padding-top: 56.25%;
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    @include type('M');
    color: $colorPrimary1;
    margin-top: space('Xs');
    text-align: center;
  }
  .description {
    @include type('CopyStrong');
    color: $colorGrey1;
    text-align: left;
    margin-top: space('Xs');
    margin-bottom: space('S');

    p {
      margin-top: $spaceM;
    }
  }

}
