@import '/src/styles-imports/shared';

/* REPORT PREVIEW */
.reportPreview {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */
  @include mqLargerEqual($bpS) {
    max-width: 75%;
  }
  @include mqLargerEqual($bpM) {
    max-width: 50%;
  }

  /* CHILDREN */
  .inputSection {
    display: flex;
    gap: $spaceXs;
    min-height: 75px;
    margin-bottom: $spaceXs;
    .reportLoadBtn {
      margin-top: $spaceXxxs;
    }
  }
  .viewSection {
    display: flex;
    justify-content: flex-end;
  }
}
