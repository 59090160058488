@import '/src/styles-imports/shared';

/* OVERVIEW */
.organizationOverview {
  .title {
    @include type('Xxs');
    color: $colorGrey1;
    grid-column: start/end;
    animation: slideUp $animationDuration $animationFunction;
  } 

  .copy {
    margin-top: space('Xxxs');
    margin: space('S') 0px;
    grid-column: start/end;
    @include type('Copy');
    white-space: pre-wrap;
  }

  .cards {
    grid-column: start/end;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);

    .card {
      .smallRatio {
        font-size: 12px;
      }
    }

    @include mqSmaller($bpM) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mqSmaller($bpS) {
      grid-template-columns: 1fr;
    }
  }

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
}
