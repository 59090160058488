@import '/src/styles-imports/shared';

/* WellBeingTeaserNext */
.wellBeingTeaserNext {

  /* INTERACTION */

  /* MODIFIERS */

  /* RESPONSIVE */

  /* CHILDREN */
  .block {
    display: flex;
    align-items: center;
    margin-top: $spaceXs;
    @include type('Copy');

    & > span {
      padding-left: $spaceXxs;
    }
  }

  & > div > button {
    display: block;
    margin-top: $spaceXs;
    margin-left: auto;
  }
}
